import en from "config/lang/en";

export interface INavbarItem {
  nameKey: string;
  arrowDown: boolean;
}
export interface ISettingAccountItem {
  nameKey: keyof typeof en;
  icon: string;
  logout?: boolean;
  coin?: boolean;
  lang?: boolean;
}

const navigation = {
  navbar: (): INavbarItem[] => [
    {
      // name: lang().TRADE,
      nameKey: "NFT",
      arrowDown: false,
    },
  ],
  settingAccount: (): ISettingAccountItem[] => [
    {
      // name: lang.LANGUAGE,
      nameKey: "LANGUAGE",
      lang: true,
      icon: require("../../assets/navbar/lang.svg").default,
    },
    {
      // name: lang.LANGUAGE,
      nameKey: "LOGOUT",
      lang: false,
      logout: true,
      icon: require("../../assets/navbar/logout.svg").default,
    },
  ],
};
export default navigation;
