import userIcon from "assets/user.svg";
import ButtonDefault from "components/buttons/ButtonDefault";
import InputText from "components/forms/InputText";

import Main from "pages/templates/Main";
import LangContext from "contexts/Lang";
import React, { useContext, useState } from "react";
import UserContext from "contexts/User";
import formatText from "lib/formatText";
import userService from "services/userService";
import { alert } from "lib/Alert";

import { useNavigate } from "react-router-dom";
import {
  Container,
  InfosContainer,
  TextEmail,
  TextHash,
  UserIcon,
  UserInfoContainer,
  Form,
  ButtonsContainer,
} from "./styles";
import ButtonGradient from "components/buttons/ButtonGradient";

const ChangePasswordContent: React.FC = () => {
  const { lang } = useContext(LangContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const { user } = useContext(UserContext);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const changePassword = async () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      alert.open({ title: lang.FILL_THE_INPUTS, type: "warning" });
      return;
    }
    setLoading(true);
    try {
      alert.closeAll();
      if (currentPassword && confirmPassword && newPassword) {
        const data = {
          currentPassword: currentPassword,
          password: newPassword,
          passwordConfirm: confirmPassword,
        };
        const response = await userService.updatePasswod(data);
        // console.log({ response });
        if (response) {
          alert.open({ title: response.message, type: "success" });
          navigate("/account");
        }
      } else {
        alert.open({ title: lang.FILL_THE_INPUTS, type: "warning" });
      }
      setLoading(false);
    } catch (e: any) {
      // console.log(e.response);
      if (e.response && e.response.data) {
        if (e.response.data.errors) {
          e.response.data.errors.map((error) => {
            alert.open({ title: error.message, type: "error" });
            return null;
          });
        }
        if (e.response.data.validationErrors) {
          e.response.data.validationErrors.map((error) => {
            alert.open({ title: error.message, type: "error" });
            return null;
          });
        }
      }
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form>
        <UserInfoContainer>
          <UserIcon src={userIcon} alt="user" />
          <InfosContainer>
            <TextEmail>{user.email}</TextEmail>
            <TextHash>
              {formatText.truncateHash({
                hash: user.walletAddress,
                startLength: 10,
                endLength: 10,
              })}
            </TextHash>
          </InfosContainer>
        </UserInfoContainer>
        <InputText
          {...{
            type: "password",
            title: lang.CURRENT_PASSWORD,
            value: currentPassword,
            setValue: setCurrentPassword,
            containerStyle: { margin: "0 0 50px 0" },
          }}
        />

        <InputText
          {...{
            type: "password",
            title: lang.NEW_PASSWORD,
            value: newPassword,
            setValue: setNewPassword,
            containerStyle: { margin: "0 0 15px 0" },
          }}
        />

        <InputText
          {...{
            type: "password",
            title: lang.CONFIRM_PASSWORD,
            value: confirmPassword,
            setValue: setConfirmPassword,
          }}
        />
      </Form>
      <ButtonsContainer>
        <ButtonDefault
          title={lang.CANCEL}
          action={() => navigate("/account", { replace: true })}
        />
        <ButtonGradient
          {...{
            title: lang.CONFIRM,
            onClick: changePassword,
            loading,
            disabled: loading,
          }}
        />
      </ButtonsContainer>
    </Container>
  );
};
const ChangePassword: React.FC = () => {
  const { lang } = useContext(LangContext);

  return (
    <Main headerBody={{ title: lang.CHANGE_PASSWORD, backPath: "/account" }}>
      <ChangePasswordContent />
    </Main>
  );
};
export default ChangePassword;
