import triangleDown from "assets/triangle_down.svg";
import { allCoins, coins, dictionaryCoins } from "constants/coins";

import React, { useEffect, useState } from "react";
import {
  Container,
  Header,
  IconOption,
  IconOptions,
  IconTriangle,
  Input,
  Label,
  OptionContainer,
  OptionsContainer,
  SelectContainer,
  SelectedOption,
  SelectedOptionContainer,
  SelectOptionTitle,
  Title,
} from "./styles";

interface IInputNumberWithSelect {
  title: string;
  value: string;
  setValue: (val: string) => void;
  option: number;
  setOption: (e: string) => void;
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  options: allCoins[];
  label?: string;
  id?: string;
}
const InputNumberWithSelect: React.FC<IInputNumberWithSelect> = ({
  title,
  value,
  setValue,
  option,
  setOption,
  disabled,
  containerStyle,
  options,
  label,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);

  useEffect(() => {
    const listener = function (e) {
      const id = `${title}-input`;
      const target = document.getElementById(id);
      if (id && target?.contains(e.target)) {
        setShowOptions(false);
      } else {
        setShowOptions(false);
      }
    };
    window.addEventListener("click", listener);
    return () => {
      window.removeEventListener("click", listener);
    };
  }, [title]);

  return (
    <Container style={{ ...containerStyle }}>
      <Header>
        <Title>{title}</Title>
        {label && <Label>{label}</Label>}
      </Header>
      <SelectedOptionContainer id={`${title}-input`} disabled={disabled}>
        <Input
          {...{
            pattern: "[0-9]+([.,][0-9]+)?",
            step: "0.01",
            type: "number",
            value,
            disabled,
            placeholder: "0.0",
            onChange: (e) => {
              if (!disabled) setValue(e.target.value);
            },
          }}
        />
        <SelectContainer
          onClick={(e) => {
            e.stopPropagation();
            setShowOptions(!showOptions);
          }}
        >
          {options.length > 0 && (
            <IconOption
              src={coins[dictionaryCoins[option]].icon}
              alt={coins[dictionaryCoins[option]].name}
            />
          )}
          <SelectedOption>
            {options.length > 0 && coins[dictionaryCoins[option]].symbol}
          </SelectedOption>
          <IconTriangle src={triangleDown} alt="option" />
        </SelectContainer>
        {showOptions && options.length > 0 && (
          <OptionsContainer onClick={() => setShowOptions(!showOptions)}>
            {options.map((option: any, idx: number) => (
              <OptionContainer key={idx} onClick={() => setOption(option)}>
                <IconOptions
                  src={coins[dictionaryCoins[option]].icon}
                  alt="option"
                />
                <SelectOptionTitle>
                  {coins[dictionaryCoins[option]].symbol}
                </SelectOptionTitle>
              </OptionContainer>
            ))}
          </OptionsContainer>
        )}
      </SelectedOptionContainer>
    </Container>
  );
};
export default InputNumberWithSelect;
