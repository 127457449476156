import styled, { css } from "styled-components";

export const Container = styled.div(
  (props) => css`
    ${props.theme.aligns.verticalAndHorizontal};
    justify-content: center;
    width: 100%;
    ${props.theme.responsive.sm(css`
      height: 76px;
      width: 335px;
    `)}
  `
);

export const ButtonContent = styled.div`
  border-radius: 9px;
  background-color: ${(props) => props.theme.colors.primaryBgc};
  width: 70px;
  height: 76px;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  flex-flow: column;
  &:hover {
    background-color: ${(props) =>
      props.theme.colors.gradientWalletBalanceSecundary};
  }
`;

export const ButtonContainer = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  width: 75px;
  height: 81px;
  margin: 0 15px;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  box-shadow: 0px 4px 4px
    ${(props) => props.theme.colors.blackTransparentDarker};
  background-image: linear-gradient(
    230deg,
    #ffffff 1%,
    ${(props) => props.theme.colors.secundary} 30%,
    ${(props) => props.theme.colors.secundary} 70%,
    #ffffff 99%
  );

  ${(props) =>
    props.disabled &&
    css`
      background-image: linear-gradient(
        237deg,
        ${(props) => props.theme.colors.whitelight}ee 5.66%,
        ${(props) => props.theme.colors.whitelight}ee 75.35%
      );
      pointer-events: none;
      & ${ButtonContent} {
        background-color: ${props.theme.colors.whitelight}ee;
      }
    `}
  border-radius: 10px;
`;

export const ImgButton = styled.img`
  width: 25px;
  margin-bottom: 8px;
`;
export const ButtonText = styled.span`
  ${(props) => props.theme.fonts.poppins};
  color: ${(props) => props.theme.colors.secundary};
  font-weight: 600;
  font-size: 12px;
`;
