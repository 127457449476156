import styled, { css } from "styled-components";

export const Container = styled.div`
  ${(props) => props.theme.aligns.vertical};
  position: relative;
  flex-flow: column;
  width: 100%;
  height: 545px;
`;
export const ImgLogo = styled.img`
  width: 275px;
  height: 233px;
  margin: 20px 0 20px 0;
`;

export const Divider = styled.div`
  width: 80%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.blackTransparentMind};
`;
export const LoadingText = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    font-size: 18px;
    font-weight: 500;
    color: ${props.theme.colors.secundary};
  `
);
