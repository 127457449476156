import themes from "config/themes";
import { AlertProvider } from "contexts/Alert";
import { AuthProvider } from "contexts/Auth";
import { LangProvider } from "contexts/Lang";
import { PricesProvider } from "contexts/Prices";
import { TaxesAndLimitsProvider } from "contexts/TaxAndLimits";
import { UserProvider } from "contexts/User";
import React from "react";
import { ThemeProvider } from "styled-components";

const Providers: React.FC = ({ children }) => {
  return (
    <LangProvider>
      <ThemeProvider theme={themes}>
        <AlertProvider>
          <PricesProvider>
            <TaxesAndLimitsProvider>
              <UserProvider>
                <AuthProvider>{children}</AuthProvider>
              </UserProvider>
            </TaxesAndLimitsProvider>
          </PricesProvider>
        </AlertProvider>
      </ThemeProvider>
    </LangProvider>
  );
};

export default Providers;
