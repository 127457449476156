import styled, { css } from "styled-components";

export const Header = styled.div`
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  padding: 0 7px;
`;

export const WalletAddressContainer = styled.div(
  (props) => css`
    ${props.theme.fonts.roboto};
    color: ${props.theme.colors.primaryTextDisabled};
    font-size: 12px;
    ${props.theme.aligns.horizontal};
    flex-flow: column;
    font-weight: 700;
  `
);
export const WalletAddressTitle = styled.span<{ reverse?: boolean }>(
  (props) =>
    css`
      ${props.reverse && "text-align: right;"}
      margin: 5px 0;
    `
);
export const WalletAddressHash = styled.span``;
export const WalletContainer = styled.div`
  margin-bottom: 20px;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;
export const IconCoin = styled.img`
  width: 25px;
  padding: 2px;
  background-color: white;
  border-radius: 20px;
  margin-left: 5px;
`;
export const IconCoinContainer = styled.div`
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;
export const TokenCoin = styled.img`
  width: 35px;
  background-color: #fff;
  border-radius: 30px;
  padding: 8px;
`;
export const ConfirmApprovalText = styled.span`
  font-size: 14px;
  ${(props) => props.theme.fonts.roboto};
  color: ${(props) => props.theme.colors.primaryFont};
  padding: 5px 10px;
  text-align: left;
  float: left;
  height: 20px;
  line-height: 20px;
`;
