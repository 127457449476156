import Radius from "components/forms/Radius";
import navigation from "config/navigation";
import languages from "constants/languages";
import AuthContext from "contexts/Auth";
import LangContext from "contexts/Lang";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ISettingsAccount } from "./interfaces";
import {
  AccountItemContainer,
  AccountItemContent,
  AccountItemText,
  Container,
  ContainerBody,
  IconImg,
} from "./styles";

const SettingsAccount: React.FC<ISettingsAccount> = ({
  show,
  setShow,
  user = true,
}) => {
  const { changeLang, lang, currentLangKey } = useContext(LangContext);
  const { logout } = useContext(AuthContext);
  const [selectedCoin, setSelectedCoin] = useState(0);
  const [selectedLang, setSelectedLang] = useState(
    currentLangKey === "pt" ? 0 : 1
  );
  useEffect(() => {
    setSelectedLang(currentLangKey === "pt" ? 0 : 1);
  }, [currentLangKey]);

  const selectCoins = ["BRL", "USD"];
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const res = await logout();
      if (res) navigate("/login", { replace: true });
    } catch (error) {
      // console.log(error);
    }
  };
  const handleChangeLanguage = (key: string) => {
    changeLang(key);
  };
  return (
    <Container $show={show}>
      <ContainerBody>
        {navigation.settingAccount().map(
          (item, idx) =>
            !(item.logout && !user) && (
              <AccountItemContainer
                key={idx}
                $select={item.coin || item.lang}
                onClick={async () => {
                  try {
                    if (item.logout) handleLogout();
                    setShow(false);
                  } catch (error) {
                    // console.log(error);
                  }
                }}
              >
                <AccountItemContent>
                  <IconImg
                    src={item.icon}
                    alt={lang[item.nameKey].toString()}
                  />
                  <AccountItemText $white={item.lang}>
                    <>{lang[item.nameKey]}</>
                  </AccountItemText>
                </AccountItemContent>
                {item.lang &&
                  languages.map((lang, idx) => (
                    <AccountItemContent
                      $select
                      key={idx}
                      onClick={() => {
                        setShow(false);
                        setSelectedLang(idx);
                        handleChangeLanguage(lang.key);
                      }}
                    >
                      <IconImg src={lang.icon} alt={lang.name.toString()} />
                      <AccountItemText>{lang.name}</AccountItemText>
                      <Radius
                        value={idx === selectedLang}
                        setValue={() => {
                          setShow(false);
                          setSelectedLang(idx);
                          handleChangeLanguage(lang.key);
                        }}
                      />
                    </AccountItemContent>
                  ))}
                {item.coin &&
                  selectCoins.map((coin, idx) => (
                    <AccountItemContent
                      $coin
                      key={idx}
                      onClick={() => {
                        setShow(false);
                        setSelectedCoin(idx);
                      }}
                    >
                      <AccountItemText>{coin}</AccountItemText>
                      <Radius
                        value={idx === selectedCoin}
                        setValue={() => {
                          setShow(false);
                          setSelectedCoin(idx);
                        }}
                      />
                    </AccountItemContent>
                  ))}
              </AccountItemContainer>
            )
        )}
      </ContainerBody>
    </Container>
  );
};

export default SettingsAccount;
