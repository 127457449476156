// import shareIcon from "assets/share.svg";
import copyIcon from "assets/copy.svg";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import formatText from "lib/formatText";
import helpers from "lib/helpers";
import { TextHash } from "pages/Account/styles";
import { CopyIcon } from "pages/ConfirmTransaction/styles";
import Main from "pages/templates/Main";
import React, { useContext } from "react";
import {
  Container,
  CopyHash,
  CopyText,
  InfosContainer,
  QrCodeContainer,
  TextEmail,
  UserInfoContainer,
} from "./styles";

const DepositCryptoContent: React.FC = () => {
  const { user } = useContext(UserContext);
  const { lang } = useContext(LangContext);
  return (
    <Container>
      <UserInfoContainer>
        <InfosContainer>
          <TextEmail>{lang.ADRESS}</TextEmail>
          <TextHash>{lang.ADRESS_LABEL}</TextHash>
        </InfosContainer>
        {/* <UserIcon src={shareIcon} alt="user" /> */}
      </UserInfoContainer>
      <QrCodeContainer>
        <img src={helpers.createQrcodeUrl(user.walletAddress)} alt="qr-code" />
      </QrCodeContainer>
      <CopyHash
        onClick={() => {
          helpers.copyToClipboard(user.walletAddress);
        }}
      >
        <CopyText>{user.walletAddress}</CopyText>
        <CopyIcon src={copyIcon} alt="copy" />
      </CopyHash>
    </Container>
  );
};
const DepositCrypto: React.FC = () => {
  const { lang } = useContext(LangContext);

  return (
    <Main headerBody={{ title: lang.DEPOSIT, backPath: "/" }}>
      <DepositCryptoContent />
    </Main>
  );
};
export default DepositCrypto;
