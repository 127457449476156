import ButtonGradient from "components/buttons/ButtonGradient";
import InputText from "components/forms/InputText";
import LangContext from "contexts/Lang";
import { alert } from "lib/Alert";
import validator from "lib/validator";
import Guest from "pages/templates/Guest";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "services/authService";
import { ButtonContainer, Container, Form, Message } from "./styles";

const ForgotPasswordContent: React.FC = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { lang } = useContext(LangContext);
  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await authService.forgotPassword({
        email,
      });
      setTimeout(() => {
        alert.open({ title: res.message, type: "success" }, true);
        navigate("/", { replace: true });
      }, 500);
    } catch (error: any) {
      setTimeout(() => {
        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          validator.verifyErrors(errorRes.data);
        }
        setLoading(false);
      }, 500);
    }
  };
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Message>{lang.RESET_PASSWORD_MESSAGE}</Message>
        <InputText
          {...{
            title: lang.EMAIL,
            name: "email",
            value: email,
            setValue: setEmail,
            containerStyle: { margin: "15px 0 10px 0" },
          }}
        />
      </Form>
      <ButtonContainer>
        <ButtonGradient
          {...{
            loading,
            width: screenWidth > 350 ? "320px" : "260px",
            title: lang.RECOVER_PASSWORD,
            onClick: handleSubmit,
            disabled: loading,
          }}
        />
      </ButtonContainer>
    </Container>
  );
};
const ForgotPassword: React.FC = () => {
  const { lang } = useContext(LangContext);
  return (
    <Guest
      headerBody={{ title: lang.FORGOT_PASSWOD, backPath: "/login" }}
      settingsAccount
    >
      <ForgotPasswordContent />
    </Guest>
  );
};
export default ForgotPassword;
