import React from "react";

import { Container, InternalRadius } from "./styles";
interface IRadius {
  value: boolean;
  setValue: (val: boolean) => void;
}
const Radius: React.FC<IRadius> = ({ value, setValue }) => {
  return (
    <Container $active={value} onClick={() => setValue(!value)}>
      <InternalRadius />
    </Container>
  );
};

export default Radius;
