import sendImg from "assets/send.svg";
import swapImg from "assets/swap.svg";
import depositImg from "assets/deposit.svg";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";

import {
  ButtonContainer,
  ButtonContent,
  ButtonText,
  Container,
  ImgButton,
} from "./styles";

const ButtonImg: React.FC<{
  title: string;
  url: string;
  onClick: () => void;
}> = ({ title, url, onClick }) => {
  const { balance } = useContext(UserContext);

  return (
    <ButtonContainer disabled={!balance} onClick={() => onClick()}>
      <ButtonContent>
        <ImgButton src={url} alt={title} />
        <ButtonText>{title}</ButtonText>
      </ButtonContent>
    </ButtonContainer>
  );
};

const ActionsButtons: React.FC = () => {
  const navigate = useNavigate();
  const { lang } = useContext(LangContext);

  return (
    <Container>
      <ButtonImg
        title={lang.DEPOSIT}
        url={depositImg}
        onClick={() => navigate("deposit/pix", { replace: false })}
      />
      <ButtonImg
        title={lang.SEND}
        url={sendImg}
        onClick={() => navigate("send-token", { replace: false })}
      />

      <ButtonImg
        title={lang.SWAP}
        url={swapImg}
        onClick={() => navigate("swap", { replace: false })}
      />
    </Container>
  );
};

export default ActionsButtons;
