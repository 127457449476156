import React, { createContext, useState } from "react";
import userService from "services/userService";

interface IUserContext {
  user: any;
  setUser: (data: any) => void;
  balance: any;
  setBalance: (data: any) => void;
  fetchBalance: () => Promise<any>;
  reset: () => void;
}
const UserContext = createContext<IUserContext>({
  user: "",
  setUser: (data) => {},
  balance: "",
  setBalance: (data) => {},
  fetchBalance: () => {
    return new Promise(() => {});
  },
  reset: () => {},
});
export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>();
  const [balance, setBalance] = useState<any>();
  const fetchBalance = () =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await userService.balances();
        setBalance(res.data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });

  const reset = () => {
    setUser(undefined);
    setBalance(undefined);
  };
  return (
    <UserContext.Provider
      value={{ user, setUser, balance, setBalance, fetchBalance, reset }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
