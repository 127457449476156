import AuthContext from "contexts/Auth";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import Account from "pages/Account";
import AccountData from "pages/AccountData";
import DocumentationUsers from "pages/Admin/DocumentationUsers";
import ListUsers from "pages/Admin/ListUsers";
import ChangePassword from "pages/ChangePassword";

import ConfirmTransaction from "pages/ConfirmTransaction";
import Dashboard from "pages/Dashboard";
import DepositCrypto from "pages/DepositCrypto";
import DepositPix from "pages/DepositPix";
import EarnInfo from "pages/EarnInfo";
import ForgotPassword from "pages/ForgotPassword";
import LoadingPage from "pages/LoadingPage";
import Login from "pages/Login";
import ResetPassword from "pages/ResetPassword";
import SendToken from "pages/SendToken";
import SignUp from "pages/SignUp";
import Swap from "pages/Swap";
import VerifyEmail from "pages/VerifyEmail";
import VerifyTransaction from "pages/VerifyTransaction";
import React, { useContext, useEffect, useState } from "react";
import {
  HashRouter,
  Navigate,
  Route,
  Routes as RoutesDom,
} from "react-router-dom";

// import { Container } from './styles';

const Routes: React.FC = () => {
  const { token, isCheckingToken } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const { changeLang } = useContext(LangContext);
  const [userAdmin, setUserAdmin] = useState(false);
  useEffect(() => {
    // console.log({ user });
    if (user) {
      try {
        if (user["system_admin"]) {
          setUserAdmin(true);
          changeLang("pt");
        }
      } catch (e) {}
    } else {
      setUserAdmin(false);
    }
  }, [user, token]);
  return (
    <HashRouter>
      <RoutesDom>
        {isCheckingToken ? (
          <Route path="*" element={<LoadingPage />} />
        ) : (
          <>
            {token ? (
              userAdmin ? (
                <>
                  <Route
                    path={"/users/documentation"}
                    element={<DocumentationUsers />}
                  />
                  <Route path={"/users"} element={<ListUsers />} />
                  <Route path={"/"} element={<ListUsers />} />
                </>
              ) : (
                <>
                  <Route path="/send-token" element={<SendToken />} />
                  <Route path="/swap" element={<Swap />} />
                  <Route
                    path="/confirm-transaction"
                    element={<ConfirmTransaction />}
                  />
                  <Route path="/account" element={<Account />} />
                  <Route path="/account/data" element={<AccountData />} />
                  <Route
                    path="/account/change-password"
                    element={<ChangePassword />}
                  />

                  <Route path="/earn/:id" element={<EarnInfo />} />
                  <Route path="/deposit/crypto" element={<DepositCrypto />} />
                  <Route path="/deposit/pix" element={<DepositPix />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/" element={<Dashboard />} />
                </>
              )
            ) : (
              <>
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/login" element={<Login />} />
                <Route path="/email-verification" element={<VerifyEmail />} />
                <Route path="/" element={<Login />} />
              </>
            )}
            <Route path="/send-confirmation" element={<VerifyTransaction />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </RoutesDom>
    </HashRouter>
  );
};

export default Routes;