import blockIcon from "assets/block_white.svg";
import ButtonDefault from "components/buttons/ButtonDefault";
import ButtonGradient from "components/buttons/ButtonGradient";
import InputNumber from "components/forms/InputNumber";
import InputText from "components/forms/InputText";
import SelectWithImg from "components/forms/SelectWithImg";
import ErrorMessage from "components/texts/ErrorMessage";
import { coins, dictionaryCoins, sendCoinKeys } from "constants/coins";
import LangContext from "contexts/Lang";
import PricesContext from "contexts/Prices";
import TaxesAndLimitsContext from "contexts/TaxAndLimits";
import UserContext from "contexts/User";
import handleAccountTokenIsApproved from "handlers/handleAccountTokenIsApproved";
import { alert } from "lib/Alert";
import formatText from "lib/formatText";
import handleTransactions from "lib/handleTransactions";
import math from "lib/math";
import Main from "pages/templates/Main";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AllowButtonsContainer,
  AllowPlatformTokenContainer,
  ButtonsContainer,
  Container,
  Form,
  ImgBlock,
} from "./styles";

const SendTokenContent: React.FC = () => {
  const { user, balance } = useContext(UserContext);
  const { lang } = useContext(LangContext);
  const { taxesAndLimits } = useContext(TaxesAndLimitsContext);
  const { mainTokenPrice } = useContext(PricesContext);
  const [loadingAllowToken, setLoadingAllowToken] = useState(true);
  const [allowToken, setAllowToken] = useState(false);
  const [to, setTo] = useState("");
  const [fee, setFee] = useState("");
  const [assets, setAssets] = useState<any>(sendCoinKeys[0]);
  const [amount, setAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const amountMemo = useMemo(() => amount, [amount]);
  useEffect(() => {
    alert.closeAll();
  }, [amountMemo]);
  useEffect(() => {
    if (math.isGreaterThan(amount, "0")) {
      const validationMessage = handleTransactions.validSend({
        amount,
        balance: balance.wallet[assets].available,
        minimum: taxesAndLimits.limits.send[assets][assets],
      });
      setErrorMessage(validationMessage);

      setFee(
        `${formatText.formatNumber({
          number: handleTransactions.calculateFees({
            amount:
              assets === "bnb"
                ? amount
                : handleTransactions.convertCoin({
                    fromAmount: amount,
                    to: "bnb",
                    pricesInMainToken: mainTokenPrice,
                  }),
            fee: taxesAndLimits.taxes.send.adm,
            gas: taxesAndLimits.minGasCryptoBalances.send,
          }),
        })} BNB`
      );
    } else {
      setFee("");
    }
  }, [amount, assets, to, balance, taxesAndLimits, mainTokenPrice]);
  useEffect(() => {
    const checkIfAccountTokenIsApproved = async () => {
      try {
        setLoadingAllowToken(true);
        const res = await handleAccountTokenIsApproved({ coin: assets });
        if (res === "unexecuted") {
          setAllowToken(true);
        } else {
          setAllowToken(false);
        }
      } catch (error) {
        // console.log(error);
      }
      setLoadingAllowToken(false);
    };
    if (assets !== "bnb") checkIfAccountTokenIsApproved();
    else {
      setAllowToken(false);
      setLoadingAllowToken(false);
    }
  }, [assets]);
  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  return (
    <Container>
      <Form>
        <InputText
          {...{
            title: lang.FROM,
            value:
              "ACCOUNT " +
              formatText.truncateHash({
                hash: user.walletAddress,
                startLength: screenWidth > 350 ? 10 : 6,
                endLength: screenWidth > 350 ? 10 : 6,
              }),
            setValue: () => {},
            disabled: true,
            containerStyle: { margin: "8px 0" },
          }}
        />
        <InputText
          {...{
            title: lang.TO,
            name: "to",
            value: to,
            setValue: setTo,
            containerStyle: { margin: "8px 0" },
          }}
        />
        <SelectWithImg
          {...{
            title: lang.ASSETS,
            name: "assets",
            value: assets,
            setValue: setAssets,
            containerStyle: { margin: "8px 0" },
            options: sendCoinKeys,
          }}
        />
        <InputNumber
          {...{
            title: lang.AMOUNT,
            name: "amount",
            value: amount,
            setValue: setAmount,
            containerStyle: { margin: "8px 0" },
            label: `${lang.BALANCE}: ${formatText.formatNumber({
              number: balance.wallet[assets].available,
            })} ${coins[dictionaryCoins[assets]].symbol}`,
            valueMax: () =>
              setAmount(
                formatText.formatNumber({
                  number: balance.wallet[assets].available,
                })
              ),
          }}
        />
        <InputText
          {...{
            title: lang.ESTIMATE_FEE,
            name: "fee",
            value: fee,
            setValue: () => {},
            disabled: true,
            containerStyle: { margin: "8px 0" },
          }}
        />
        {errorMessage && !allowToken && <ErrorMessage message={errorMessage} />}
      </Form>
      {allowToken ? (
        <AllowButtonsContainer>
          <ButtonGradient
            {...{
              loading: loadingAllowToken,
              width: screenWidth > 350 ? "320px" : "260px",

              disabled: loadingAllowToken,
              title: (
                <AllowPlatformTokenContainer>
                  <ImgBlock src={blockIcon} alt="block" />
                  {`${lang.ALLOW_PLATFORM_TO_USE} ${
                    coins[dictionaryCoins[assets]].symbol
                  }`}
                </AllowPlatformTokenContainer>
              ),
              onClick: () =>
                navigate(`/confirm-transaction?type=approval&coin=${assets}`, {
                  replace: false,
                }),
            }}
          />
        </AllowButtonsContainer>
      ) : (
        <ButtonsContainer>
          <ButtonDefault
            title={lang.CANCEL}
            action={() => navigate("/", { replace: true })}
          />
          <ButtonGradient
            {...{
              loading: loadingAllowToken,
              disabled:
                !to || !math.isGreaterThan(amount, 0) || Boolean(errorMessage),
              title: lang.NEXT,
              onClick: () =>
                navigate(
                  `/confirm-transaction?type=send&to=${to}&amount=${amount}&coin=${assets}`,
                  {
                    replace: false,
                  }
                ),
            }}
          />
        </ButtonsContainer>
      )}
    </Container>
  );
};
const SendToken: React.FC = () => {
  const { lang } = useContext(LangContext);

  return (
    <Main headerBody={{ title: lang.SEND_TOKEN, backPath: "/" }}>
      <SendTokenContent />
    </Main>
  );
};

export default SendToken;