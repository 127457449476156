import React from "react";
import arrowIcon from "assets/navbar/arrow_pagination.svg";
import { ArrowLeft, ArrowRight, Container, NumberListItem } from "./styles";

// import { Container } from './styles';
interface IPagination {
  page: number;
  lastPage: number;
  getUsers: (data: any) => any;
  filter: string;
  perPage: number;
}

const Pagination: React.FC<IPagination> = ({
  page,
  lastPage,
  getUsers,
  filter,
  perPage,
}) => {
  const changePage = ({
    numPage,
    limit,
  }: {
    numPage?: number;
    limit?: number;
  }) => {
    let _page = numPage || page;

    getUsers({ page: limit ? 1 : _page, filter, limit: limit || perPage });
  };
  return lastPage > 0 ? (
    <Container>
      <ArrowLeft
        src={arrowIcon}
        disabled={page === 1}
        alt="arrow"
        onClick={() => {
          if (!(page === 1)) changePage({ numPage: page - 1 });
        }}
      />
      <div style={{ display: "flex" }}>
        {Array.from(Array(lastPage).keys()).map((count, idx) => (
          <NumberListItem
            active={idx + 1 === page}
            key={count + idx}
            onClick={() => {
              changePage({ numPage: count + 1 });
            }}
          >
            <span>{`${idx + 1}`}</span>
          </NumberListItem>
        ))}
      </div>
      <ArrowRight
        src={arrowIcon}
        alt="arrow"
        disabled={page === lastPage}
        onClick={() => {
          if (!(page === lastPage)) changePage({ numPage: page + 1 });
        }}
      />
    </Container>
  ) : (
    <></>
  );
};

export default Pagination;
