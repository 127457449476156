import styled, { css } from "styled-components";

export const Img = styled.img`
  width: 10px;
`;

interface IContainer {
  active: boolean;
}

export const Container: any = styled.div<IContainer>(
  (props) => css`
    width: ${props.theme.sizes.checkboxHeightWidth};
    height: ${props.theme.sizes.checkboxHeightWidth};
    margin: 5px;
    border-radius: 3px;
    cursor: pointer;
    border: 2px solid ${props.theme.colors.white};
    ${props.theme.aligns.verticalAndHorizontal}
    ${props.active
      ? css`
          background-color: ${props.theme.colors.white};
        `
      : css`
          & ${Img} {
            display: none;
          }
        `}
  `
);
