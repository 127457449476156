import ButtonGradient from "components/buttons/ButtonGradient";
import LangContext from "contexts/Lang";
import LoadingPage from "pages/LoadingPage";
import Guest from "pages/templates/Guest";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userService from "services/userService";
import {
  ButtonsContainer,
  Container,
  Message,
  MessageContainer,
} from "./styles";

const VerifyEmail: React.FC = () => {
  const { lang } = useContext(LangContext);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState(lang.CONFIRM_EMAIL);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate();

  const fetchResendToken = async () => {
    const queryString = window.location.hash.split("?")[1];
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");

    try {
      if (token) {
        const response = await userService.resendTokenEmail({ token });

        if (response) {
          setMessage(lang.CONFIRM_ACCOUNT);
        }
      }
    } catch (e: any) {
      // console.log(e.response);
    }
  };
  useEffect(() => {
    const validToken = async () => {
      const queryString = window.location.hash.split("?")[1];
      const token = queryString.split("token=")[1];
      setLoading(true);

      try {
        if (token) {
          const response = await userService.confirmRegistration({ token });
          if (response) {
            setMessage(lang.CONFIRM_EMAIL_SUCCESS);
          }
        }
      } catch (e: any) {
        if (e.response && e.response.data) {
          if (e.response.data.errors) {
            let expired = false;
            e.response.data.errors.map((error) => {
              if (error.id === "EXPIRED_TOKEN") {
                expired = true;
              }
              return null;
            });
            setMessage(lang.CONFIRM_ACCOUNT_INVALID);
            if (expired) {
              setMessage(lang.CONFIRM_ACCOUNT_EXPIRED);
              setIsTokenExpired(true);
            }
          }
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    validToken();
  }, [lang]);
  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  return loading ? (
    <LoadingPage />
  ) : (
    <Guest
      headerBody={{
        title: lang.CONFIRM_EMAIL,
        backPath: "/",
      }}
      settingsAccount
    >
      <Container>
        <MessageContainer>
          <Message>{message}</Message>
        </MessageContainer>
        <ButtonsContainer>
          <ButtonGradient
            {...{
              width: screenWidth > 350 ? "320px" : "260px",
              title: isTokenExpired ? lang.RESEND_EMAIL : lang.LOGIN,
              onClick: () =>
                isTokenExpired
                  ? fetchResendToken()
                  : navigate("/", { replace: true }),
            }}
          />
        </ButtonsContainer>
      </Container>
    </Guest>
  );
};

export default VerifyEmail;
