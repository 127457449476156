export interface IInit {
  logout: () => void;
}
export interface IAuth extends IInit {
  init: (data: IInit) => void;
}
class Auth implements IAuth {
  public init = (data: IInit) => {
    Object.assign(this, data);
  };
  public logout = () => {};
}
export const auth = new Auth();
