import { coins } from "constants/coins";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import formatText from "lib/formatText";
import math from "lib/math";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AssetContainer,
  Container,
  Icon,
  IconContent,
  LeftContent,
  ListAssetsContainer,
  RewardButton,
  RightContent,
  Subtitle,
  Title,
} from "./styles";
interface IAssets {
  icon: string;
  symbol: string;
  key: string;
  earnKey?: string;
  fixed: number;
}
interface IListAssets {
  assets: IAssets[];
}
const ListAssets: React.FC<IListAssets> = ({ assets }) => {
  const navigate = useNavigate();
  const { balance } = useContext(UserContext);
  const { lang } = useContext(LangContext);

  return (
    <ListAssetsContainer>
      {assets.map((asset, idx) => (
        <AssetContainer key={idx}>
          <LeftContent>
            <IconContent>
              <Icon src={asset.icon} alt={asset.symbol} />
            </IconContent>
          </LeftContent>
          <RightContent>
            <Title>
              {balance
                ? formatText.formatNumber({
                    number: balance.wallet[asset.key].available,
                    fixed: asset.fixed,
                  })
                : "..."}{" "}
              {asset.symbol}
            </Title>
            <Subtitle>
              {balance
                ? formatText.formatNumber({
                    number: balance.wallet[asset.key].usd,
                    fixed: 2,
                  })
                : "..."}{" "}
              USD
            </Subtitle>
          </RightContent>

          {balance &&
            balance.earn[asset.key] &&
            asset.earnKey &&
            balance.earn[asset.key][asset.earnKey] &&
            math.isGreaterThan(
              balance.earn[asset.key][asset.earnKey].available,
              "0"
            ) && (
              <RewardButton
                onClick={() =>
                  navigate("/earn/" + asset.key, { replace: false })
                }
              >
                {lang.REWARD}
              </RewardButton>
            )}
        </AssetContainer>
      ))}
    </ListAssetsContainer>
  );
};
const Assets: React.FC = () => {
  return (
    <Container>
      <ListAssets assets={coins} />
    </Container>
  );
};

export default Assets;
