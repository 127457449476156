import triangleDown from "assets/chevron.svg";
import React, { useEffect, useState } from "react";
import {
  Container,
  Header,
  IconTriangle,
  Label,
  OptionContainer,
  OptionsContainer,
  SelectContainer,
  SelectedOption,
  SelectedOptionContainer,
  SelectOptionTitle,
  Title,
} from "./styles";

interface IMessageSelect {
  title: string;
  value: any;
  option: any;
  setValue: any;
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  options: any;
  label?: string;
  id?: string;
  setAll?: any;
}
const MessageSelect: React.FC<IMessageSelect> = ({
  title,
  value,
  setValue,
  disabled,
  containerStyle,
  options,
  label,
  setAll,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);

  useEffect(() => {
    const listener = function (e) {
      const id = `${title}-input`;
      const target = document.getElementById(id);
      if (id && target?.contains(e.target)) {
        setShowOptions(false);
      } else {
        setShowOptions(false);
      }
    };
    window.addEventListener("click", listener);
    return () => {
      window.removeEventListener("click", listener);
    };
  }, [title]);

  const handlesetValue = (idx: number) => {
    // console.log(idx);

    setValue({ approved: false, message: idx });
    if (setAll) setAll(idx);
  };

  return (
    <Container style={{ ...containerStyle }}>
      <Header>
        <Title>{title}</Title>
        {label && <Label>{label}</Label>}
      </Header>
      <SelectedOptionContainer id={`${title}-input`} disabled={disabled}>
        <SelectContainer
          onClick={(e) => {
            if (!disabled) {
              e.stopPropagation();
              setShowOptions(!showOptions);
            }
          }}
        >
          <SelectedOption>{value || "Justificativa"}</SelectedOption>
          <IconTriangle open={!showOptions} src={triangleDown} alt="option" />
        </SelectContainer>
        {showOptions && options.length > 0 && (
          <OptionsContainer onClick={() => setShowOptions(!showOptions)}>
            {options.map((option: any, idx: number) => (
              <OptionContainer key={idx} onClick={() => handlesetValue(idx)}>
                <SelectOptionTitle>{option}</SelectOptionTitle>
              </OptionContainer>
            ))}
          </OptionsContainer>
        )}
      </SelectedOptionContainer>
    </Container>
  );
};
export default MessageSelect;
