import styled, { css } from "styled-components";

export const Container = styled.div``;
export const WelcomeText = styled.span(
  (props) => css`
    ${props.theme.fonts.dmSans};
    ${props.theme.aligns.vertical};
    justify-content: space-between;
    color: ${props.theme.colors.primaryFont};
    font-weight: 700;
    font-size: 18px;
  `
);
export const WarningDocs = styled.span(
  (props) => css`
    font-weight: 500;
    font-size: 10px;
    color: #ffce31;
    cursor: pointer;
    &:hover {
      color: #ffce31dd;
    }
    ${props.theme.aligns.verticalAndHorizontal};
  `
);
export const Icon = styled.img`
  margin: 5px;
  width: 10px;
  cursor: pointer;
`;
export const Tabs = styled.div`
  width: 100%;
`;
interface ITab {
  active?: boolean;
}
export const Tab = styled.div<ITab>`
  display: none;
  ${(props) =>
    props.active &&
    css`
      display: block;
    `}
`;
export const HeaderTabs = styled.div`
  display: flex;
  padding: 0 10px;
  overflow-x: auto;
  /*CUSTOMIZING SCROLLBAR*/
  /* width */
  &::-webkit-scrollbar {
    height: 4px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.blackTransparentMindLight};
  }
`;
interface ITabHeader {
  active?: boolean;
}
export const TabHeader = styled.div<ITabHeader>`
  cursor: pointer;
  padding: 5px 15px;

  flex-shrink: 0;
  flex: 1 1 0px;
  color: ${(props) => props.theme.colors.neutralDarkest};
  ${(props) => props.theme.fonts.poppins};
  font-size: 14px;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  ${(props) =>
    props.active &&
    css`
      border-bottom: 2px solid ${(props) => props.theme.colors.secundaryFont};
      color: ${(props) => props.theme.colors.secundaryFont};
    `};
`;
export const BodyTabs = styled.div`
  padding-top: 10px;
`;
