import styled, { css } from "styled-components";
import { IMobile } from "./interfaces";

export const Container = styled.header(
  (props) => css`
    ${props.theme.aligns.horizontal};
    ${props.theme.fonts.poppins};
    background: transparent;
    position: absolute;
    width: 100%;
    height: ${props.theme.sizes.navbarHeightMob};
    z-index: 10;
    ${props.theme.responsive.lg(
      css`
        height: ${props.theme.sizes.navbarHeightDesk};
      `
    )}
  `
);
export const Nav = styled.nav<IMobile>(
  (props) => css`
    ${props.theme.paddingLayoutPage};
    ${props.mobile
      ? css`
          justify-content: center;
          flex-flow: column;
        `
      : css`
          justify-content: space-between;
        `}
    display: flex;
    align-items: center;
    width: 1280px;
    position: relative;
    height: ${props.theme.sizes.navbarHeightMob};
    ${props.theme.responsive.lg(
      css`
        height: ${props.theme.sizes.navbarHeightDesk};
      `
    )}
  `
);
export const LogoMenu = styled.img`
  position: absolute;
  left: 10px;
  padding: 10px;
  width: 20px;
  ${(props) =>
    props.theme.responsive.sm(css`
      width: 25px;
    `)}

  cursor: pointer;
`;
export const LogoContainer = styled.div<IMobile>`
  display: flex;
  cursor: pointer;
  align-items: center;
  & img {
    width: 50px;
  }
  & span {
    margin-left: 10px;
    font-weight: 700;
  }
`;
export const RightContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SettingsContainer = styled.div`
  position: relative;
`;
export const SettingButtonContainer = styled.div`
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  background-color: ${(props) => props.theme.colors.headerItemBgc};
  height: 35px;
  width: 35px;
  border-radius: 12px;
  margin-left: 20px;
  cursor: pointer;
`;
export const SettingImg = styled.img``;
export const UserAccountContainer = styled.div`
  margin: 0 20px;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  cursor: pointer;
`;
export const UserAccountIcon = styled.img`
  width: 32px;
`;
export const NetworkContainer = styled.div`
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  background-color: ${(props) => props.theme.colors.headerItemBgc};
  box-sizing: border-box;
  padding: 7px 15px;
  border-radius: 5px;
  margin-right: 20px;
`;
export const CircleStatus = styled.div<{ color?: string }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) =>
    props.color ? props.color : props.theme.colors.alertWarning};
  border-radius: 10px;
  margin-right: 5px;
`;
export const TextNetwork = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primaryFont};
  ${(props) => props.theme.fonts.barlow};
`;
