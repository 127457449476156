import formatText from "lib/formatText";

import React, { useContext, useRef, useState } from "react";
import {
  Container,
  Header,
  Input,
  InputButton,
  Label,
  SelectedOptionContainer,
  SelectOptionTitle,
  Title,
} from "./styles";
import LangContext from "contexts/Lang";

interface IInputFileWithButton {
  title: string;
  value: any;
  setValue: (val: any) => void;
  containerStyle?: React.CSSProperties;
  label?: string;
  id?: string;
  disabled?: boolean;
}
const InputFileWithButton: React.FC<IInputFileWithButton> = ({
  title,
  value,
  setValue,
  containerStyle,
  label,
  disabled,
}) => {
  const fileRef = useRef<any>(null);
  const { lang } = useContext(LangContext);
  const [fileLabel, setFileLabel] = useState("");

  const onChangeFile = () => {
    try {
      setFileLabel(fileRef.current.files[0].name);
      setValue(fileRef.current.files[0]);
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <Container style={{ ...containerStyle }}>
      <Header>
        <Title>{title}</Title>
        {label && <Label>{label}</Label>}
      </Header>
      <SelectedOptionContainer
        id={`${title}-input`}
        onClick={
          !disabled
            ? () => {
                document.getElementById(`${title}-id`)?.click();
              }
            : () => {}
        }
      >
        <Input
          ref={fileRef}
          id={`${title}-id`}
          {...{
            pattern: "[a-z]+[A-Z]?",
            type: "file",
            placeholder: "",
            onChange: onChangeFile,
          }}
          style={{ display: "none" }}
        />
        <InputButton disabled={disabled}>{lang.ATTATCH}</InputButton>
        <SelectOptionTitle>
          {fileLabel === ""
            ? fileLabel
            : formatText.truncateHash({
                hash: fileLabel,
                startLength: 4,
                endLength: 6,
              })}
        </SelectOptionTitle>
      </SelectedOptionContainer>
    </Container>
  );
};
export default InputFileWithButton;
