import logoIcon from "assets/logo_icon.svg";
import cogImg from "assets/navbar/cog.svg";
import menuImg from "assets/navbar/menu.svg";
import userImg from "assets/navbar/user.svg";
import LangContext from "contexts/Lang";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SettingsAccount from "./SettingsAccount";
import Sidenav from "./Sidenav";
import {
  CircleStatus,
  Container,
  LogoContainer,
  LogoMenu,
  Nav,
  NetworkContainer,
  RightContent,
  SettingButtonContainer,
  SettingImg,
  SettingsContainer,
  TextNetwork,
  UserAccountContainer,
  UserAccountIcon,
} from "./styles";

const MainHeader: React.FC = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [activeAccount, setActiveAccount] = useState(0);
  const [showSidenav, setShowSidenav] = useState(false);
  const [showSettingsAccount, setShowSettingsAccount] = useState(false);
  const { lang } = useContext(LangContext);

  useEffect(() => {}, [lang]);
  useEffect(() => {
    const listener = function (e) {
      setShowSettingsAccount(false);
    };
    document.body.addEventListener("click", listener);
    return () => {
      document.body.removeEventListener("click", listener);
    };
  }, []);
  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  const navigate = useNavigate();
  const handleRedirect = (path: string) => {
    if (navigate) {
      navigate(path, { replace: false });
    }
  };
  return (
    <Container>
      {screenWidth > 992 ? (
        <Nav>
          <LogoContainer onClick={() => handleRedirect("/")}>
            <img src={logoIcon} alt="logo" />
          </LogoContainer>
          <RightContent>
            <NetworkContainer>
              <CircleStatus />
              <TextNetwork>BSC Network</TextNetwork>
            </NetworkContainer>
            <UserAccountContainer onClick={() => handleRedirect("/account")}>
              <UserAccountIcon src={userImg} alt="user" />
            </UserAccountContainer>
            <SettingsContainer id="settings-account-container">
              <SettingButtonContainer
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSettingsAccount(!showSettingsAccount);
                }}
              >
                <SettingImg src={cogImg} alt="config" />
              </SettingButtonContainer>
              <SettingsAccount
                {...{
                  show: showSettingsAccount,
                  setShow: setShowSettingsAccount,
                }}
              />
            </SettingsContainer>
          </RightContent>
        </Nav>
      ) : (
        <Nav mobile>
          <LogoMenu
            src={menuImg}
            alt="menu"
            onClick={() => setShowSidenav(true)}
          />
          <LogoContainer mobile onClick={() => handleRedirect("/")}>
            <img src={logoIcon} alt="logo" />
          </LogoContainer>
        </Nav>
      )}

      <Sidenav
        {...{ activeAccount, setActiveAccount }}
        show={showSidenav}
        setShow={setShowSidenav}
      />
    </Container>
  );
};

export default MainHeader;
