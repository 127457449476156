const pt = {
  LANGUAGE: "Idioma",
  TRADE: "Trade",
  HELP: "Ajuda",
  PROFILE: "Perfil",
  COIN: "Moeda",
  COPIED: "Copiado!",
  COPY_FAILED: "Falha ao copiar!",
  LOGOUT: "Sair",
  CHANGE_ACCOUNT: "Mudar de conta",
  ADD_LEGAL_ACCOUNT: "Adicionar conta PJ",
  DEX_TAX: "Taxa DEX",
  NETWORK_TAX: "Taxa rede",
  TO_PAY: "Pagar",
  LIQUIDITY_TAX: "Taxa de liquidez",
  RECEIVE_ESTIMATED: "Receber (estimado)",
  COST: "Custa",
  FINAL_AMOUNT: "Quantidade final",
  AVAILABLE: "Disponível",
  BALANCE: "Saldo",
  LIQUIDITY: "Liquidez",
  CONFIRM_ORDER: "Confirmar ordem",
  TRANSACTION_SUBMITTED: "Transação submetida",
  VIEW_IN_EXPLORER: "Ver no explorer",
  ADD_EXPO_TO_METAMASK: "Adicionar Expo ao MetaMask",
  SETTINGS: "Configuração",
  CONNECT_WALLET: "Conectar carteira",
  READ_AND_AGREE_WITH_THE: "Li e concordo com o",
  WARNING: "Aviso",
  CLOSE: "Fechar",
  RIGHT_RESERVED: "© 2021 NEX, Todos os Direitos Reservados.",
  VIEW_ON_BSCSCAN: "Ver no bscsan",
  PENDING_TRANSACTION: "Transação pendente",
  DENIED_TRANSACTION: "Transação negada",
  TRANSACTION_COMPLETED: "Transação concluída",
  USER_DENIED_TRANSACTION: "Usuário não aprovou a transação",
  WAIT_A_FEW_SECONDS: "Aguarde alguns segundos",
  FOR: "Para",
  ADRESS: "Endereço",
  ADRESS_LABEL: "Rede: BSC BEP-20",
  CURRENCY: "Moeda",
  CHANGE_PASSWORD: "Alterar Senha",
  EXIT: "Sair",
  CURRENT_PASSWORD: "Senha Atual",
  NEW_PASSWORD: "Nova Senha",
  CONFIRM_PASSWORD: "Confirme a nova senha",
  CONFIRM: "Confirmar",
  CANCEL: "Cancelar",
  FROM: "De",
  TO: "Para",
  FEE: "Taxa",
  NICKNAME: "Apelido",
  PASSWORD: "Senha",
  EMAIL: "E-mail",
  CONFIRM_PASSWORD_S: "Confirme a senha",
  CHECKBOX: "Li e concordo com o",
  DISCLAIMER: "Aviso",
  HELLO: "Olá",
  SWAP_TOKEN: "Swap de token",
  DETAILS: "Detalhes",
  QUANTITY: "Quantidade",
  TOTAL: "Total",
  ESTIMATED_TOTAL: "Total estimado",
  TOTAL_RECEIVE: "Total a receber",
  TOTAL_SPENT: "Total gasto",
  BACK: "Voltar",
  ASSETS: "Ativos",
  AMOUNT: "Montante",
  ESTIMATE_FEE: "Estimativa de taxa",
  RESET_PASSWORD: "Resetar Senha",
  LOGIN: "Entrar",
  DONT_HAVE_ACCOUNT: "Não tem uma conta?",
  REGISTER: "Cadastre-se",
  RESET_PASSWORD_MESSAGE:
    "Enviaremos um link de redefinição de senha para seu email cadastrado na plataforma.",
  RECOVER_PASSWORD: "Recuperar senha",
  SEND_TOKEN: "Envio de token",
  CONFIRM_ACCOUNT:
    "Enviamos um novo link de confirmação para o seu e-mail cadastrado! Procure na sua caixa de entrada e acesse o link. ",
  CONFIRMED_ACCOUNT:
    "E-mail confirmado com sucesso! Clique aqui para fazer seu primeiro login! ",
  CONFIRM_ACCOUNT_INVALID:
    "O código de confirmação deste link é inválido ou já foi utilizado, verifique se era o link correto. Se você já ativou o link, verifique se consegue fazer o login.",
  CONFIRM_ACCOUNT_EXPIRED:
    "Este código de confirmação expirou, você pode solicitar que um novo código seja enviado para seu e-mail. ",
  CONFIRM_EMAIL: "Confirmar Email",
  RESEND_EMAIL: "Reenviar Email",
  CONFIRM_EMAIL_SUCCESS: "E-mail verificado com sucesso!",
  SEND: "Enviar",
  SWAP: "Trocar",
  ACTIVITIES: "Histórico",
  SIGNUP: "Cadastro",
  CONFIRM_TRANSACTION: "Confirmar Transação",
  FORGOT_PASSWOD: "Esqueci minha senha",
  DEPOSIT: "Depósito",
  ACCOUNT: "Conta",
  NEXT: "Avançar",
  INVALID_TOKEN: "Token inválido",
  TRANSACTION_SUCCESS: "Transação realizada com sucesso!",
  TRANSACTION_FAIL: "Erro na transação!",
  EXPIRED_TRANSACTION_TOKEN:
    "A transação já foi confirmada ou o tempo de confirmação da transação expirou. Faça login e verifique seu histórico.",
  SERVER_DOWN: "Atualizando novos recursos!",
  FILL_THE_INPUTS: "Preencha os campos para finalizar",
  INVALID_PASSWORD_TOKEN:
    "O código de redefinição de senha para este link é inválido, expirou ou já foi usado, verifique se era o link correto. Se você já utilizou o link, verifique se sua senha já foi alterada fazendo login.",
  REWARD: "Recompensa",
  TOKEN_TO_EARN_NOT_FOUND: "Moeda de ganho não encontrada",
  REWARD_REDEMPTION: "Resgate da recompensa",
  INVALID_CONFIRMATION_TYPE: "Tipo de confirmação inválido",
  WITHDRAW: "Saque",
  TOKEN_REWARD: "Recompensa de Token",
  REWARD_IN: "Recompensa em",
  SUCCESS: "Sucesso!",
  LOADING: "Carregando",
  CHECKING_DATA: "Verificando dados",
  ACTION: "Ação",
  PERMISSION_TO: "Permissão para",
  PERMISSION_TO_FUNDS: "Permissão para acessar fundos",
  PERMISSION: "Permissão",
  ALLOW_PLATFORM_TO_USE: "Permitir que a Plataforma utilize seu",
  COMMUNITY: "Comunidade",
  SHARE_LINK: "Compartilhe seu link",
  COPY_ID: "Copiar ID",
  SHOW_QR: "Mostrar Qr-cde",
  COMMUNITY_INFO: "Saiba como funciona a comunidade",
  COMMUNITY_DESCRIPTION: {
    items: [
      {
        title: "Regras",
        description: [
          "Para cada novo usuário, a pessoa que compartilhou o link receberá um bônus em CAU toda vez que o novo usuário receber CAU de staking. Você pode convidar livremente usuários compatilhando seu link.",
          "Exemplo:",
          "O Usuário A convida o Usuário B compartilhando seu link. O Usuário A agora recebe bônus em CAU sempre que B receber CAU de staking.",
        ],
      },
      {
        title: "Bonificação",
        description: [
          "Airdrop de 2.5% em CAU",
          "Comece sua comunidade e receba 2.5% de bonus em CAU.	",
          "Airdrop de 5% em CAU",
          "Quando sua comunidade atingir um volume negociado equivalente a 10000 CAUs em staking, você recebe 5% de bônus em CAU.",
          "Airdrop de 10% em CAU",
          "Quando sua comunidade atingir um volume negociado equivalente a 100000 CAUs em staking, você recebe 10% de bônus em CAU.",
        ],
      },
    ],
    warning: "*As regras podem mudar a qualquer momento",
  },
  SIGN_UP_CACAU: "Cadastre-se na Cacau Digital",
  SIGN_UP_INDICATION: "ID de indicação",
  SIGN_UP_INDICATION_MOBILE: "ID de indicação",
  INSUFICIENT_BALANCE: "Saldo insuficiente",
  MINIMUM_SWAP: "Swap mínimo",
  MINIMUM_SEND: "Envio mínimo",
  ADDRESS_MUST_BE_INFORMED: "O endereço de envio deve ser informado",
  TOKEN_MUST_BE_INFORMED: "O token deve ser informado",
  SHIPPING_VALUE_MUST_BE_INFORMED: "O valor de envio deve ser informado",
  SHIPPING_CURRENCY_MUST_BE_INFORMED: "A moeda de envio deve ser informada",
  CURRENCY_MUST_BE_INFORMED: "A moeda deve ser informada",
  CONVERSION_CURRENCY_MUST_BE_INFORMED:
    "A moeda de conversão deve ser informada",
  CURRENCY_TO_BE_CONVERTED_MUST_BE_INFORMED:
    "A moeda a ser convertida deve ser informada",
  CONFIRM_APPROVAL:
    "Ao confirmar, você está permitindo a plataforma acessar seus fundos",
  UPDATE_ACCOUNT_PIX:
    "Para usar o Pix é necessário terminar de preencher suas informações pessoais e submeter documentação com foto. Caso já fez este processo, aguarde a análise de sua documentação.",
  UPDATE_INFO: "Clique aqui para atualizar suas informações.",
  DEPOSIT_WARNING: "Li e concordo com o aviso",
  DECLARE_SAMPLE_ACCOUNT_CPF:
    "Declaro que meu depósito Pix é originário de uma conta pertencente ao CPF: ",
  PIX_ONLY_FOR_BRAZIL:
    "O depósito via PIX está disponível somente para brasileiros, portanto, é necessário ter um número de CPF para esta opção de depósito.",
  MIN_VALUE_IS: "O valor mínimo é de",
  TERMS_PIX_CNPJ:
    "Ainda não aceitamos depósitos vindos de contas conjuntas ou CNPJ",
  TERMS_PIX_PRE: "Não aceitamos depósitos vindos de contas pré-pagas",
  TERMS_PIX_EXT: "Não fazemos estorno automático",
  PIX: "Pix",
  DEPOSIT_ACCOUNT_PIX_BELOW: "Deposite na conta Pix abaixo",
  PIX_KEY: "Chave Pix:",
  BENEFICIARY: "Favorecido:",
  BANK: "Banco:",
  EMPLOYER_IDENTIFICATION_NUMBER: "CNPJ:",
  PLACE_HOLDER_BENEFICIARY: "Beneficiary name",
  COUNTRY: "País",
  DOCUMENT_NUMBER: "Número do CPF",
  DOCUMENT_FRONT: "Frente do Documento",
  DOCUMENT_BACK: "Fundo do Documento",
  DOCUMENT_SELF: "Selfie com o Documento",
  EXPIRED_TOKEN: `Este código de confirmação está vencido`,
  INVALID_CPF: `CPF inválido`,
  INVALID_DOCUMENT_NUMBER: `Número de documento inválido`,
  DOCUMENT_IMAGE_REQUIRED: `Documento é obrigatório`,
  COUNTRY_REQUIRED: `País é obrigatório`,
  OPERATION_EXECUTION_FAILED: "Erro ao executar operação",
  INVALID_VOUCHER: "Voucher não é válido, promoção não aplicada",
  INVALID_FILE_TYPE:
    "Insira um documento de imagem válido (png, jpg, jpeg, tiff, webp, jfif).",
  INVALID_FILE_SIZE: "Insira um documento de tamanho menor que 10MB.",
  DOCUMENT_SUBMITTED: "Documento já submetido.",
  ATTATCH: "Anexar",
  YOUR_DOCUMENT: "documento.png",
  SIGN_UP: "Cadastro",
  WAITING_VERIFICATION: "Verificação",
  DOCUMENTS_VERIFIED: "Documentos Verificados",
  EMAIL_OK: "email ok",
  RECEIVED: "recebido",
  NOT_APPROVED: "não aprovado",
  UNDER_ANALYSIS: "em análise",
  APPROVED: "aprovado",
  VERIFIED: "verificado",
  ACCOUNT_DATA: "Dados da conta",
  PERSONAL_DATA: "Dados pessoais",
  DOCUMENTS: "Documentos",
  FIRST_NAME: "Primeiro nome",
  FULL_NAME: "Nome Completo",
  BIRTH_DATE: "Data de nascimento",
  PHONE: "Celular",
  UPDATE: "Atualizar",
  TEXT_PHOTO_DOCUMENT:
    "Para uma melhor análise, a foto deve estar com as informações visíveis. O número do documento deve ser o mesmo da foto do documento.  Cada imagem devem conter no máximo 10MB",
  TEXT_ANALYSIS_DOCUMENT:
    "Sua documentação está sujeita à aprovação pela nossa equipe de compliance em no máx 48h.",
  DOCUMENT_ANALYSIS:
    "Seus documentos foram recebidos e estão sendo analisados por nossa equipe, aguarde.",
  DOCUMENT_NOT_ACCEPTED:
    "Seus documentos não foram aprovados, clique aqui para tentar novamente.",
  SAVE: "Salvar",
  SHOW_DETAILS: "Mostrar detalhes",
  DOCUMENTATION_REFUSED: "Documentação recusada",
  ERRORS_FOUND: "Erros encontrados",
  ERROR_INVALID_WALLET: "Carteira inválida",
};

export default pt;