import styled, { css } from "styled-components";

export const Container = styled.div``;
export const ListActivitiesContainer = styled.div`
  height: 200px;
  overflow: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
export const ActivityContainer = styled.div`
  background-color: ${(props) => props.theme.colors.tertiary};
  height: 60px;
  width: 100%;
  border-radius: 8px;
  margin: 10px 0;
  color: ${(props) => props.theme.colors.primary};
  ${(props) => props.theme.fonts.poppins};
`;
export const HeaderContent = styled.div`
  padding: 10px 10px 0 10px;
  ${(props) => props.theme.aligns.vertical};
  justify-content: space-between;
`;
export const BodyContent = styled.div`
  margin-top: 5px;
  padding: 0 10px;
  ${(props) => props.theme.aligns.vertical};
  justify-content: space-between;
`;
export const Title = styled.span`
  font-weight: 700;
  font-size: 14px;
`;
export const Subtitle = styled(Title)`
  ${(props) => props.theme.fonts.barlow};
  color: ${(props) => props.theme.colors.primarySupport};
`;
export const Status = styled.span<{ hash?: string }>(
  (props) => css`
    font-size: 12px;
    min-width: 75px;
    padding: 5px 10px;
    border-radius: 10px;
    ${props.hash
      ? css`
          background-color: ${props.theme.colors.secundarySupport};
          color: ${props.theme.colors.primarySupport};
        `
      : css`
          background-color: ${props.theme.colors.secundarySupport};
          color: ${props.theme.colors.primarySupport};
        `}
    ${props.theme.fonts.inter};
    ${props.theme.aligns.verticalAndHorizontal};
  `
);
export const HashLink = styled.a`
  color: ${(props) => props.theme.colors.primaryLink};
  font-size: 12px;
  &:hover {
    color: ${(props) => props.theme.colors.primarySupport};
  }
`;
