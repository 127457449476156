import styled, { css } from "styled-components";

export const Container = styled.div(
  (props) => css`
    box-sizing: border-box;
    padding: 10px;
    ${props.theme.aligns.vertical};
  `
);
interface IArrow {
  disabled?: boolean;
}
export const ArrowLeft = styled.img<IArrow>(
  (props) => css`
    margin: 0 5px;

    border-radius: 5px;
    padding: 8px 8px;
    ${props.disabled
      ? css``
      : css`
          cursor: pointer;
          &:hover {
            background-color: white;
            font-weight: 700;
          }
        `}
  `
);
export const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;
interface INumberListItem {
  active?: boolean;
}
export const NumberListItem = styled.div<INumberListItem>(
  (props) => css`
    box-sizing: border-box;
    padding: 5px 8px;
    margin: 0px 2px;
    cursor: pointer;
    color: white;
    background-color: #2c2e2f;
    border-radius: 5px;
    &:hover {
      background-color: #2c2e2faa;
      font-weight: 700;
    }
    ${props.theme.fonts.montserrat};
  `
);
