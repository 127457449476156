// import shareIcon from "assets/share.svg";
import Tabs, { ITabInfo } from "components/Tabs";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import Main from "pages/templates/Main";
import PersonalData from "./PersonalData";
import Documents from "./Documents";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "./styles";

const DataContent: React.FC = () => {
  const { user } = useContext(UserContext);
  const { lang } = useContext(LangContext);
  const [documentsAlreadySubmitted, setDocumentsAlreadySubmitted] =
    useState(false);

  useEffect(() => {
    const userHasPersonalInfoFilled = () => {
      if (user.name && user.fullname && user.birthdate && user.cellphone) {
        return true;
      }
      return false;
    };

    // console.log({ user });
    if (
      user.documentStatus === "processing" ||
      user.documentStatus === "confirmed"
    ) {
      setDocumentsAlreadySubmitted(true);
    } else if (!userHasPersonalInfoFilled()) {
      setDocumentsAlreadySubmitted(false);
    } else {
      setDocumentsAlreadySubmitted(true);
    }
  }, [user]);

  const tabInfo: ITabInfo = {
    personalData: {
      title: lang.PERSONAL_DATA,
      content: <PersonalData />,
      disabled: false,
    },
    documents: {
      title: lang.DOCUMENTS,
      content: <Documents />,
      disabled: !documentsAlreadySubmitted,
    },
  };
  return (
    <Container>
      <Tabs tabInfo={tabInfo} tabKeyDefault="personalData" />
    </Container>
  );
};
const AccountData: React.FC = () => {
  const { lang } = useContext(LangContext);

  return (
    <Main headerBody={{ title: lang.ACCOUNT_DATA, backPath: "/account" }}>
      <DataContent />
    </Main>
  );
};
export default AccountData;
