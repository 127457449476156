import Alert from "components/notifications/Alert";
import { alert, INotification, makeid } from "lib/Alert";
import React, { createContext, useEffect, useState } from "react";

interface IAlertContext {
  notifications: INotification[];
}
const AlertContext = createContext<IAlertContext>({
  notifications: [],
});

export const AlertProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  useEffect(() => {
    alert.init({ open, closeAll });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const verifyId = (id) => notifications.find((notify) => notify.id === id);

  const open = (data: INotification, closeNotification?: boolean) => {
    if (closeNotification) closeAll();
    if (!data.id) data.id = makeid(15);
    if (!verifyId(data.id)) {
      setNotifications((state) => [...state, data]);
    }
  };
  const closeAll = () => {
    setNotifications([]);
  };
  return (
    <AlertContext.Provider
      value={{
        notifications,
      }}
    >
      <Alert />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
