import styled, { css } from "styled-components";
interface IDisabled {
  disabled?: boolean;
}
export const Container = styled.div``;
export const Title = styled.span(
  (props) => css`
    font-size: 14px;
    font-weight: 700;
    color: ${props.theme.colors.primaryFont};
    margin-left: 6px;
    ${props.theme.fonts.poppins};
  `
);

export const SelectedOptionContainer = styled.div<IDisabled>(
  (props) => css`
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    margin-top: 3px;
    position: relative;
    width: 100%;
    ${props.theme.responsive.sm(css`
      width: 320px;
    `)}
    ${props.theme.aligns.vertical};
    ${props.disabled
      ? css`
          border: 1px solid ${props.theme.colors.neutralDarker};
          background-color: ${props.theme.colors.whiteLighter};
        `
      : css`
          border: 1px solid ${props.theme.colors.primaryBorderInput};
        `}
  `
);
export const SelectedOption = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    ${props.theme.aligns.vertical};
    font-size: 12px;
    font-weight: 500;
    width: 40px;
    padding: 0 0 0 5px;
    height: 15px;
    color: ${props.theme.colors.primaryFont};
  `
);
export const IconOption = styled.img`
  width: 25px;
  margin: 0 0 0 10px;
`;
export const IconTriangle = styled.img`
  width: 10px;
  margin: 0 10px 0 0;
`;
export const OptionsContainer = styled.div`
  height: 200px;
  z-index: 1;
  top: 33px;
  border: 1px solid ${(props) => props.theme.colors.tertiary};
  left: -1px;
  width: 100%;
  position: absolute;
  background-color: ${(props) => props.theme.colors.tertiary};
  padding: 15px 0 0 0;
  box-shadow: 0px 4px 4px
    ${(props) => props.theme.colors.blackTransparentDarker};
  border-radius: 8px;
`;
export const OptionContainer = styled.div(
  (props) => css`
    padding: 8px 0;
    ${props.theme.aligns.vertical};
    &:hover {
      background-color: ${props.theme.colors.secundarySupport}77;
    }
  `
);

export const SelectOptionTitle = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    ${props.theme.aligns.vertical};
    font-weight: 600;
    font-size: 14px;
    padding: 0 5px;
    width: 100%;
    color: ${props.theme.colors.primarySupport};
  `
);

export const IconOptions = styled(IconOption)``;
export const Input = styled.input(
  (props) => css`
    ${props.theme.fonts.poppins};
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    height: 100%;
    color: ${props.theme.colors.primaryFont};
    border-radius: 0 16px 16px 0;
    ${props.disabled &&
    css`
      color: ${props.theme.colors.primaryTextDisabled};
    `}
    &:focus {
      border-bottom: none;
      margin: 0;
      box-shadow: none;
      outline: none;
    }
    &::placeholder {
      color: ${props.theme.colors.neutralDarker};
      ${props.theme.fonts.poppins};
      font-weight: 700;
    }
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  `
);
export const SelectContainer = styled.div`
  ${(props) => props.theme.aligns.vertical}
`;
export const Header = styled.div(
  (props) => css`
    ${props.theme.aligns.vertical};
    justify-content: space-between;
    width: 100%;
    ${props.theme.responsive.sm(css`
      width: 320px;
    `)}
    height: 20px;
  `
);
export const Label = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    font-weight: 300;
    color: ${props.theme.colors.primaryFont};
    font-size: 12px;
  `
);
