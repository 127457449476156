import arrowLeft from "assets/navbar/arrow_left.svg";
import GuestHeader from "components/headers/GuestHeader";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Body,
  CardBody,
  Container,
  ContentCard,
  HeaderBody,
  IconHeaderBody,
  TitleHeaderBody,
} from "../styles";
interface IGuest {
  headerBody?: { title: string; backPath: string };
  children: any;
  settingsAccount?: boolean;
}
const Guest: React.FC<IGuest> = ({ children, headerBody, settingsAccount }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <GuestHeader settingsAccount={settingsAccount} />
      <Body>
        <ContentCard>
          {headerBody && (
            <HeaderBody>
              <IconHeaderBody
                src={arrowLeft}
                alt="back"
                onClick={() => navigate(headerBody.backPath, { replace: true })}
              />
              <TitleHeaderBody>{headerBody.title}</TitleHeaderBody>
            </HeaderBody>
          )}
          <CardBody>{children}</CardBody>
        </ContentCard>
      </Body>
    </Container>
  );
};

export default Guest;
