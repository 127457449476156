import styled, { css } from "styled-components";
interface IDisabled {
  disabled?: boolean;
}
export const Container = styled.div``;
export const Title = styled.span(
  (props) => css`
    font-size: 14px;
    font-weight: 700;
    margin-left: 6px;
    color: ${props.theme.colors.primaryFont};
    ${props.theme.fonts.poppins};
  `
);

export const InputContainer = styled.div<IDisabled>(
  (props) => css`
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 3px;
    width: 100%;
    ${props.theme.responsive.sm(css`
      width: 320px;
    `)}
    ${props.theme.aligns.vertical};
    ${props.disabled
      ? css`
          border: 1px solid ${props.theme.colors.neutralDarker};
          background-color: ${props.theme.colors.whiteLighter};
        `
      : css`
          border: 1px solid ${props.theme.colors.primaryBorderInput};
        `}
    height: ${props.theme.sizes.inputsHeightMob};
  `
);
export const Input = styled.input(
  (props) => css`
    ${props.theme.fonts.poppins};
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    height: 100%;
    color: ${props.theme.colors.primaryFont};
    border-radius: 8px;

    ${props.disabled &&
    css`
      color: ${props.theme.colors.primaryTextDisabled};
    `}
    &:focus {
      border-bottom: none;
      margin: 0;
      box-shadow: none;
      outline: none;
    }
    &::placeholder {
      ${props.theme.fonts.poppins};
      font-weight: 700;
    }
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  `
);
export const MaxButton = styled.span(
  (props) => css`
    background: ${props.theme.colors.maxButtonBgc};
    border-radius: 5px;
    width: 45px;
    height: 25px;
    font-size: 10px;
    margin-right: 10px;
    color: ${props.theme.colors.primarySupport};
    cursor: pointer;
    ${props.theme.fonts.poppins};
    ${props.theme.aligns.verticalAndHorizontal};
    &:hover {
      background: ${props.theme.colors.maxButtonHover};
    }
  `
);
export const Header = styled.div(
  (props) => css`
    ${props.theme.aligns.vertical};
    justify-content: space-between;
    width: 100%;
    ${props.theme.responsive.sm(css`
      width: 320px;
    `)}
    height: 20px;
  `
);
export const Label = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    color: ${props.theme.colors.primaryFont};
    font-weight: 300;
    font-size: 12px;
  `
);
