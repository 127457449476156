import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  min-height: 100vh;
  height: auto;
  width: 100%;
  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    width: 1228px;
    height: 1228px;
    right: -842px;
    top: -50px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #0c1532 40%,
      rgba(6, 19, 31, 0) 100%
    );
    opacity: 0.7;
  }
  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    width: 1228px;
    height: 628px;
    left: -742px;
    top: -50px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #0c1532 40%,
      rgba(6, 19, 31, 0) 100%
    );
    opacity: 0.7;
  }
  overflow-x: hidden;
  overflow-y: hidden;
`;
export const Body = styled.main(
  (props) => css`
    ${props.theme.aligns.verticalAndHorizontal};
    width: 100%;
    min-height: calc(100vh - ${props.theme.sizes.navbarHeightMob});
    margin-top: ${props.theme.sizes.navbarHeightMob};
    ${props.theme.responsive.lg(
      css`
        min-height: calc(100vh - ${props.theme.sizes.navbarHeightDesk});
        margin-top: ${props.theme.sizes.navbarHeightDesk};
      `
    )}
  `
);
export const ContentCard = styled.div(
  (props) => css`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-height: 600px;
    background-color: ${props.theme.colors.primaryBgc};
    box-shadow: 0px 4px 4px ${props.theme.colors.blackTransparentDarker};
    width: 100%;
    ${props.theme.aligns.vertical};
    flex-flow: column;
    ${props.theme.responsive.sm(css`
      flex-flow: row wrap;
      ${props.theme.aligns.initial};
      width: auto;
    `)}
  `
);
export const CardBody = styled.div`
  box-sizing: border-box;
  padding: 15px 10px;
  width: 100%;
  ${(props) =>
    props.theme.responsive.sm(css`
      padding: 25px 20px;
      width: 375px;
    `)}
`;
export const IconHeaderBody = styled.img`
  position: absolute;
  cursor: pointer;
  padding: 8px 10px 10px 2px;
  left: 20px;
`;
export const TitleHeaderBody = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.primaryFont};
  ${(props) => props.theme.fonts.poppins};
`;
export const HeaderBody = styled.div`
  position: relative;
  height: 62px;
  width: 100%;
  border-bottom: 4px solid ${(props) => props.theme.colors.primaryDark};
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;
