import styled from "styled-components";
import { css } from "styled-components";
import { DropdownContainer } from "./interfaces";

export const Container = styled.div``;
export const UserInfoContainer = styled.div`
  ${(props) => props.theme.aligns.vertical};

  margin-bottom: 30px;
  box-sizing: border-box;
`;
export const InfosContainer = styled.div`
  ${(props) => props.theme.aligns.horizontal};
  flex-flow: column;
`;
export const UserIcon = styled.img`
  padding: 10px;
  border-radius: 5px;
  margin-right: 15px;
  background-color: ${(props) => props.theme.colors.tertiary};
`;
export const TextEmail = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 700;
  color: ${(props) => props.theme.colors.primaryFont};
  font-size: 14px;
  line-height: 20px;
`;
export const TextHash = styled.span`
  ${(props) => props.theme.fonts.inter};
  font-size: 13px;
  color: ${(props) => props.theme.colors.secundarySupport};
`;
export const ContainerOption = styled.div`
  cursor: pointer;
  ${(props) => props.theme.aligns.vertical};
  box-sizing: border-box;
  padding: 20px 20px;
  margin: 5px 0;
  background-color: ${(props) => props.theme.colors.tertiary};
  border-radius: 5px;
  transition: all 0.5 ease-in-out;
  &:hover {
    background-color: ${(props) => props.theme.colors.tertiarySupport};
  }
`;
export const ContainerOptions = styled.div`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 700;
  position: relative;
`;
export const IconOption = styled.img`
  margin-right: 15px;
`;
export const TitleOption = styled.span`
  color: ${(props) => props.theme.colors.primarySupport};
  font-size: 14px;
`;
export const LabelOption = styled.span`
  color: ${(props) => props.theme.colors.secundary};
  position: absolute;
  right: 15px;
`;
export const ContainerOptionDropdown = styled.div`
  cursor: pointer;
  ${(props) => props.theme.aligns.vertical};
  box-sizing: border-box;
  padding: 20px 20px;
  margin: 5px 0;
  background-color: ${(props) => props.theme.colors.tertiary}ee;
  transition: all 0.5 ease-in-out;
  border-radius: 5px;
  &:hover {
    background-color: ${(props) => props.theme.colors.tertiarySupport};
  }
`;

export const ContainerOptionsDropdown = styled.div<DropdownContainer>`
  ${(props) =>
    props.active
      ? css`
          height: 100%;
          opacity: 1;
        `
      : css`
          display: none;
          height: 0;
          opacity: 0;
          z-index: -1;
        `};
  ${(props) => props.theme.fonts.poppins};
  font-weight: 700;
  position: relative;
  transition: all 0.5 ease-in-out;
`;
