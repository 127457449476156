import styled, { css } from "styled-components";
interface IDisabled {
  disabled?: boolean;
}
export const Container = styled.div`
  z-index: 1;
`;
export const Title = styled.span(
  (props) => css`
    font-size: 14px;
    font-weight: 700;
    color: ${props.theme.colors.primaryFont};
    margin-left: 6px;
    ${props.theme.fonts.poppins};
  `
);

export const SelectedOptionContainer = styled.div<IDisabled>(
  (props) => css`
    cursor: pointer;
    z-index: 1;
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    margin-top: 3px;
    position: relative;
    width: 100%;

    ${props.theme.responsive.sm(css`
      width: 320px;
    `)}
    ${props.theme.aligns.vertical};
    ${props.disabled
      ? css`
          border: 1px solid ${props.theme.colors.neutralDarker};
          background-color: ${props.theme.colors.whiteLighter};
        `
      : css`
          border: 1px solid ${props.theme.colors.primaryBorderInput};
        `}
  `
);
export const SelectedOption = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    ${props.theme.aligns.vertical};
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    padding: 0 15px;
    height: 15px;
    color: ${props.theme.colors.primaryFont};
  `
);
export const IconOption = styled.img`
  width: 25px;
  margin: 0 0 0 10px;
`;
export const IconTriangle = styled.img`
  width: 10px;
  margin: 0 10px;
`;
export const OptionsContainer = styled.div`
  height: 200px;
  top: 32px;
  border: 1px solid ${(props) => props.theme.colors.tertiary};
  left: -1px;
  width: 100%;
  position: absolute;
  background-color: ${(props) => props.theme.colors.tertiary};
  padding: 15px 0 0 0;
  box-shadow: 0px 4px 4px
    ${(props) => props.theme.colors.blackTransparentDarker};
  border-radius: 8px;
`;
export const OptionContainer = styled.div(
  (props) => css`
    z-index: 1;
    padding: 8px 0;
    ${props.theme.aligns.vertical};
    &:hover {
      background-color: ${props.theme.colors.secundarySupport}77;
    }
  `
);
export const SelectOptionContainer = styled.div`
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
  padding: 0 15px;
  width: 100%;
`;
export const SelectOptionTitle = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    ${props.theme.aligns.vertical};
    font-size: 12px;
    font-weight: 600;
    width: 100%;

    color: ${props.theme.colors.primarySupport};
  `
);
export const SelecdOptionSubtitle = styled(SelectOptionTitle)`
  font-size: 8px;
  font-weight: 300;
`;
export const IconOptions = styled(IconOption)``;
