import styled, { css } from "styled-components";

export const Container = styled.div``;
export const Form = styled.form`
  min-height: 380px;
  width: 100%;
  ${(props) =>
    props.theme.responsive.sm(css`
      flex-flow: column;
    `)}
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
`;
export const ButtonContainer = styled.div`
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;
export const Message = styled.span`
  width: 270px;

  ${(props) =>
    props.theme.responsive.sm(css`
      width: 320px;
    `)}
  ${(props) => props.theme.fonts.roboto};
  font-weight: 300;
  color: ${(props) => props.theme.colors.primaryFont};
  font-size: 12px;
`;
