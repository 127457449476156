import qrcode from "qrcode-generator";
import { language } from "./Language";
import { alert } from "lib/Alert";
import math from "./math";
const helpers = {
  copyToClipboard: (value: string) => {
    if (window.isSecureContext || window.location.hostname === "localhost") {
      navigator.clipboard.writeText(value);
      alert.open({ title: language.lang.COPIED, type: "info" }, true);
      return;
    }
    alert.open({ title: language.lang.COPY_FAILED, type: "error" }, true);
    return;
  },
  createQrcodeUrl: (url) => {
    let qr = qrcode(0, "L");
    qr.addData(url);
    qr.make();
    return qr.createDataURL(6, 4);
  },
  convertCoin: (data: {
    amount: string | number;
    multiplier: string | number;
  }) => {
    return math.mul(data.amount, data.multiplier);
  },
};
export default helpers;
