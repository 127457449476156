import Admin from "pages/templates/Admin";
import React, { useContext, useEffect, useState } from "react";
import adminService from "services/adminService";

import { alert } from "lib/Alert";
import validator from "lib/validator";
import { useNavigate } from "react-router-dom";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import { cpf as cpf_validator } from "cpf-cnpj-validator";
import { TailSpin } from "react-loader-spinner";
import { Body, Table, Td, Th, Tr } from "../ListUsers/styles";
import {
  BigImg,
  BigImgContainer,
  Container,
  CpfContainer,
  CpfIcon,
  DocumentContainer,
  DocumentImageContainer,
  Documents,
  DocumentTitle,
  EmptyIcon,
  EvaluateContainer,
  FooterContainer,
  Loading,
  SectionTitle,
} from "./styles";
import cpfIcon from "assets/cpf.svg";
import emptyIcon from "assets/empty.svg";
import MessageSelect from "components/MessageSelect";
import Checkbox from "components/CheckBox";
import ButtonGradient from "components/buttons/ButtonGradient";
import { setConstantValue } from "typescript";

const DocumentationContent: React.FC = () => {
  const [disableButton, setDisableButton] = useState(true);
  const [selected, setSelected] = useState(null);
  const [approvedAll, setApprovedAll] = useState<{
    approved: boolean;
    message: number;
  }>({
    message: -1,
    approved: false,
  });
  const [approvedNumber, setApprovedNumber] = useState<{
    approved: boolean;
    message: number;
  }>({
    message: -1,
    approved: false,
  });
  const [approvedFileBack, setApprovedFileBack] = useState<{
    approved: boolean;
    message: number;
  }>({
    message: -1,
    approved: false,
  });
  const [approvedFileFront, setApprovedFileFront] = useState<{
    approved: boolean;
    message: number;
  }>({
    message: -1,
    approved: false,
  });
  const [approvedFileSelf, setApprovedFileSelf] = useState<{
    approved: boolean;
    message: number;
  }>({
    message: -1,
    approved: false,
  });

  const [bigImg, setBigImg] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState<any>();
  const [documentNumber, setDocumentNumber] = useState("");
  const navigate = useNavigate();
  const { currentLangKey, changeLang, lang } = useContext(LangContext);

  useEffect(() => {
    if (
      // Se todos aprovados ativa botao
      approvedAll.approved ||
      // Se todos os campos foram validados (aprovado ou reprovado)
      (((!approvedNumber.approved && approvedNumber.message >= 0) ||
        approvedNumber.approved) &&
        ((!approvedFileBack.approved && approvedFileBack.message >= 0) ||
          approvedFileBack.approved) &&
        ((!approvedFileFront.approved && approvedFileFront.message >= 0) ||
          approvedFileFront.approved) &&
        ((!approvedFileSelf.approved && approvedFileSelf.message >= 0) ||
          approvedFileSelf.approved))
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [
    approvedAll,
    approvedNumber,
    approvedFileBack,
    approvedFileFront,
    approvedFileSelf,
  ]);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      // console.log({ approvedNumber, approvedFileBack, approvedFileFront, approvedFileSelf });
      const response = await adminService.adminAnalysisSave({
        documentNumber: user.documentNumber,
        analysis: {
          documentNumber: approvedNumber,
          documentFileBack: approvedFileBack,
          documentFileFront: approvedFileFront,
          documentFileSelf: approvedFileSelf,
        },
      });
      setSubmitLoading(false);
      // closeAllNotifications();
      alert.closeAll();
      alert.open({
        title: response.message,
        type: "success",
      });
      setDocumentNumber("");
      navigate("/users");
    } catch (e: any) {
      setSubmitLoading(false);
      alert.closeAll();
      const response = e.response;
      // closeAllNotifications();
      // console.log(e);
      if (response && response.data) {
        validator.verifyValidationErrors(response.data);
        validator.verifyErrors(response.data);
      }
    }
  };

  useEffect(() => {
    const getMessages = async () => {
      try {
        const response = await adminService.adminAnalysisMessage();
        // console.log({ response });
        setMessages(response.data);
      } catch (e: any) {
        // console.log(e);
        if (e.response && e.response.data) {
          const response = e.response;
          validator.verifyValidationErrors(response.data);
          validator.verifyErrors(response.data);
        }
      }
    };
    const getUser = async () => {
      const queryString = window.location.hash.split("?")[1];
      const urlParams = new URLSearchParams(queryString);
      const email = urlParams.get("email");
      const documentNumber = urlParams.get("document-number");

      try {
        setSelected(null);
        await new Promise((resolve, reject) => {
          if (!documentNumber && !email)
            reject({
              name: "input",
              message: lang.INVALID_DOCUMENT_NUMBER,
            });
          resolve("");
        });
        const response = await adminService.adminShowUser({
          email,
          documentNumber: documentNumber,
        });
        setUser(response.data);
        setDocumentNumber(
          documentNumber ? documentNumber : response.data.documentNumber
        );
      } catch (e: any) {
        // console.log({ e });
        if (documentNumber) setDocumentNumber(documentNumber);

        setUser(null);

        if (e.response && e.response.data) {
          const response = e.response;
          validator.verifyValidationErrors(response.data);
          validator.verifyErrors(response.data);
        }
      }
    };
    getUser();
    getMessages();
    // console.log({ user });
    // console.log({ messages });
  }, [documentNumber, navigate]);

  const handleApprovedAll = (status) => {
    if (status) {
      setApprovedAll({
        message: -1,
        approved: true,
      });
      setApprovedNumber({
        message: -1,
        approved: true,
      });
      setApprovedFileBack({
        message: -1,
        approved: true,
      });
      setApprovedFileFront({
        message: -1,
        approved: true,
      });
      setApprovedFileSelf({
        message: -1,
        approved: true,
      });
    } else {
      setApprovedAll({
        message: -1,
        approved: false,
      });
      setApprovedNumber({
        message: -1,
        approved: false,
      });
      setApprovedFileBack({
        message: -1,
        approved: false,
      });
      setApprovedFileFront({
        message: -1,
        approved: false,
      });
      setApprovedFileSelf({
        message: -1,
        approved: false,
      });
    }
  };
  const handleApprovedAllMessage = (message: number) => {
    setApprovedAll({ approved: false, message: message });
    setApprovedNumber({ approved: false, message: message });
    setApprovedFileBack({ approved: false, message: message });
    setApprovedFileFront({ approved: false, message: message });
    setApprovedFileSelf({ approved: false, message: message });
  };

  const HeaderContent = () => {
    return (
      <>
        <SectionTitle>Informações de Usuário</SectionTitle>
        <Container>
          <Body style={{ minHeight: "fit-content" }}>
            <Table>
              <thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>E-mail</Th>
                  <Th>Telefone</Th>
                  <Th>CPF</Th>
                  <Th>Nº Reprovações</Th>
                  <Th>Status</Th>
                </Tr>
              </thead>
              <tbody>
                <Tr>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.cellphone}</Td>
                  <Td>{user.documentNumber}</Td>
                  <Td>{user.analysisDisapprovedAmount}</Td>
                  <Td style={{ color: "#D6A84C" }}>{user.statusMessages}</Td>
                </Tr>
              </tbody>
            </Table>
          </Body>
        </Container>
      </>
    );
  };

  const CpfContent = () => {
    return (
      <>
        <SectionTitle>Cpf:*</SectionTitle>
        <CpfContainer>
          <CpfIcon src={cpfIcon} alt={"cpf-icon"} />
          {user.documentNumber}
        </CpfContainer>
        <EvaluateContainer>
          <Checkbox
            {...{
              value: approvedNumber.approved,
              setValue: setApprovedNumber,
              column: false,
            }}
          />
          <MessageSelect
            {...{
              value: messages[approvedNumber.message],
              setValue: setApprovedNumber,
              option: "",
              options: messages,
              title: "Reprovar",
            }}
          />
        </EvaluateContainer>
      </>
    );
  };
  const AnaliseContent = () => {
    return (
      <>
        <SectionTitle>Análise:*</SectionTitle>
        <Documents>
          <DocumentContainer>
            <DocumentTitle>Frente do Documento</DocumentTitle>
            <DocumentImageContainer>
              <EmptyIcon
                onClick={() =>
                  handleBigImgOpen(
                    `data:image/jpeg;base64,${user.documentFileFront}`
                  )
                }
                src={`data:image/jpeg;base64,${user.documentFileFront}`}
                alt="empty-icon"
              />
            </DocumentImageContainer>
            <EvaluateContainer file={true}>
              <Checkbox
                {...{
                  value: approvedFileFront.approved,
                  setValue: setApprovedFileFront,
                  column: false,
                }}
              />
              <MessageSelect
                {...{
                  value: messages[approvedFileFront.message],
                  setValue: setApprovedFileFront,
                  option: "",
                  options: messages,
                  title: "Reprovar",
                }}
              />
            </EvaluateContainer>
          </DocumentContainer>
          <DocumentContainer>
            <DocumentTitle>Fundo do Documento</DocumentTitle>
            <DocumentImageContainer>
              <EmptyIcon
                onClick={() =>
                  handleBigImgOpen(
                    `data:image/jpeg;base64,${user.documentFileBack}`
                  )
                }
                src={`data:image/jpeg;base64,${user.documentFileBack}`}
                alt="empty-icon"
              />
            </DocumentImageContainer>
            <EvaluateContainer file={true}>
              <Checkbox
                {...{
                  value: approvedFileBack.approved,
                  setValue: setApprovedFileBack,
                  column: false,
                }}
              />
              <MessageSelect
                {...{
                  value: messages[approvedFileBack.message],
                  setValue: setApprovedFileBack,
                  option: "",
                  options: messages,
                  title: "Reprovar",
                }}
              />
            </EvaluateContainer>
          </DocumentContainer>
          <DocumentContainer>
            <DocumentTitle>Selfie com o Documento</DocumentTitle>
            <DocumentImageContainer>
              <EmptyIcon
                onClick={() =>
                  handleBigImgOpen(
                    `data:image/jpeg;base64,${user.documentFileSelf}`
                  )
                }
                src={`data:image/jpeg;base64,${user.documentFileSelf}`}
                alt="empty-icon"
              />
            </DocumentImageContainer>
            <EvaluateContainer file={true}>
              <Checkbox
                {...{
                  value: approvedFileSelf.approved,
                  setValue: setApprovedFileSelf,
                  column: false,
                }}
              />
              <MessageSelect
                {...{
                  value: messages[approvedFileSelf.message],
                  setValue: setApprovedFileSelf,
                  option: "",
                  options: messages,
                  title: "Reprovar",
                }}
              />
            </EvaluateContainer>
          </DocumentContainer>
        </Documents>
      </>
    );
  };

  const FooterContent = () => {
    return (
      <>
        <FooterContainer>
          <EvaluateContainer>
            <Checkbox
              {...{
                value: approvedAll.approved,
                setValue: setApprovedAll,
                setAll: handleApprovedAll,
                column: false,
                title: "Aprovar Todos",
              }}
            />

            <MessageSelect
              {...{
                value: messages[approvedAll.message],

                setAll: handleApprovedAllMessage,
                setValue: setApprovedAll,
                option: "",
                options: messages,
                title: "Reprovar",
              }}
            />
          </EvaluateContainer>
          <ButtonGradient
            {...{
              disabled: disableButton,
              title: "Salvar Análise",
              onClick: handleSubmit,
              width: "25%",
            }}
          />
        </FooterContainer>
      </>
    );
  };
  const handleBigImgOpen = (img?: string) => {
    let html = document.getElementsByTagName("html");
    if (img) {
      setBigImg(img);
      html[0].style.overflow = "hidden";
    } else {
      setBigImg("");
      html[0].style.overflow = "auto";
    }
  };
  const BigImgContent = () => {
    return (
      <BigImgContainer onClick={() => handleBigImgOpen()}>
        <BigImg src={bigImg} />
      </BigImgContainer>
    );
  };
  return user ? (
    <>
      {bigImg && <BigImgContent />}
      <HeaderContent />
      <CpfContent />
      <AnaliseContent />
      <FooterContent />
    </>
  ) : (
    <Loading>
      <TailSpin height="55" width="55" color="white" ariaLabel="" />
    </Loading>
  );
};

const DocumentationUser = () => {
  return (
    <Admin>
      <DocumentationContent />
    </Admin>
  );
};
export default DocumentationUser;
