import arrowRightIcon from "assets/arrow_right.svg";
import ButtonDefault from "components/buttons/ButtonDefault";
import ButtonGradient from "components/buttons/ButtonGradient";
import SpreadLoading from "components/SpreadLoading";
import ErrorMessage from "components/texts/ErrorMessage";
import { coins, dictionaryCoins, sendCoinType } from "constants/coins";
import AuthContext from "contexts/Auth";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import { alert } from "lib/Alert";
import formatText from "lib/formatText";
import validator from "lib/validator";
import LoadingPage from "pages/LoadingPage";
import Main from "pages/templates/Main";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import transactionService from "services/transactions";

import {
  Arrow,
  Body,
  ButtonsContainer,
  Container,
  DescriptionRowTransactionInfo,
  InfoContent,
  RowTransactionInfo,
  TitleHeader,
  TitleRowTransactionInfo,
  TitleTransactionInfo,
  TransactionInfo,
} from "../styles";
import {
  Header,
  IconCoin,
  IconCoinContainer,
  WalletAddressContainer,
  WalletAddressHash,
  WalletAddressTitle,
  WalletContainer,
} from "./styles";

const SendToken: React.FC = () => {
  const { updateUserData } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [fee, setFee] = useState<any>("");
  const [to, setTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingEstimate, setLoadingEstimate] = useState(true);
  const [amount, setAmount] = useState("");
  const [coin, setCoin] = useState<sendCoinType>();
  const navigate = useNavigate();
  const { lang } = useContext(LangContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const estimateGas = async () => {
      try {
        setLoadingEstimate(true);

        if (coin && amount && to) {
          const res = await transactionService.estimateGasSend({
            coin,
            value: amount,
            to,
          });

          setFee(res.data);
          setTimeout(() => {
            setLoadingEstimate(false);
          }, 500);
        } else {
          setLoadingEstimate(false);
        }
      } catch (error: any) {
        const errorRes = error.response;

        setTimeout(() => {
          console.log(errorRes);
          if (errorRes && errorRes.data) {
            if (
              errorRes.data.errors &&
              errorRes.data.errors.length > 0 &&
              errorRes.data.errors[0].id === "MUST_EXECUTE_APPROVE_FIRST"
            ) {
              let navigateTo = formatText.changeUrlParams({
                url: window.location.hash,
                hashed: true,
                oldParameter: "type",
                newParameters: "type=approval",
              });

              navigate(navigateTo, { replace: false });
            } else if (
              errorRes.data.validationErrors &&
              errorRes.data.validationErrors.length > 0 &&
              errorRes.data.validationErrors[0].field === "to"
            ) {
              alert.open({ title: lang.ERROR_INVALID_WALLET, type: "warning" });
              setTimeout(() => {
                navigate(-1);
              }, 2000);
            } else {
              validator.verifyErrors(errorRes.data);
              navigate(-1);
              // setLoadingEstimate(false);
            }
          }
        }, 500);
      }
    };
    estimateGas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, to, coin]);
  useEffect(() => {
    const queryString = window.location.hash.split("?")[1];
    const urlParams = new URLSearchParams(queryString);
    const toUrl = urlParams.get("to");
    const amountUrl = urlParams.get("amount");
    const coinUrl = urlParams.get("coin") as sendCoinType;

    if (!toUrl) {
      setErrorMessage(lang.ADDRESS_MUST_BE_INFORMED);
      return;
    }
    if (!amountUrl) {
      setErrorMessage(lang.SHIPPING_VALUE_MUST_BE_INFORMED);
      return;
    }
    if (!coinUrl) {
      setErrorMessage(lang.SHIPPING_CURRENCY_MUST_BE_INFORMED);
      return;
    }
    setAmount(amountUrl);
    setTo(toUrl);
    setCoin(coinUrl);
  }, [lang]);
  const handleSubmit = async () => {
    try {
      if (coin) {
        setLoading(true);
        const res = await transactionService.sendTransaction({
          to,
          value: amount,
          coin,
        });
        setTimeout(async () => {
          await updateUserData();
          setLoading(false);
          alert.open({ title: res.message, type: "success" }, true);
          navigate("/", { replace: true });
        }, 1000);
      }
    } catch (error: any) {
      setTimeout(() => {
        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          validator.verifyErrors(errorRes.data);
        }
        setLoading(false);
      }, 1000);
    }
  };

  return loadingEstimate ? (
    <LoadingPage loadingLabel={lang.CHECKING_DATA} />
  ) : (
    <Main
      headerBody={{
        title: lang.CONFIRM_TRANSACTION,
        backPath: "/",
        type: "secondary",
      }}
    >
      <Container>
        {errorMessage && <ErrorMessage message={errorMessage} />}

        <InfoContent>
          {amount && coin && to && (
            <>
              <Header>
                <TitleHeader>{lang.SEND_TOKEN}</TitleHeader>
              </Header>
              <Body>
                <WalletContainer>
                  <WalletAddressContainer>
                    <WalletAddressTitle>{lang.FROM}</WalletAddressTitle>
                    <WalletAddressHash>
                      {formatText.truncateHash({
                        hash: user.walletAddress,
                        startLength: 5,
                        endLength: 5,
                      })}
                    </WalletAddressHash>
                  </WalletAddressContainer>
                  <Arrow src={arrowRightIcon} alt="Arrow Right" />
                  <WalletAddressContainer>
                    <WalletAddressTitle reverse>{lang.TO}</WalletAddressTitle>
                    <WalletAddressHash>
                      {formatText.truncateHash({
                        hash: to,
                        startLength: 5,
                        endLength: 5,
                      })}
                    </WalletAddressHash>
                  </WalletAddressContainer>
                </WalletContainer>
                <TransactionInfo>
                  <TitleTransactionInfo>{lang.DETAILS}</TitleTransactionInfo>
                  <RowTransactionInfo>
                    <TitleRowTransactionInfo>
                      {lang.COIN}
                    </TitleRowTransactionInfo>
                    <IconCoinContainer>
                      <IconCoin
                        src={coin && coins[dictionaryCoins[coin]].icon}
                        alt="bnb"
                      />
                      {coins[dictionaryCoins[coin]].symbol}
                    </IconCoinContainer>
                  </RowTransactionInfo>
                  <RowTransactionInfo>
                    <TitleRowTransactionInfo>
                      {lang.QUANTITY}
                    </TitleRowTransactionInfo>
                    <DescriptionRowTransactionInfo>
                      {fee ? (
                        `${
                          coin === "bnb"
                            ? `${formatText.formatNumber({
                                number: parseFloat(fee.finalValueToSend),
                              })} BNB`
                            : fee.finalValueToSend
                        }`
                      ) : (
                        <SpreadLoading reverse width="15px" />
                      )}
                    </DescriptionRowTransactionInfo>
                  </RowTransactionInfo>
                  <RowTransactionInfo>
                    <TitleRowTransactionInfo>
                      {lang.FEE}
                    </TitleRowTransactionInfo>
                    <DescriptionRowTransactionInfo>
                      {fee ? (
                        `${formatText.formatNumber({
                          number: parseFloat(fee.totalFeeInCrypto),
                        })} BNB`
                      ) : (
                        <SpreadLoading reverse width="15px" />
                      )}
                    </DescriptionRowTransactionInfo>
                  </RowTransactionInfo>
                  <RowTransactionInfo bold alignStart>
                    <TitleRowTransactionInfo>
                      {lang.TOTAL}
                    </TitleRowTransactionInfo>
                    <DescriptionRowTransactionInfo>
                      {fee ? (
                        `${
                          coin === "bnb"
                            ? `${formatText.formatNumber({
                                number: parseFloat(fee.finalValueToSend),
                              })} BNB`
                            : fee.finalValueToSend
                        } + ${formatText.formatNumber({
                          number: parseFloat(fee.totalFeeInCrypto),
                        })} BNB`
                      ) : (
                        <SpreadLoading reverse width="15px" />
                      )}
                    </DescriptionRowTransactionInfo>
                  </RowTransactionInfo>
                </TransactionInfo>
              </Body>
            </>
          )}
        </InfoContent>

        <ButtonsContainer>
          <ButtonDefault
            {...{
              width: errorMessage ? "320px" : "",
              title: lang.BACK,
              disabled: loading,
              action: () => navigate(-1),
            }}
          />

          {!errorMessage && (
            <ButtonGradient
              {...{
                title: lang.CONFIRM,
                disabled: loading,
                loading,
                onClick: handleSubmit,
              }}
            />
          )}
        </ButtonsContainer>
      </Container>
    </Main>
  );
};

export default SendToken;