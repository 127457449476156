const en = {
  LANGUAGE: "Lang",
  TRADE: "Trade",
  HELP: "Help",
  PROFILE: "Profile",
  COIN: "Coin",
  COPIED: "Copied!",
  COPY_FAILED: "Copy failed!",
  LOGOUT: "Logout",
  CHANGE_ACCOUNT: "Change account",
  ADD_LEGAL_ACCOUNT: "Add legal account",
  DEX_TAX: "DEX Fee",
  NETWORK_TAX: "Network fee",
  TO_PAY: "Pay",
  LIQUIDITY_TAX: "Liquidity tax",
  RECEIVE_ESTIMATED: "Receive (estimated)",
  COST: "Cost",
  FINAL_AMOUNT: "Final amount",
  AVAILABLE: "Available",
  BALANCE: "Balance",
  LIQUIDITY: "Liquidity",
  CONFIRM_ORDER: "Confirm order",
  TRANSACTION_SUBMITTED: "Transation Submitted",
  VIEW_IN_EXPLORER: "View in explorer",
  ADD_EXPO_TO_METAMASK: "Add Expo to MetaMask",
  SETTINGS: "Settings",
  CONNECT_WALLET: "Connect wallet",
  READ_AND_AGREE_WITH_THE: "I read and agree with the",
  WARNING: "Warning",
  CLOSE: "Close",
  RIGHT_RESERVED: "© 2021 NEX, All Rights Reserved.",
  VIEW_ON_BSCSCAN: "View on bscsan",
  PENDING_TRANSACTION: "Pending transaction",
  DENIED_TRANSACTION: "Denied transaction",
  TRANSACTION_COMPLETED: "Transaction completed",
  USER_DENIED_TRANSACTION: "User denied transaction",
  WAIT_A_FEW_SECONDS: "Wait a few seconds",
  FOR: "For",
  ADRESS: "Adress",
  ADRESS_LABEL: "Network: BSC BEP-20",
  CURRENCY: "Currency",
  CHANGE_PASSWORD: "Change Password",
  EXIT: "Exit",
  CURRENT_PASSWORD: "Current password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm new password",
  CONFIRM: "Confirm",
  CANCEL: "Cancel",
  FROM: "From",
  TO: "To",
  FEE: "Fee",
  NICKNAME: "Nickname",
  PASSWORD: "Password",
  EMAIL: "E-mail",
  CONFIRM_PASSWORD_S: "Confirm password",
  CHECKBOX: "I have read and agree with the disclaimer ",
  DISCLAIMER: "Disclaimer",
  HELLO: "Hello",
  SWAP_TOKEN: "Swap token",
  DETAILS: "Details",
  QUANTITY: "Quantity",
  TOTAL: "Total",
  ESTIMATED_TOTAL: "Estimated total",
  TOTAL_RECEIVE: "Total to receive",
  TOTAL_SPENT: "Total spent",
  BACK: "Back",
  ASSETS: "Assets",
  AMOUNT: "Amount",
  ESTIMATE_FEE: "Estimated Fee",
  RESET_PASSWORD: "Reset Password",
  LOGIN: "Login",
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  REGISTER: "Sign up",
  RESET_PASSWORD_MESSAGE:
    "We will send a password reset link to your registered email address.",
  RECOVER_PASSWORD: "Recover password",
  SEND_TOKEN: "Send Token",
  CONFIRM_ACCOUNT:
    "We sent a new confirmation link to your registered email! Look in your inbox and go to the link.",
  CONFIRMED_ACCOUNT:
    "Email confirmed successfully! Click here to make your first login!",
  CONFIRM_ACCOUNT_INVALID:
    "The confirmation code for this link is invalid or has already been used, make sure it was the correct link. If you have already activated the link, check if you are able to login.",
  CONFIRM_ACCOUNT_EXPIRED:
    "This confirmation code is expired, you can request a new code to be sent to your email.",
  CONFIRM_EMAIL: "Confirm Email",
  RESEND_EMAIL: "Resend Email",
  CONFIRM_EMAIL_SUCCESS: "E-mail verified with success!",
  SEND: "Send",
  SWAP: "Swap",
  ACTIVITIES: "Activities",
  SIGNUP: "Sign Up",
  CONFIRM_TRANSACTION: "Confirm Transaction",
  FORGOT_PASSWOD: "Forgot Password",
  DEPOSIT: "Deposit",
  ACCOUNT: "Account",
  NEXT: "Next",
  INVALID_TOKEN: "Invalid token",
  TRANSACTION_SUCCESS: "Transaction successfully completed!",
  TRANSACTION_FAIL: "Transaction failed!",
  EXPIRED_TRANSACTION_TOKEN:
    "Transaction has already been confirmed, or the transaction confirmation time has expired. Log in and check your history.",
  SERVER_DOWN: "Updating new features!",
  FILL_THE_INPUTS: "Fill in the fields to finalize",
  INVALID_PASSWORD_TOKEN:
    "The password reset code for this link is invalid, expired or has already been used, make sure it was the correct link. If you have already used the link, check if your password has already been changed by logging in.",
  REWARD: "Reward",
  TOKEN_TO_EARN_NOT_FOUND: "Earn token not found",
  REWARD_REDEMPTION: "Reward redemption",
  INVALID_CONFIRMATION_TYPE: "Invalid confirmation type",
  WITHDRAW: "Withdraw",
  TOKEN_REWARD: "Token Reward",
  REWARD_IN: "Reward in",
  SUCCESS: "Success!",
  LOADING: "Loading",
  CHECKING_DATA: "Checking data",
  ACTION: "Action",
  PERMISSION_TO: "Permission to",
  PERMISSION_TO_FUNDS: "Permission to access funds",
  PERMISSION: "Permission",
  ALLOW_PLATFORM_TO_USE: "Allow the Platform to use your",
  COMMUNITY: "Community",
  SHARE_LINK: "Share your link",
  COPY_ID: "Copy ID",
  SHOW_QR: "Show Qr-cde",
  COMMUNITY_INFO: "Get to know our community",
  COMMUNITY_DESCRIPTION: {
    items: [
      {
        title: "Rules",
        description: [
          "For every new user, the person who shared the link will receive a CAU bonus every time the new user receives staking CAU. You can freely invite users by sharing your link.",
          "Example:",
          "User A invites User B by sharing their link. User A now receives bonus CAU whenever B receives staking CAU.",
        ],
      },
      {
        title: "Bonus",
        description: [
          "2.5% Airdrop on CAU",
          "Start your community and get 2.5% CAU bonus.",
          "5% Airdrop on CAU",
          "When your community reaches a trading volume equivalent to 10000 CAUs in staking, you receive 5% CAU bonus.",
          "10% Airdrop on CAU",
          "When your community reaches a trading volume equivalent to 100000 CAUs in staking, you receive 10% CAU bonus.",
        ],
      },
    ],
    warning: "*Rules may change at any time",
  },
  SIGN_UP_CACAU: "Register at Cacau Digital",
  SIGN_UP_INDICATION: "Indication ID",
  SIGN_UP_INDICATION_MOBILE: "ID de indicação",
  INSUFICIENT_BALANCE: "Insuficient balance",
  MINIMUM_SWAP: "Minimum swap",
  MINIMUM_SEND: "Minimum send",
  ADDRESS_MUST_BE_INFORMED: "Shipping address must be informed",
  TOKEN_MUST_BE_INFORMED: "The token must be informed",
  SHIPPING_VALUE_MUST_BE_INFORMED: "The shipping value must be informed",
  SHIPPING_CURRENCY_MUST_BE_INFORMED: "The shipping currency must be informed",
  CURRENCY_MUST_BE_INFORMED: "The currency must be informed",
  CONVERSION_CURRENCY_MUST_BE_INFORMED:
    "The conversion currency must be informed",
  CURRENCY_TO_BE_CONVERTED_MUST_BE_INFORMED:
    "The currency to be converted must be informed",
  CONFIRM_APPROVAL:
    "By granting permission, you are allowing the platform to access your funds",
  UPDATE_ACCOUNT_PIX:
    "To use Pix it is necessary to fill your personal information and submit your documents. If you have already done this process, wait for the analysis of your documentation",
  UPDATE_INFO: "Click here to update your information.",
  DEPOSIT_WARNING: "I have read and agree with the warning",
  MIN_VALUE_IS: "The minimum value is",
  DECLARE_SAMPLE_ACCOUNT_CPF:
    "I declare that my Pix deposit originates from an account belonging to the CPF:  ",
  PIX_ONLY_FOR_BRAZIL:
    "Deposit via PIX is only available to Brazilians, therefore, a CPF number is required for this deposit option.",
  TERMS_PIX_CNPJ: "We do not yet accept deposits from joint accounts or CNPJ",
  TERMS_PIX_PRE: "We do not accept deposits from account prepaid.",
  TERMS_PIX_EXT: "We do not do automatic chargeback",
  PIX: "Pix",
  DEPOSIT_ACCOUNT_PIX_BELOW: "Deposit into the Pix account below",
  PIX_KEY: "Pix Key:",
  BENEFICIARY: "Beneficiary:",
  BANK: "Bank:",
  EMPLOYER_IDENTIFICATION_NUMBER: "CNPJ:",
  COUNTRY: "Country",
  DOCUMENT_NUMBER: "CPF Number",
  DOCUMENT_FRONT: "Front of Document",
  DOCUMENT_BACK: "Back of Document",
  DOCUMENT_SELF: "Selfie With Document",
  EXPIRED_TOKEN: `This confirmation code is expired`,
  INVALID_CPF: `Invalid CPF`,
  INVALID_DOCUMENT_NUMBER: `Invalid document number`,
  DOCUMENT_IMAGE_REQUIRED: `Document is required`,
  COUNTRY_REQUIRED: `Country is required`,
  OPERATION_EXECUTION_FAILED: "Failed while executing operation",
  INVALID_VOUCHER: "Invalid voucher, promotion not applied",
  INVALID_FILE_TYPE:
    "Please insert a valid image document (png, jpg, jpeg, tiff, webp, jfif).",
  INVALID_FILE_SIZE: "Insert a document that is less than 10MB in size.",
  DOCUMENT_SUBMITTED: "Document already submitted",
  ATTATCH: "Attach",
  YOUR_DOCUMENT: "document.png",
  SIGN_UP: "SignUp",
  WAITING_VERIFICATION: "Verification",
  DOCUMENTS_VERIFIED: "Documents Verified",
  EMAIL_OK: "email ok",
  RECEIVED: "received",
  NOT_APPROVED: "not approved",
  UNDER_ANALYSIS: "under analysis",
  APPROVED: "approved",
  VERIFIED: "verified",
  ACCOUNT_DATA: "Account data",
  PERSONAL_DATA: "Personal data",
  DOCUMENTS: "Documents",
  FIRST_NAME: "First name",
  FULL_NAME: "Full name",
  BIRTH_DATE: "Date of birth",
  PHONE: "Phone",
  UPDATE: "Update",
  TEXT_PHOTO_DOCUMENT:
    "For a better analysis, the photo must have the information visible. The document number must be the same as the document photo.  Each image must contain a maximum of 10MB",
  TEXT_ANALYSIS_DOCUMENT:
    "Your documentation is subject to approval by our compliance team within 48 hours.",
  SAVE: "Save",
  DOCUMENT_ANALYSIS:
    "Your documents have been received and are being analyzed by our team, please wait.",
  DOCUMENT_NOT_ACCEPTED: "Documents not accepted, click here to try again.",
  SHOW_DETAILS: "Show details",
  DOCUMENTATION_REFUSED: "Documentation refused",
  ERRORS_FOUND: "Errors found",
  ERROR_INVALID_WALLET: "Invalid Wallet",
};

export default en;