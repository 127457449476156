import fullLogo from "assets/full_logo.svg";
import LangContext from "contexts/Lang";
import Guest from "pages/templates/Guest";
import React, { useContext } from "react";
import { TailSpin } from "react-loader-spinner";

import { Container, ImgLogo, LoadingText } from "./styles";

interface ILoadingPage {
  loadingLabel?: string;
}
const LoadingPageContent: React.FC<ILoadingPage> = ({ loadingLabel }) => {
  return (
    <Container>
      <ImgLogo src={fullLogo} alt=" Logo" />

      <br />
      <br />
      <br />
      <br />
      <LoadingText>{loadingLabel}</LoadingText>
      <br />
      <br />
      <TailSpin height="100" width="100" color="white" ariaLabel="" />
    </Container>
  );
};

const LoadingPage: React.FC<ILoadingPage> = ({ loadingLabel }) => {
  const { lang } = useContext(LangContext);

  return (
    <Guest>
      <LoadingPageContent loadingLabel={loadingLabel || lang.LOADING} />
    </Guest>
  );
};

export default LoadingPage;
