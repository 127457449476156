import styled, { css } from "styled-components";

export const Container = styled.div`
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
  justify-content: space-between;
`;
export const MessageContainer = styled.div`
  min-height: 380px;
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
`;
export const Message = styled.span`
  text-align: left;
  width: 260px;

  ${(props) =>
    props.theme.responsive.sm(css`
      width: 320px;
    `)}
  ${(props) => props.theme.fonts.roboto};
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryFont};
`;
export const ButtonsContainer = styled.div<{ tokenExpired?: boolean }>(
  (props) => css`
    margin-top: 40px;
    width: 100%;
    ${props.theme.aligns.verticalAndHorizontal};
  `
);
