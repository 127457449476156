import React, { useContext } from "react";
import logoutIcon from "assets/navbar/logout.svg";
import logoutLightIcon from "assets/navbar/logout_light.svg";
import {
  Body,
  Container,
  LogoutContainer,
  Header,
  LinksContainer,
  NavLink,
  LogoutText,
  LogoutIcon,
  LogoutHoverIcon,
} from "./styles";
import AuthContext from "contexts/Auth";
interface IAdmin {
  children: any;
}
const Admin: React.FC<IAdmin> = ({ children }) => {
  const { logout } = useContext(AuthContext);
  return (
    <Container>
      <Header>
        <LinksContainer>
          <NavLink to="/">Usuários</NavLink>
        </LinksContainer>
        <LogoutContainer onClick={() => logout()}>
          <LogoutText>Sair</LogoutText>
          <LogoutIcon src={logoutIcon} alt="Logout" />
          <LogoutHoverIcon src={logoutLightIcon} alt="Logout" />
        </LogoutContainer>
      </Header>
      <Body>{children}</Body>
    </Container>
  );
};

export default Admin;
