import blockIcon from "assets/block_white.svg";
import swapIcon from "assets/swap_icon.svg";
import ButtonDefault from "components/buttons/ButtonDefault";
import ButtonGradient from "components/buttons/ButtonGradient";
import InputNumberWithSelect from "components/forms/InputNumberWithSelect";
import ErrorMessage from "components/texts/ErrorMessage";
import {
  coins,
  dictionaryCoins,
  swapCoinKeys,
  swapCoinPairKey,
} from "constants/coins";
import LangContext from "contexts/Lang";
import PricesContext from "contexts/Prices";
import TaxesAndLimitsContext from "contexts/TaxAndLimits";
import UserContext from "contexts/User";
import handleAccountTokenIsApproved from "handlers/handleAccountTokenIsApproved";
import formatText from "lib/formatText";
import handleTransactions from "lib/handleTransactions";
import helpers from "lib/helpers";
import math from "lib/math";
import Main from "pages/templates/Main";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AllowButtonsContainer,
  AllowPlatformTokenContainer,
  ButtonsContainer,
  Container,
  Form,
  ImgBlock,
  InfoPricesContainer,
  InfoPricesLeftContent,
  InfoPricesLeftText,
  InfoPricesRightContent,
  InfoPricesRightText,
  SwapIcon,
  SwapIconContainer,
} from "./styles";

const SwapContent: React.FC = () => {
  const { balance } = useContext(UserContext);
  const { mainTokenPrice, bnbTokenPrice } = useContext(PricesContext);
  const { taxesAndLimits } = useContext(TaxesAndLimitsContext);
  const [lastInputChange, setLastInputChange] = useState(1);
  const [loadingAllowToken, setLoadingAllowToken] = useState(true);
  const [allowToken, setAllowToken] = useState(true);
  const [fee, setFee] = useState("");
  const { lang } = useContext(LangContext);
  const [from, setFrom] = useState<string>("");
  const [fromOption, setFromOption] = useState<any>(swapCoinKeys[0]);
  const [fromErrorMessage, setFromErrorMessage] = useState("");
  const [to, setTo] = useState<string>("");
  const [toOption, setToOption] = useState<any>(
    swapCoinPairKey[swapCoinKeys[0]][0]
  );

  const navigate = useNavigate();
  const handleSetFromOption = (key: string) => {
    setFromOption(key);
    setToOption(swapCoinPairKey[key][0]);
  };
  useEffect(() => {
    setFrom("");
    setTo("");
  }, [fromOption, toOption]);

  useEffect(() => {
    if (math.isGreaterThan(from, "0")) {
      // Verificações de balance
      const validationMessage = handleTransactions.validSwap({
        amount: from,
        balance: balance.wallet[fromOption].available,
        minimum:
          taxesAndLimits.limits.swap[`${fromOption}${toOption}`][fromOption],
        gas: taxesAndLimits.minGasCryptoBalances.swap,
      });
      setFromErrorMessage(validationMessage);
      // console.log(taxesAndLimits);

      setFee(
        `${formatText.formatNumber({
          number: handleTransactions.calculateFees({
            amount:
              fromOption === "bnb"
                ? from
                : handleTransactions.convertCoin({
                    fromAmount: from,
                    to: "bnb",
                    pricesInMainToken:
                      fromOption === "bnb" ? bnbTokenPrice : mainTokenPrice,
                  }),
            fee: taxesAndLimits.taxes.swap.adm,
            gas: taxesAndLimits.minGasCryptoBalances.swap,
          }),
        })} BNB`
      );
    } else {
      setFee("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, balance, taxesAndLimits, fromOption, toOption, mainTokenPrice]);
  // Atualizar preço dos inputs de acordo com a atualização do mainTokenPrice
  useEffect(() => {
    if (lastInputChange === 1) {
      handleFromChange(from);
    } else {
      handleToChange(to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainTokenPrice]);

  useEffect(() => {
    const checkIfAccountTokenIsApproved = async () => {
      try {
        setLoadingAllowToken(true);
        const res = await handleAccountTokenIsApproved({ coin: fromOption });
        if (res === "unexecuted") {
          setAllowToken(true);
        } else {
          setAllowToken(false);
        }
      } catch (error) {
        // console.log(error);
      }
      setLoadingAllowToken(false);
    };
    if (fromOption !== "bnb" && fromOption !== "brl")
      checkIfAccountTokenIsApproved();
    else {
      setAllowToken(false);
      setLoadingAllowToken(false);
    }
  }, [fromOption]);

  const handleFromChange = (val: string = "0") => {
    if (!val) {
      setFrom("");
      setTo("");
      return;
    }
    setFrom(val);
    setLastInputChange(1);
    if (mainTokenPrice) {
      console.log(val, mainTokenPrice.brl);

      setTo(
        formatText.formatNumber({
          number:
            fromOption === "bnb"
              ? math.div(
                  math.sub(val, taxesAndLimits.minGasCryptoBalances.swap) ||
                    "0",
                  mainTokenPrice.bnb
                ) || "0"
              : fromOption === "brl"
              ? math.div(val, mainTokenPrice.brl) || "0"
              : math.mul(val, mainTokenPrice.bnb),
        })
      );
    }
  };
  const handleToChange = (val: string = "0") => {
    if (!val) {
      setFrom("");
      setTo("");
      return;
    }
    setTo(val);
    setLastInputChange(2);
    if (mainTokenPrice) {
      setFrom(
        formatText.formatNumber({
          number:
            fromOption === "bnb"
              ? math.sum(
                  math.mul(val, mainTokenPrice.bnb),
                  taxesAndLimits.minGasCryptoBalances.swap
                )
              : fromOption === "brl"
              ? math.mul(val, mainTokenPrice.brl)
              : math.div(val, mainTokenPrice.bnb) || "0",
        })
      );
    }
  };
  return (
    <Container>
      <Form>
        <InputNumberWithSelect
          {...{
            title: lang.FROM,
            value: from,
            setValue: handleFromChange,
            option: fromOption,
            setOption: handleSetFromOption,
            containerStyle: { margin: "10px 0" },
            options: swapCoinKeys,
            label: `Balance: ${formatText.formatNumber({
              number: balance.wallet[fromOption].available,
              fixed: coins[dictionaryCoins[fromOption]].fixed,
            })} ${coins[dictionaryCoins[fromOption]].symbol}`,
          }}
        />
        <ErrorMessage message={fromErrorMessage} />
        <br />
        <SwapIconContainer>
          <SwapIcon src={swapIcon} alt="Swap" />
        </SwapIconContainer>
        <InputNumberWithSelect
          {...{
            title: lang.TO,
            value: to,
            setValue: handleToChange,
            option: toOption,
            setOption: setToOption,
            containerStyle: { margin: "10px 0" },
            options: swapCoinPairKey[fromOption],
            label: `Balance: ${formatText.formatNumber({
              number: balance.wallet[toOption].available,
              fixed: coins[dictionaryCoins[toOption]].fixed,
            })} ${coins[dictionaryCoins[toOption]].symbol}`,
          }}
          id={"from-input"}
        />
        {fee && (
          <>
            <InfoPricesContainer>
              <InfoPricesLeftContent>
                <InfoPricesLeftText>{`1 ${
                  coins[dictionaryCoins[fromOption]].symbol
                }`}</InfoPricesLeftText>
              </InfoPricesLeftContent>
              <InfoPricesRightContent>
                <InfoPricesRightText>{`${formatText.formatNumber({
                  number: helpers.convertCoin({
                    amount: 1,
                    multiplier:
                      fromOption === "bnb"
                        ? math.div(1, mainTokenPrice.bnb) || "0"
                        : fromOption === "brl"
                        ? math.div(1, mainTokenPrice.brl) || "0"
                        : math.mul(1, mainTokenPrice.bnb),
                  }),
                })} ${
                  coins[dictionaryCoins[toOption]].symbol
                }`}</InfoPricesRightText>
              </InfoPricesRightContent>
            </InfoPricesContainer>
            <InfoPricesContainer>
              <InfoPricesLeftContent>
                <InfoPricesLeftText>{`1 ${
                  coins[dictionaryCoins[toOption]].symbol
                }`}</InfoPricesLeftText>
              </InfoPricesLeftContent>
              <InfoPricesRightContent>
                <InfoPricesRightText>{`${formatText.formatNumber({
                  number: helpers.convertCoin({
                    amount: 1,
                    multiplier:
                      fromOption === "bnb"
                        ? math.mul(1, mainTokenPrice.bnb)
                        : fromOption === "brl"
                        ? math.mul(1, mainTokenPrice.brl)
                        : math.div(1, mainTokenPrice.bnb) || "0",
                  }),
                })} ${
                  coins[dictionaryCoins[fromOption]].symbol
                }`}</InfoPricesRightText>
              </InfoPricesRightContent>
            </InfoPricesContainer>
            <InfoPricesContainer>
              <InfoPricesLeftContent>
                <InfoPricesLeftText>{`${lang.FEE}`}</InfoPricesLeftText>
              </InfoPricesLeftContent>
              <InfoPricesRightContent>
                <InfoPricesRightText>{fee}</InfoPricesRightText>
              </InfoPricesRightContent>
            </InfoPricesContainer>
          </>
        )}
      </Form>
      {allowToken ? (
        <AllowButtonsContainer>
          <ButtonGradient
            {...{
              loading: loadingAllowToken,
              width: "320px",
              disabled: loadingAllowToken,
              title: (
                <AllowPlatformTokenContainer>
                  <ImgBlock src={blockIcon} alt="block" />
                  {`${lang.ALLOW_PLATFORM_TO_USE} ${
                    coins[dictionaryCoins[fromOption]].symbol
                  }`}
                </AllowPlatformTokenContainer>
              ),
              onClick: () =>
                navigate(
                  `/confirm-transaction?type=approval&coin=${fromOption}`,
                  {
                    replace: false,
                  }
                ),
            }}
          />
        </AllowButtonsContainer>
      ) : (
        <ButtonsContainer>
          <ButtonDefault
            title={lang.CANCEL}
            action={() => navigate("/", { replace: true })}
          />
          <ButtonGradient
            {...{
              loading: loadingAllowToken,
              disabled:
                !math.isGreaterThan(from, 0) || Boolean(fromErrorMessage),
              title: lang.NEXT,
              onClick: () =>
                navigate(
                  `/confirm-transaction?type=swap&amount=${from}&coinFrom=${fromOption}&coinTo=${toOption}`,
                  { replace: false }
                ),
            }}
          />
        </ButtonsContainer>
      )}
    </Container>
  );
};
const Swap: React.FC = () => {
  const { lang } = useContext(LangContext);
  return (
    <Main headerBody={{ title: lang.SWAP, backPath: "/" }}>
      <SwapContent />
    </Main>
  );
};

export default Swap;