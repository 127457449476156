import styled, { css } from "styled-components";

export const Container = styled.div`
  background-color: #333434;
  min-height: 50vh;
  padding: 15px 15px;
  box-sizing: border-box;
  border-radius: 15px;
`;
export const Header = styled.div(
  (props) => css`
    ${props.theme.aligns.vertical};
    justify-content: space-between;
    ${props.theme.fonts.montserrat};
    color: #979898;
    box-sizing: border-box;
    padding: 10px 15px;
  `
);
export const LeftHeader = styled.div``;
export const CenterHeader = styled.div(
  (props) => css`
    ${props.theme.aligns.vertical}
    overflow-y: auto;
  `
);
export const RightHeader = styled.div``;
interface IOptionFilter {
  active?: boolean;
}
export const OptionFilter = styled.div<IOptionFilter>(
  (props) => css`
    ${props.active &&
    css`
      background-color: #2c2e2f;
    `}
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 15px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      color: white;
    }
  `
);

export const Body = styled.div`
  min-height: 40vh;
  border: 1px solid #2c2e2f;
  border-radius: 25px;
  overflow-y: auto;
`;
export const Table = styled.table(
  (props) => css`
    position: relative;
    padding: 20px 10px 40px 10px;
    width: 100%;

    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border-collapse: separate;
  `
);
export const Tr = styled.tr(
  (props) => css`
    border-radius: 30px;
    color: #979898;
    ${props.theme.fonts.montserrat};
    text-align: left;
    height: auto;
    &:nth-child(even) {
      background-color: #2c2e2f44;
    }
    &:hover {
      background-color: #2c2e2fee;
    }
  `
);
export const Th = styled.th(
  (props) => css`
    background-color: #333434;
    border-collapse: separate;
    height: 30px;
    font-weight: 700;
    border-radius: 25px;
    font-size: 18px;
    padding: 8px;
  `
);
interface ITd {
  active?: boolean;
}
export const Td = styled.td<ITd>(
  (props) => css`
    vertical-align: middle;
    ${props.active &&
    css`
      cursor: pointer;
    `}
    height: 30px;
    padding: 8px;
  `
);
export const TailSpinContainer = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;

  left: 0;
  right: 0;
`;
export const SelectNumRowsContainer = styled.div(
  (props) => css`
    padding: 10px;
    width: 50px;
    background-color: #2c2e2f;
    border-radius: 10px;
    box-sizing: border-box;
    ${props.theme.aligns.vertical};
    cursor: pointer;
    position: relative;
  `
);
export const ArrowDown = styled.img`
  padding: 15px 0 0 0;
  transform: rotate(-90deg);
`;
export const ListOptionsContainer = styled.div`
  box-sizing: border-box;
  position: absolute;

  padding: 10px;
  border-radius: 10px;
  width: 50px;
  bottom: -125px;
  left: 0;
  z-index: 1;
  background-color: #2c2e2f;
`;
export const SelectNumRowsTitle = styled.span`
  &:hover {
    color: white;
  }
`;
export const ListOptionsItem = styled.div(
  (props) => css`
    ${props.theme.aligns.horizontal};
    padding: 5px;
    box-sizing: border-box;
    border-radius: 10px;

    &:hover {
      background-color: white;
      color: #2c2e2f;
    }
  `
);