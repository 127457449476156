import styled from "styled-components";

export const Container = styled.div``;
export const UserInfoContainer = styled.div`
  ${(props) => props.theme.aligns.vertical};
  margin-bottom: 30px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
`;
export const InfosContainer = styled.div`
  ${(props) => props.theme.aligns.horizontal};
  flex-flow: column;
`;
export const UserIcon = styled.img`
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 15px;
  /* background-color: ${(props) => props.theme.colors.whiteLighter}; */
`;
export const TextEmail = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 700;
  color: ${(props) => props.theme.colors.primaryFont};
  font-size: 14px;
  line-height: 20px;
`;
export const TextHash = styled.span`
  ${(props) => props.theme.fonts.inter};
  font-size: 13px;
  color: ${(props) => props.theme.colors.primaryFont};
  overflow: hidden;
  width: 80%;
`;

export const CopyHash = styled.div`
  ${(props) => props.theme.fonts.poppins};
  ${(props) => props.theme.aligns.horizontal};
  color: ${(props) => props.theme.colors.primaryFont};
  border: 1px solid ${(props) => props.theme.colors.secundary};
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 5px;
  transition: background 0.5 ease-in-out;
  &:hover {
    background: ${(props) => props.theme.colors.secundary};
  }
`;

export const CopyIcon = styled.img`
  padding: 10px;
  cursor: pointer;
  /* background-color: ${(props) => props.theme.colors.whiteLighter}; */
`;

export const CopyText = styled.span`
  ${(props) => props.theme.fonts.inter};
  font-size: 10px;

  overflow: hidden;
  color: ${(props) => props.theme.colors.primaryFont};
`;

export const QrCodeContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 1em 0;
`;
