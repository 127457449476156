import styled, { css } from "styled-components";

export const Container = styled.div`
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
  justify-content: space-between;
`;
export const InfoContent = styled.div`
  min-height: 380px;
  position: relative;
  width: 100%;
`;
export const ButtonsContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  justify-content: space-between;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 7px;
`;
export const Body = styled.div`
  margin-top: 20px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.tertiary};
  min-height: 300px;
  width: 100%;
  padding: 20px 25px;
  border-radius: 10px;
`;

export const LeftHeader = styled.div`
  ${(props) => props.theme.fonts.roboto};
  display: flex;
  flex-flow: column;
`;
export const TitleHeader = styled.span`
  font-size: 14px;
  ${(props) => props.theme.fonts.roboto};
  color: ${(props) => props.theme.colors.primaryFont};
  font-weight: 700;
  line-height: 20px;
`;
export const SubtitleHeader = styled.span`
  font-size: 10px;
  color: ${(props) => props.theme.colors.neutralDark};
`;
export const RightHeader = styled.div`
  ${(props) => props.theme.aligns.vertical};
`;
export const HashText = styled.span`
  font-size: 10px;
  color: ${(props) => props.theme.colors.primaryFont};
  line-height: 20px;
  cursor: pointer;
  ${(props) => props.theme.fonts.inter};
`;
export const CopyIcon = styled.img`
  padding: 0 10px;
  width: 10px;
  cursor: pointer;
`;
export const ShareIcon = styled.img`
  width: 10px;
  cursor: pointer;
`;
export const CoinsContainer = styled.div`
  justify-content: space-around;
  ${(props) => props.theme.aligns.vertical};
`;
export const LeftCoin = styled.img`
  width: 35px;
  background-color: #fff;
  border-radius: 30px;
  padding: 8px;
`;
export const Arrow = styled.img`
  width: 25px;
`;
export const RightCoin = styled.img`
  width: 35px;
  background-color: #fff;
  border-radius: 30px;
  padding: 8px;
`;
export const TransactionInfo = styled.div`
  ${(props) => props.theme.fonts.roboto};
  color: ${(props) => props.theme.colors.primary};
  margin-top: 20px;
`;
export const TitleTransactionInfo = styled.div`
  font-weight: 700;
  margin-bottom: 30px;
`;
interface IRowTransactionInfo {
  bold?: boolean;
  alignStart?: boolean;
}
export const RowTransactionInfo = styled.div<IRowTransactionInfo>`
  ${(props) =>
    props.alignStart
      ? css`
          display: flex;
        `
      : props.theme.aligns.vertical};
  font-size: 14px;
  justify-content: space-between;
  margin: 20px 0;
  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}
`;
export const TitleRowTransactionInfo = styled.div`
  display: flex;
  align-items: flex-start;
`;
export const DescriptionRowTransactionInfo = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;
export const Divider = styled.div`
  height: 3px;
  border-radius: 5px;
  width: 100%;
  background-color: #f1dbcc;
`;
