import { handleApi } from "./server";

const authService = {
  login: async (data: { email: string; password: string }) => {
    return await handleApi("/user/login", "post", { data });
  },
  checkLogin: async () => {
    return await handleApi("/user/checkLogin", "get", {});
  },
  signup: async (data: {
    name: string;
    email: string;
    password: string;
    passwordConfirm: string;
    checkTerms: "true" | "false";
  }) => {
    return await handleApi("/user/create", "post", { data });
  },
  forgotPassword: async (data: { email: string }) => {
    return await handleApi("/user/reset-password", "post", { data });
  },
  validateTokenResetPassword: async (data: { token: string }) => {
    return await handleApi(
      "/user/reset-password?token=" + data.token,
      "get",
      {}
    );
  },
  resetPassword: async (data: {
    token: string;
    password: string;
    password_confirmation: string;
  }) => {
    return await handleApi("/user/reset-password", "put", { data });
  },
};
export default authService;
