import { isDisabled } from "@testing-library/user-event/dist/utils";
import React, { useEffect, useState } from "react";

import { BodyTabs, Container, HeaderTabs, Tab, TabHeader } from "./styles";
export interface ITabInfo {
  [key: string]: {
    title: string;
    content: any;
    disabled?: boolean;
  };
}
interface ITabs {
  tabInfo: ITabInfo;
  tabKeyDefault: string;
}
const Tabs: React.FC<ITabs> = ({ tabInfo, tabKeyDefault }) => {
  const [showTab, setShowTab] = useState(tabKeyDefault);
  return (
    <Container>
      <HeaderTabs>
        {Object.keys(tabInfo).map((value: string, idx) => (
          <TabHeader
            key={idx}
            active={showTab === value}
            onClick={() => {
              if (!tabInfo[value].disabled) setShowTab(value);
            }}
          >
            {tabInfo[value].title}
          </TabHeader>
        ))}
      </HeaderTabs>
      <BodyTabs>
        {Object.keys(tabInfo).map((value, idx) => (
          <Tab active={showTab === value} key={idx}>
            {tabInfo[value].content}
          </Tab>
        ))}
      </BodyTabs>
    </Container>
  );
};

export default Tabs;
