import { language } from "./Language";
import math from "./math";

const handleTransactions = {
  validSend: (data: {
    amount: string | number;
    balance: string | number;
    minimum: string;
  }): string => {
    const { amount, balance, minimum } = data;
    if (math.isGreaterThan(amount, balance)) {
      return language.lang.INSUFICIENT_BALANCE;
    } else if (
      math.isGreaterThan(parseFloat(minimum), amount) &&
      math.isGreaterThan(amount, 0)
    ) {
      return `${language.lang.MINIMUM_SEND} ${minimum}`;
    } else {
      return "";
    }
  },
  validSwap: (data: {
    amount: string | number;
    balance: string | number;
    minimum: string;
    gas: string;
  }): string => {
    const { amount, balance, minimum, gas } = data;
    if (math.isGreaterThan(amount, balance)) {
      return language.lang.INSUFICIENT_BALANCE;
    } else if (
      math.isGreaterThan(parseFloat(minimum), amount) &&
      math.isGreaterThan(amount, 0)
    ) {
      return `${language.lang.MINIMUM_SWAP} ${minimum}`;
    } else {
      return "";
    }
  },
  convertCoin: (data: {
    fromAmount: string | number;
    to: string;
    pricesInMainToken: any;
  }) => {
    const { fromAmount, to, pricesInMainToken } = data;
    return math.mul(fromAmount, pricesInMainToken[to]);
  },
  admFee: (data: { amount: string | number; fee: string | number }) => {
    const { amount, fee } = data;

    // Taxa de adm
    const admFee = math.mul(fee, amount);
    return admFee;
  },
  calculateFees: (data: {
    amount: string | number;
    fee: string | number;
    gas: string;
  }) => {
    const { amount, fee, gas } = data;
    // Taxa de adm
    const admFee = handleTransactions.admFee({
      amount,
      fee,
    });
    // Taxa de rede
    const gasPrice = parseFloat(gas);
    // Soma taxas
    return math.sum(admFee, gasPrice);
  },

  calculateAmountWithoutFees: (data: {
    amount: string | number;
    fee: string | number;
    gas: string;
  }): string => {
    const { amount, fee, gas } = data;

    const fees = handleTransactions.calculateFees({
      fee,
      amount,
      gas,
    });
    const amountWithouFees = math.sub(amount || "0", fees);

    return amountWithouFees || "0";
  },
};
export default handleTransactions;