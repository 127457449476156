import styled, { css } from "styled-components";

export const Container = styled.div``;
export const ListAssetsContainer = styled.div`
  height: 200px;
  overflow: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
export const AssetContainer = styled.div`
  background-color: ${(props) => props.theme.colors.tertiary};
  height: 60px;
  width: 100%;
  border-radius: 8px;
  position: relative;
  margin: 10px 0;
  ${(props) => props.theme.aligns.vertical};
`;
export const LeftContent = styled.div`
  padding: 0 10px;
`;
export const RightContent = styled.div`
  ${(props) => props.theme.aligns.horizontal};
  flex-flow: column;
  ${(props) => props.theme.fonts.poppins};
  color: ${(props) => props.theme.colors.secundaryFont};
`;
export const RewardButton = styled.span(
  (props) => css`
    right: 25px;
    bottom: 11px;
    position: absolute;
    color: ${(props) => props.theme.colors.secundary};
    ${props.theme.fonts.barlow};
    cursor: pointer;
    font-weight: 600;
    &:hover {
      color: ${props.theme.colors.secundary}dd;
    }
  `
);
export const Title = styled.span`
  font-weight: 700;
  color: ${(props) => props.theme.colors.primarySupport};
  font-size: 18px;
`;
export const Subtitle = styled.span`
  color: ${(props) => props.theme.colors.primarySupport};
  font-size: 12px;
  margin-top: 2px;
`;
export const Icon = styled.img`
  width: 35px;
  border-radius: 20px;
`;
export const IconContent = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;
