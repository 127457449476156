import { NavLink as NavLinkDom } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  background-color: #2c2e2f;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px;
  height: 100%;
`;

export const Header = styled.div(
  (props) => css`
    padding: 30px;
    ${props.theme.aligns.vertical}
    ${props.theme.fonts.montserrat};
    justify-content: space-between;
  `
);
export const Body = styled.div``;
export const NavLink = styled(NavLinkDom)`
  text-decoration: none;
  font-size: 18px;
  color: #d6a84cee;
  &:hover {
    color: #d6a84c;
  }
  font-weight: 700;
`;
export const LinksContainer = styled.div``;

export const LogoutIcon = styled.img``;
export const LogoutHoverIcon = styled.img`
  display: none;
`;

export const LogoutContainer = styled.div(
  (props) => css`
    cursor: pointer;
    font-size: 18px;
    ${props.theme.aligns.verticalAndHorizontal}
    color: #979898;
    font-weight: 700;
    &:hover {
      color: #ffffff;
      & ${LogoutHoverIcon} {
        display: block;
      }
      & ${LogoutIcon} {
        display: none;
      }
    }
  `
);
export const LogoutText = styled.span`
  padding: 0 10px;
`;
