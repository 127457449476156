import logoImg from "assets/logo_icon.svg";
import arrowLeft from "assets/navbar/arrow_left.svg";
import MainHeader from "components/headers/MainHeader";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Body,
  CardBody,
  Container,
  ContentCard,
  HeaderBody,
  IconHeaderBody,
  TitleHeaderBody,
} from "../styles";
import {
  CacauDigitalWalletContainer,
  CacauDigitalWalletLogo,
  CacauDigitalWalletText,
  SecondaryHeaderBody,
  TitleSecondaryHeaderBody,
} from "./styles";
interface IMain {
  headerBody?: { title: string; backPath: string | number; type?: string };
  children: any;
}
const Main: React.FC<IMain> = ({ children, headerBody }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <MainHeader />
      <Body>
        <ContentCard>
          {headerBody && (
            <>
              {!headerBody.type ? (
                <HeaderBody>
                  <IconHeaderBody
                    src={arrowLeft}
                    alt="back"
                    onClick={() => {
                      if (typeof headerBody.backPath === "number") {
                        navigate(headerBody.backPath);
                      } else {
                        navigate(headerBody.backPath, { replace: true });
                      }
                    }}
                  />
                  <TitleHeaderBody>{headerBody.title}</TitleHeaderBody>
                </HeaderBody>
              ) : headerBody.type === "secondary" ? (
                <SecondaryHeaderBody>
                  <TitleSecondaryHeaderBody>
                    {headerBody.title}
                  </TitleSecondaryHeaderBody>
                  <CacauDigitalWalletContainer>
                    <CacauDigitalWalletLogo src={logoImg} alt="cacau digital" />
                    <CacauDigitalWalletText>
                      Smart Business Wallet
                    </CacauDigitalWalletText>
                  </CacauDigitalWalletContainer>
                </SecondaryHeaderBody>
              ) : null}
            </>
          )}
          <CardBody>{children}</CardBody>
        </ContentCard>
      </Body>
    </Container>
  );
};

export default Main;
