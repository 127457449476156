import styled, { css } from "styled-components";

export const Container = styled.div``;
export const UserInfoContainer = styled.div`
  width: 100%;

  ${(props) => props.theme.aligns.vertical};
  margin-bottom: 30px;
  box-sizing: border-box;
`;
export const InfosContainer = styled.div`
  ${(props) => props.theme.aligns.horizontal};
  flex-flow: column;
`;
export const UserIcon = styled.img`
  padding: 10px;
  border-radius: 5px;
  margin-right: 15px;
  background-color: ${(props) => props.theme.colors.whiteLighter};
`;
export const TextEmail = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 700;
  color: ${(props) => props.theme.colors.primaryFont};
  font-size: 14px;
  line-height: 20px;
`;
export const TextHash = styled.span`
  ${(props) => props.theme.fonts.inter};
  font-size: 13px;
  color: ${(props) => props.theme.colors.secundarySupport};
`;
export const Form = styled.form`
  width: 100%;
  min-height: 380px;
  ${(props) =>
    props.theme.responsive.sm(css`
      ${(props) => props.theme.aligns.vertical};
      flex-flow: column;
    `)}
`;
export const ButtonsContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  justify-content: space-between;
`;
