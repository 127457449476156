import React from "react";

import { Container, Img } from "./styles";
import checkImg from "assets/check.svg";
interface ICheckbox {
  value: boolean;
  setValue: (value: boolean) => void;
}
const Checkbox: React.FC<ICheckbox> = ({ value, setValue }) => {
  return (
    <Container active={value} onClick={() => setValue(!value)}>
      <Img src={checkImg} alt="check" />
    </Container>
  );
};

export default Checkbox;
