import BigNumber from "bignumber.js";
const { ROUND_DOWN, ROUND_UP } = BigNumber;

const math = {
  BN: (value: string | number) => {
    return new BigNumber(value);
  },

  fixit: (a: string | number, fixed = 18, roundDown = false) => {
    const _a = math.BN(a);
    return _a.toFixed(fixed, roundDown ? ROUND_DOWN : ROUND_UP);
  },
  sum: (a: string | number, b: string | number) => {
    const _a = math.BN(a);
    const _b = math.BN(b);

    return BigNumber.sum(_a, _b).toFixed();
  },
  sub: (a: string | number, b: string | number) => {
    const _a = math.BN(a);
    const _b = math.BN(b);
    if (_b.isLessThanOrEqualTo(_a)) {
      return _a.minus(_b).toFixed();
    } else {
      return null;
    }
  },

  mul: (a: string | number, b: string | number) => {
    const _a = math.BN(a);
    const _b = math.BN(b);
    return _a.multipliedBy(_b).toFixed();
  },

  div: (a: string | number, b: string | number) => {
    const _a = math.BN(a);
    const _b = math.BN(b);
    if (_b.isGreaterThan(0)) {
      return _a.div(_b).toFixed();
    } else {
      return null;
    }
  },

  isGreaterThan: (a: string | number, b: string | number) => {
    const _a = math.BN(a);
    const _b = math.BN(b);
    return _a.isGreaterThan(_b);
  },

  isLessThan: (a: string | number, b: string | number) => {
    const _a = math.BN(a);
    const _b = math.BN(b);
    return _a.isLessThan(_b);
  },

  isLessThanOrEqualTo: (a: string | number, b: string | number) => {
    const _a = math.BN(a);
    const _b = math.BN(b);
    return _a.isLessThanOrEqualTo(_b);
  },
};

export default math;
