import AlertContext from "contexts/Alert";
import LangContext from "contexts/Lang";
import { alert, INotification } from "lib/Alert";
import React, { useContext, useEffect, useState } from "react";
import {
  AlertContainer,
  CloseIcon,
  Container,
  LeftContent,
  LinkBsc,
  RightContent,
  Subtitle,
  Title,
} from "./styles";
const AlertItem: React.FC<INotification> = ({
  type,
  title,
  subtitle,
  scanLink,
  duration,
}) => {
  const [show, setShow] = useState(false);
  const { lang } = useContext(LangContext);
  // * Poe temporizador no alert
  useEffect(() => {
    let interval: any;
    if (show && type !== "info" && duration)
      interval = setTimeout(() => {
        setShow(false);
      }, duration);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 200);
  }, []);
  return (
    <AlertContainer show={show} type={type}>
      <LeftContent type={type} />
      <RightContent>
        <Title type={type}>{title}</Title>
        {subtitle && <Subtitle type={type}>{subtitle}</Subtitle>}
        {scanLink && (
          <LinkBsc href={scanLink} target="_blank">
            {lang.VIEW_ON_BSCSCAN}
          </LinkBsc>
        )}

        <CloseIcon
          onClick={() => {
            setShow(false);
            alert.closeAll();
          }}
          type={type}
        />
      </RightContent>
    </AlertContainer>
  );
};

const Alert: React.FC = () => {
  const { notifications } = useContext(AlertContext);
  return (
    <Container>
      {notifications.map((notification, idx) => (
        <AlertItem key={notification.id} {...notification} />
      ))}
    </Container>
  );
};

export default Alert;
