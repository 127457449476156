import { handleApi } from "./server";
const pricesService = {
  getTokenPrices: async () => {
    return await handleApi("/token/price", "get", {});
  },
  getBusdTokenPrices: async () => {
    return await handleApi("/token/price/busd", "get", {});
  },
  getBnbCryptoPrices: async () => {
    return await handleApi("/crypto/price/bnb", "get", {});
  },
};
export default pricesService;
