import React from "react";
import {
  ButtonInput,
  Container,
  Header,
  Input,
  InputContainer,
  Label,
  Title,
} from "./styles";

interface IInputText {
  title: string;
  value: string;
  setValue: (val: string) => void;
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  type?: string;
  label?: string;
  buttonInputAction?: () => void;
  buttonInputTitle?: string;
  id?: string;
  key?: string;
  name?: string;
}
const InputText: React.FC<IInputText> = ({
  title,
  value,
  setValue,
  disabled,
  containerStyle,
  type = "text",
  label,
  buttonInputAction,
  buttonInputTitle,
  id,
  key,
  name,
}) => {
  return (
    <Container style={{ ...containerStyle }}>
      <Header>
        <Title>{title}</Title>
        {label && <Label>{label}</Label>}
      </Header>
      <InputContainer disabled={disabled}>
        <Input
          {...{
            label,
            value,
            disabled,
            type,
            id,
            key,
            name,
            onChange: (e) => {
              if (!disabled) setValue(e.target.value);
            },
          }}
        />

        {buttonInputTitle && (
          <ButtonInput onClick={buttonInputAction}>
            {buttonInputTitle}
          </ButtonInput>
        )}
      </InputContainer>
    </Container>
  );
};
export default InputText;
