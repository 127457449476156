import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import formatText from "lib/formatText";
import React, { useContext, useEffect, useMemo, useState } from "react";
import userService from "services/userService";
import {
  ActivityContainer,
  BodyContent,
  Container,
  HashLink,
  HeaderContent,
  ListActivitiesContainer,
  Status,
  Subtitle,
  Title,
} from "./styles";

interface IActivities {
  title: string;
  hash: string;
  url?: string;
  status: string;
  flow: "swap" | "in" | "out";
  type: any;
  [key: string]: any;
}
interface IListActivities {
  activities: IActivities[];
}
const ListActivities: React.FC<IListActivities> = ({ activities }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  return (
    <ListActivitiesContainer>
      {activities.map((asset, idx) => (
        <ActivityContainer key={idx}>
          <HeaderContent>
            <Title>{asset.title}</Title>

            {asset.hash && asset.value && (
              <Subtitle>
                {`${asset.flow === "out" ? "-" : "+"} ${formatText.formatNumber(
                  {
                    number: asset.pairValue || asset.value,
                  }
                )} ${
                  asset.type === "claim_earn"
                    ? asset.earnedCoin.toUpperCase()
                    : asset.pairCoin
                    ? asset.pairCoin.toUpperCase()
                    : asset.coin.toUpperCase()
                }`}
              </Subtitle>
            )}
          </HeaderContent>
          <BodyContent>
            <Status hash={asset.hash}>{asset.status}</Status>
            {asset.hash && (
              <HashLink href={asset.url} target="_blank">
                {formatText.truncateHash({
                  hash: asset.hash,
                  startLength: screenWidth > 350 ? 10 : 6,
                  endLength: screenWidth > 350 ? 10 : 6,
                })}
              </HashLink>
            )}
          </BodyContent>
        </ActivityContainer>
      ))}
    </ListActivitiesContainer>
  );
};
const Activities: React.FC = () => {
  const [activities, setActivities] = useState([]);
  const { balance } = useContext(UserContext);
  const { lang } = useContext(LangContext);
  const balanceMemo = useMemo(() => {
    return balance;
  }, [balance]);
  useEffect(() => {
    const ac = new AbortController();
    const getActivities = async () => {
      try {
        const res = await userService.transactionHistory(ac.signal);
        setActivities(res.data);
      } catch (error) {
        // console.log(error);
      }
    };
    getActivities();
    return () => ac.abort();
  }, [balanceMemo, lang]);

  return (
    <Container>
      <ListActivities activities={activities} />
    </Container>
  );
};

export default Activities;
