import pt from "assets/flags/pt.svg";
import en from "assets/flags/en.svg";
export interface ILanguages {
  name: string;
  icon: string;
  key: string;
}

const languages: ILanguages[] = [
  { name: "Portuguese", icon: pt, key: "pt" },
  { name: "English", icon: en, key: "en" },
];
export default languages;
