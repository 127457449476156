import styled from "styled-components";

export const SecondaryHeaderBody = styled.div`
  position: relative;
  height: 62px;
  border-bottom: 4px solid ${(props) => props.theme.colors.primaryDark};
  padding: 0 20px;
  ${(props) => props.theme.aligns.vertical};
  justify-content: space-between;
`;
export const TitleSecondaryHeaderBody = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.primaryFont};
  ${(props) => props.theme.fonts.poppins};
`;
export const CacauDigitalWalletContainer = styled.div`
  background-color: ${(props) => props.theme.colors.walletBgcStick};
  padding: 5px 10px;
  border-radius: 5px;
  ${(props) => props.theme.aligns.vertical};
`;
export const CacauDigitalWalletLogo = styled.img`
  width: 20px;
  margin-right: 5px;
`;
export const CacauDigitalWalletText = styled.span`
  font-weight: 700;
  font-size: 12px;
  color: ${(props) => props.theme.colors.primaryFont};
  ${(props) => props.theme.fonts.poppins};
`;
