import styled, { css } from "styled-components";

export const Container = styled.div<{ column: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  width: 100px;
  justify-content: space-around;
  align-items: center;
`;

export const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #d6a84c;
  padding: 5px;
  cursor: pointer;
`;

export const Title = styled.span(
  (props) => css`
    font-size: 14px;
    font-weight: 700;
    color: ${props.theme.colors.primaryBorderInput};
    margin-left: 4px;
    ${props.theme.fonts.poppins};
    margin-bottom: 10px;
    line-height: 15px;
    text-align: center;
  `
);

export const CheckIcon = styled.img`
  transform: rotate(180deg);
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
