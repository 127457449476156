import styled, { css } from "styled-components";

export const Container = styled.div`
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
  justify-content: space-between;
`;
export const Form = styled.form`
  width: 100%;
  min-height: 380px;
  ${(props) =>
    props.theme.responsive.sm(css`
      ${(props) => props.theme.aligns.vertical};
      flex-flow: column;
    `)}
`;
export const ButtonsContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  justify-content: space-between;
`;

export const AllowButtonsContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;
export const AllowPlatformTokenContainer = styled.div`
  display: flex;
`;
export const ImgBlock = styled.img`
  margin-right: 5px;
`;
