import ButtonDefault from "components/buttons/ButtonDefault";
import ButtonGradient from "components/buttons/ButtonGradient";
import ErrorMessage from "components/texts/ErrorMessage";
import { coins, dictionaryCoins } from "constants/coins";
import AuthContext from "contexts/Auth";
import LangContext from "contexts/Lang";
import { alert } from "lib/Alert";
import formatText from "lib/formatText";
import validator from "lib/validator";
import LoadingPage from "pages/LoadingPage";
import Main from "pages/templates/Main";
import { default as React, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import earnService from "services/earn";
import {
  Body,
  ButtonsContainer,
  Container,
  DescriptionRowTransactionInfo,
  Divider,
  InfoContent,
  RowTransactionInfo,
  TitleRowTransactionInfo,
  TitleTransactionInfo,
  TitleHeader,
  TransactionInfo,
} from "../styles";
import {
  Header,
  IconCoin,
  IconCoinContainer,
  TokenCoin,
  TokenTitle,
  WalletContainer,
} from "./styles";

const EarnToken: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { lang } = useContext(LangContext);
  const { updateUserData } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [loadingEstimate, setLoadingEstimate] = useState(true);
  const [token, setToken] = useState("");

  const [estimate, setEstimate] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.hash.split("?")[1];
    const urlParams = new URLSearchParams(queryString);

    const tokenUrl = urlParams.get("token");

    if (!tokenUrl) {
      setErrorMessage(lang.TOKEN_MUST_BE_INFORMED);
      return;
    }
    if (!dictionaryCoins[tokenUrl]) {
      setErrorMessage(lang.INVALID_TOKEN);
      return;
    }
    setToken(tokenUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const estimateEarn = async () => {
      try {
        setLoadingEstimate(true);
        if (token) {
          const res = await earnService.estimateClaim({ coin: token });
          setEstimate(res.data);
          setLoadingEstimate(false);
        }
      } catch (error: any) {
        // console.log(error);
        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          validator.verifyErrors(errorRes.data);

          if (errorRes.data.errors[0].id === "NO_EARNED_VALUE_TO_CLAIM") {
            navigate("/", { replace: true });
            return;
          } else {
            setTimeout(() => {
              setLoadingEstimate(false);
            }, 500);
          }
        }
      }
    };

    estimateEarn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await earnService.executeClaim({
        coin: token,
      });
      await updateUserData();
      setTimeout(() => {
        setLoading(false);
        alert.open({ title: res.message, type: "success" }, true);
        navigate("/", { replace: true });
      }, 1000);
    } catch (error: any) {
      // console.log(error);
      setTimeout(() => {
        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          validator.verifyErrors(errorRes.data);

          if (errorRes.data.errors[0].id === "NO_EARNED_VALUE_TO_CLAIM") {
            navigate(-1);
            return;
          }
        }
        setLoading(false);
      }, 1000);
    }
  };

  return loadingEstimate ? (
    <LoadingPage loadingLabel={lang.CHECKING_DATA} />
  ) : (
    <Main
      headerBody={{
        title: lang.CONFIRM_TRANSACTION,
        backPath: "/",
        type: "secondary",
      }}
    >
      <Container>
        {errorMessage && <ErrorMessage message={errorMessage} />}

        <InfoContent>
          {token && estimate && (
            <>
              <Header>
                <TitleHeader>{lang.TOKEN_REWARD}</TitleHeader>
              </Header>
              <Body>
                <WalletContainer>
                  <TokenCoin src={coins[dictionaryCoins[token]].icon} alt="" />
                  <TokenTitle>
                    {coins[dictionaryCoins[token]].symbol}
                  </TokenTitle>
                </WalletContainer>

                <TransactionInfo>
                  <TitleTransactionInfo>{lang.DETAILS}</TitleTransactionInfo>

                  <>
                    <RowTransactionInfo>
                      <TitleRowTransactionInfo>
                        {lang.REWARD_IN}
                      </TitleRowTransactionInfo>
                      <IconCoinContainer>
                        <IconCoin
                          src={
                            token &&
                            coins[
                              dictionaryCoins[
                                coins[dictionaryCoins[token]].earnKey || ""
                              ]
                            ].icon
                          }
                          alt="bnb"
                        />
                        {token &&
                          coins[
                            dictionaryCoins[
                              coins[dictionaryCoins[token]].earnKey || ""
                            ]
                          ].symbol}
                      </IconCoinContainer>
                    </RowTransactionInfo>
                    <RowTransactionInfo>
                      <TitleRowTransactionInfo>
                        {lang.QUANTITY}
                      </TitleRowTransactionInfo>
                      <DescriptionRowTransactionInfo>
                        {`${formatText.formatNumber({
                          number: parseFloat(estimate.withdrawableValue),
                        })} 
                        ${
                          coins[
                            dictionaryCoins[
                              coins[dictionaryCoins[token]].earnKey || ""
                            ]
                          ].symbol
                        }
                        `}
                      </DescriptionRowTransactionInfo>
                    </RowTransactionInfo>
                    <RowTransactionInfo>
                      <TitleRowTransactionInfo>
                        {lang.FEE}
                      </TitleRowTransactionInfo>
                      <DescriptionRowTransactionInfo>
                        {`${formatText.formatNumber({
                          number: parseFloat(estimate.gasInCrypto),
                        })} 
                        BNB
                        `}
                      </DescriptionRowTransactionInfo>
                    </RowTransactionInfo>
                    <RowTransactionInfo bold>
                      <TitleRowTransactionInfo>
                        {lang.TOTAL_SPENT}
                      </TitleRowTransactionInfo>
                      <DescriptionRowTransactionInfo>
                        {`${formatText.formatNumber({
                          number: parseFloat(estimate.gasInCrypto),
                        })} BNB`}
                      </DescriptionRowTransactionInfo>
                    </RowTransactionInfo>
                    <Divider />
                    <RowTransactionInfo bold>
                      <TitleRowTransactionInfo>
                        {lang.TOTAL_RECEIVE}
                      </TitleRowTransactionInfo>
                      <DescriptionRowTransactionInfo>
                        {` ${formatText.formatNumber({
                          number: parseFloat(estimate.withdrawableValue),
                        })} 
                        ${
                          coins[
                            dictionaryCoins[
                              coins[dictionaryCoins[token]].earnKey || ""
                            ]
                          ].symbol
                        } `}
                      </DescriptionRowTransactionInfo>
                    </RowTransactionInfo>
                  </>
                </TransactionInfo>
              </Body>
            </>
          )}
        </InfoContent>
        <ButtonsContainer>
          <ButtonDefault
            {...{
              width: errorMessage ? "320px" : "",
              title: lang.BACK,
              disabled: loading,
              action: () => navigate(-1),
            }}
          />

          {!errorMessage && (
            <ButtonGradient
              {...{
                title: lang.CONFIRM,
                disabled: loading,
                loading,
                onClick: handleSubmit,
              }}
            />
          )}
        </ButtonsContainer>
      </Container>
    </Main>
  );
};

export default EarnToken;
