import { alert } from "./Alert";

const validator = {
  throwInvalidInputMessage: ({
    inputName,
    message,
  }: {
    inputName: string;
    message: string;
  }) => {
    const input = document.getElementById(inputName);
    if (input) {
      alert.open({ title: message, type: "error" });
      input.focus();
    }
  },

  validateInputs: (data: { [key: string]: any }) => {
    let valid = true;
    Object.keys(data).map((key) => {
      if (!data[key]) {
        valid = false;
      }
      return null;
    });

    return valid;
  },

  verifyValidationErrors: (data: {
    validationErrors: { id: string; message: string }[];
  }) => {
    // Exibindo possíveis erros de validação
    data.validationErrors.map((error) => {
      alert.open({ title: error.message, type: "warning" });
      return null;
    });
  },
  verifyErrors: (data: {
    errors: { id: string; message: string }[];
    validationErrors: { id: string; message: string }[];
  }) => {
    alert.closeAll();
    // Verificando se exsitem erros a serem exibidos
    if (data) {
      if (data.errors) {
        data.errors.map((error) => {
          // console.log(error);
          if (error.id === "EMAIL_SENDING_FAILS") {
            alert.open({ title: error.message, type: "warning" });
          } else alert.open({ title: error.message, type: "error" });
          return null;
        });
      }
      if (data.validationErrors) {
        validator.verifyValidationErrors(data);
      }
    }
  },
};

export default validator;
