import React from "react";

import { Container, Message } from "./styles";
interface IErrorMessage {
  message: string;
}
const ErrorMessage: React.FC<IErrorMessage> = ({ message }) => {
  return (
    <Container>
      <Message>{message}</Message>
    </Container>
  );
};

export default ErrorMessage;
