import React from "react";

import {
  Container,
  Input,
  InputContainer,
  Title,
  MaxButton,
  Header,
  Label,
} from "./styles";

interface IInputNumber {
  title: string;
  value: string;
  setValue: (val: string) => void;
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  label?: string;
  valueMax?: () => void;
  placeholder?: string;
}
const InputNumber: React.FC<IInputNumber> = ({
  title,
  value,
  setValue,
  disabled,
  containerStyle,
  label,
  placeholder,
  valueMax,
}) => {
  return (
    <Container style={{ ...containerStyle }}>
      <Header>
        <Title>{title}</Title>
        {label && <Label>{label}</Label>}
      </Header>
      <InputContainer disabled={disabled}>
        <Input
          {...{
            type: "number",
            value,
            disabled,
            placeholder,
            onChange: (e) => {
              if (!disabled) setValue(e.target.value);
            },
          }}
        />
        {valueMax && <MaxButton onClick={() => valueMax()}>MAX</MaxButton>}
      </InputContainer>
    </Container>
  );
};
export default InputNumber;
