import React from "react";
import { TailSpin } from "react-loader-spinner";
import { Container, Label } from "./styles";

interface IButtonGradient {
  title: string | any;
  onClick: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  loading?: boolean;
  width?: string;
  disabled?: boolean;
}

const ButtonGradient: React.FC<IButtonGradient> = ({
  title,
  onClick,
  loading,
  width,
  disabled,
}) => {
  return (
    <Container
      {...{
        disabled,
        $loading: loading,
        onClick: disabled || loading ? () => {} : onClick,
        width,
      }}
    >
      {loading ? (
        <TailSpin height="20" width="20" color="white" ariaLabel="" />
      ) : (
        <Label>{title}</Label>
      )}
    </Container>
  );
};

export default ButtonGradient;
