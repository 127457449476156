// import AuthContext from "contexts/Auth/Index";
import copyIcon from "assets/copy_white.svg";
import SpreadLoading from "components/SpreadLoading";
import UserContext from "contexts/User";
import formatText from "lib/formatText";
import helpers from "lib/helpers";
import math from "lib/math";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  BalanceContainer,
  BalanceMainText,
  BalanceSecondaryText,
  Container,
  WalletContainer,
  WalletContainerIcon,
  WalletHash,
  WalletIconImg,
} from "./styles";
import qrCodeIcon from "assets/qr_code_white.svg";

const WalletBalance: React.FC = () => {
  const { user, balance } = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <Container>
      <WalletContainer>
        <WalletHash>
          {user
            ? formatText.truncateHash({
                hash: user.walletAddress,
                startLength: 5,
                endLength: 5,
              })
            : "..."}
        </WalletHash>
        <WalletContainerIcon>
          <WalletIconImg
            src={copyIcon}
            alt="Copiar carteira"
            onClick={() => {
              if (user) {
                helpers.copyToClipboard(user.walletAddress);
              }
            }}
          />
          <WalletIconImg
            src={qrCodeIcon}
            alt="Qr-code"
            onClick={() => navigate("/deposit/crypto", { replace: false })}
          />
        </WalletContainerIcon>
      </WalletContainer>
      <BalanceContainer>
        <BalanceMainText>
          {balance ? (
            formatText.formatNumber({
              number: balance.wallet.total.usd,
              fixed: 2,
            })
          ) : (
            <SpreadLoading reverse width="30px" />
          )}
          {` USD`}
        </BalanceMainText>
        <BalanceSecondaryText>
          {math.isGreaterThan(balance.earn.crd.busd.available, "0") &&
          balance.earn &&
          balance.earn.usd ? (
            `+ ${formatText.formatNumber({
              number: balance.earn.usd,
              fixed: 2,
            })}`
          ) : math.isGreaterThan(balance.earn.crd.busd.available, "0") ? (
            <SpreadLoading reverse width="20px" />
          ) : (
            `+ ${formatText.formatNumber({
              number: "0",
              fixed: 2,
            })}`
          )}
          {` USD`}
        </BalanceSecondaryText>
      </BalanceContainer>
    </Container>
  );
};

export default WalletBalance;
