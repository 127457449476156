import fullLogo from "assets/full_logo.svg";
import ButtonGradient from "components/buttons/ButtonGradient";
import InputText from "components/forms/InputText";
import AuthContext from "contexts/Auth";
import LangContext from "contexts/Lang";
import { alert } from "lib/Alert";
import validator from "lib/validator";
import Guest from "pages/templates/Guest";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  ForgotPasswordText,
  ImgLogo,
  Link,
  SignUpText,
} from "./styles";

const LoginContent: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { lang } = useContext(LangContext);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const handleSubmit = async () => {
    const configDiv = document.getElementById("settings-account-container");
    try {
      configDiv!.style.display = "none";
      setLoading(true);
      await login({ email, password });
      alert.closeAll();
      configDiv!.style.display = "block";
    } catch (error: any) {
      setLoading(false);
      configDiv!.style.display = "block";
      if (error.message === "Network Error") {
        alert.open({ title: lang.SERVER_DOWN, type: "info" });
      }
      const errorRes = error.response;
      if (errorRes && errorRes.data) {
        validator.verifyErrors(errorRes.data);
      }
    }
  };

  return (
    <Container>
      <ImgLogo src={fullLogo} alt="Logo" />
      <InputText
        {...{
          title: `${lang.EMAIL}:`,
          value: email,
          setValue: setEmail,
          name: "email",
        }}
      />
      <br />
      <InputText
        {...{
          title: `${lang.PASSWORD}:`,
          value: password,
          setValue: setPassword,
          type: "password",
          name: "password",
        }}
      />
      <Link>
        <ForgotPasswordText
          onClick={() => navigate("/forgot-password", { replace: true })}
        >
          {lang.FORGOT_PASSWOD}
        </ForgotPasswordText>
      </Link>
      <br />
      <br />
      <ButtonGradient
        {...{
          width: screenWidth > 350 ? "320px" : "260px",
          title: lang.LOGIN,
          onClick: handleSubmit,
          loading,
          disabled: loading,
        }}
      />
      <br />

      <SignUpText>
        {lang.DONT_HAVE_ACCOUNT + " "}
        <Link onClick={() => navigate("/signup", { replace: false })}>
          {lang.REGISTER}!
        </Link>
      </SignUpText>
    </Container>
  );
};

const Login: React.FC = () => {
  return (
    <Guest settingsAccount>
      <LoginContent />
    </Guest>
  );
};

export default Login;
