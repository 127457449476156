import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApprovalToken from "./ApprovalToken";
import EarnToken from "./EarnToken";
import SendToken from "./SendToken";
import SwapToken from "./SwapToken";

const ConfirmTransaction: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [type, setType] = useState("");

  const types = {
    send: { key: "send", component: <SendToken /> },
    swap: { key: "swap", component: <SwapToken /> },
    approval: { key: "approval", component: <ApprovalToken /> },
    earn: { key: "earn", component: <EarnToken /> },
  };

  useEffect(() => {
    const queryString = window.location.hash.split("?")[1];
    const urlParams = new URLSearchParams(queryString);
    const typeUrl = urlParams.get("type");

    if (typeUrl && types[typeUrl]) {
      setType(typeUrl);
    } else {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location]);
  return <>{types[type]?.component}</>;
};

export default ConfirmTransaction;
