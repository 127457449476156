import { allCoins, sendCoinType, swapCoinType, tokens } from "constants/coins";
import { handleApi } from "./server";
const transactionService = {
  taxesAndLimits: async () => {
    return await handleApi("/transactions/taxesAndLimits", "get", {});
  },
  sendTransaction: async (data: {
    to: string;
    value: string;
    coin: sendCoinType;
  }) => {
    return await handleApi("/transactions/send/crypto/save", "post", {
      data,
    });
  },
  swapTransaction: async (data: {
    coinAmount: string;
    coin: string;
    pairCoin: string;
  }) => {
    return await handleApi("/transactions/swap", "post", {
      data,
    });
  },
  approvalTransaction: async (data: { coin: allCoins }) => {
    return await handleApi("/transactions/approve", "post", {
      data,
    });
  },
  accountTokenIsApproved: async (params: { coin: tokens }) => {
    return await handleApi("/transactions/approve/check", "get", {
      params,
    });
  },

  executePendingTransaction: async (data: { token: string }) => {
    return await handleApi("/transactions/pending/execute", "post", {
      data,
    });
  },
  estimateGasSend: async (params: {
    to: string;
    value: string;
    coin: sendCoinType;
  }) => {
    return await handleApi("/transactions/send/crypto/estimate", "get", {
      params,
    });
  },
  estimateGasSwap: async (
    params: {
      coinAmount: string;
      coin: swapCoinType;
      pairCoin: string;
    },
    signal?: AbortSignal
  ) => {
    return await handleApi("/transactions/swap/estimate", "get", {
      params,
      signal,
    });
  },
  estimateGasClaimEarn: async (params: { coin: swapCoinType }) => {
    return await handleApi("/earn/claim/estimate", "get", {
      params,
    });
  },
  estimateGasApproval: async (params: { coin: allCoins }) => {
    return await handleApi("/transactions/approve/estimate", "get", { params });
  },
};
export default transactionService;
