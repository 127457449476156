import { handleApi } from "./server";
const earnService = {
  executeClaim: async (data: { coin: string }) => {
    return await handleApi("/earn/claim", "post", { data });
  },
  estimateClaim: async (data: { coin: string }) => {
    return await handleApi("/earn/claim/estimate", "post", { data });
  },
};
export default earnService;
