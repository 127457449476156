import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
`;
interface ITab {
  active?: boolean;
}
export const Tab = styled.div<ITab>`
  display: none;
  ${(props) =>
    props.active &&
    css`
      display: block;
    `}
`;
export const HeaderTabs = styled.div`
  display: flex;
  padding: 0 10px;
  overflow-x: auto;
  /*CUSTOMIZING SCROLLBAR*/
  /* width */
  &::-webkit-scrollbar {
    height: 4px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.blackTransparentMindLight};
  }
`;
interface ITabHeader {
  active?: boolean;
}
export const TabHeader = styled.div<ITabHeader>`
  cursor: pointer;
  padding: 5px 15px;

  flex-shrink: 0;
  flex: 1 1 0px;
  color: ${(props) => props.theme.colors.white}77;
  border-bottom: 2px solid transparent;
  ${(props) => props.theme.fonts.poppins};
  font-size: 14px;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  ${(props) =>
    props.active
      ? css`
          border-bottom: 2px solid ${(props) => props.theme.colors.primaryFont};
          color: ${(props) => props.theme.colors.primaryFont};
        `
      : css`
          &:hover {
            color: ${(props) => props.theme.colors.primaryFont}dd;
          }
        `};
`;
export const BodyTabs = styled.div`
  padding-top: 10px;
`;
