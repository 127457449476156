// import shareIcon from "assets/share.svg";
import Checkbox from "components/forms/Checkbox";
import InputText from "components/forms/InputText";
import LangContext from "contexts/Lang";
import TaxesAndLimitsContext from "contexts/TaxAndLimits";
import UserContext from "contexts/User";
import formatText from "lib/formatText";
import helpers from "lib/helpers";
import masks from "lib/masks";
import Main from "pages/templates/Main";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  InfosContainer,
  ListContainer,
  ListItem,
  NavigateButton,
  QrCodeContainer,
  TermsContainer,
  TermsText,
  TextHeader,
  TextPix,
  UserInfoContainer,
  WarningContainer,
} from "./styles";

const DepositContent: React.FC = () => {
  const { lang } = useContext(LangContext);
  const { user } = useContext(UserContext);
  const [checkbox, setCheckbox] = useState(false);
  const navigate = useNavigate();
  const { taxesAndLimits } = useContext(TaxesAndLimitsContext);

  const [documentsAlreadySubmitted, setDocumentsAlreadySubmitted] =
    useState(false);

  useEffect(() => {
    // console.log({ taxesAndLimits });
    if (user.analysisPercentage > 75) setDocumentsAlreadySubmitted(true);
  }, []);

  // useEffect(() => {
  //   console.log({ user });
  // }, [user]);

  const pixKey = "pix@crddao.io";
  const pixCode =
    "00020126350014BR.GOV.BCB.PIX0113pix@crddao.io5204000053039865802BR5925RENTAL BUSINESS FINTECH E6009JOINVILLE62070503***6304BDC6";

  const pixContent = (
    <>
      <UserInfoContainer>
        <InfosContainer>
          <TextPix>{lang.DEPOSIT_ACCOUNT_PIX_BELOW}</TextPix>
        </InfosContainer>
      </UserInfoContainer>
      <QrCodeContainer>
        <img src={helpers.createQrcodeUrl(pixCode)} alt="qr-code" />
      </QrCodeContainer>
      <Form>
        <InputText
          {...{
            title: lang.PIX_KEY,
            disabled: true,
            placeholder: pixKey,
            value: pixKey,
            setValue: () => {},
            containerStyle: { margin: "10px 0" },
          }}
        />
        <InputText
          {...{
            title: lang.BENEFICIARY,
            disabled: true,
            placeholder: "Rental Business Fintech",
            value: "Rental Business Fintech",
            setValue: () => {},
            containerStyle: { margin: "10px 0" },
          }}
        />
        <InputText
          {...{
            title: lang.BANK,
            disabled: true,
            placeholder: "364 - Gerencianet S.A",
            value: "364 - Gerencianet S.A",
            setValue: () => {},
            containerStyle: { margin: "10px 0" },
          }}
        />
        <InputText
          {...{
            title: lang.EMPLOYER_IDENTIFICATION_NUMBER,
            disabled: true,
            placeholder: "38.185.479/0001-50",
            value: "38.185.479/0001-50",
            setValue: () => {},
            containerStyle: { margin: "10px 0" },
          }}
        />
      </Form>
    </>
  );

  const pixContentWarning = (
    <>
      <WarningContainer>
        <ListContainer>
          <UserInfoContainer style={{ marginTop: 10, marginBottom: "10px" }}>
            <InfosContainer>
              <TextPix style={{ fontSize: "16px" }}>{lang.WARNING}</TextPix>
            </InfosContainer>
          </UserInfoContainer>
          <ListItem>
            {/* <CheckIcon src={checkIcon} /> */}
            {lang.DECLARE_SAMPLE_ACCOUNT_CPF +
              masks.cpfMask(user.documentNumber || "")}
          </ListItem>
          <ListItem>
            {/* <CheckIcon src={checkIcon} /> */}
            {lang.MIN_VALUE_IS +
              `: ${formatText.formatNumber({
                number: taxesAndLimits.limits.swap.brlcrd.brl,
                fixed: 2,
              })} BRL`}
          </ListItem>
          <ListItem>
            {/* <CheckIcon src={checkIcon} /> */}
            {lang.TERMS_PIX_CNPJ}
          </ListItem>
          <ListItem>
            {/* <CheckIcon src={checkIcon} /> */}
            {lang.TERMS_PIX_PRE}
          </ListItem>
          {/* <ListItem> */}
          {/* <CheckIcon src={checkIcon} /> */}
          {/* {lang.TERMS_PIX_EXT} */}
          {/* </ListItem> */}
        </ListContainer>
      </WarningContainer>
      <TermsContainer>
        <Checkbox {...{ value: checkbox, setValue: setCheckbox }} />
        <TermsText>{lang.DEPOSIT_WARNING}</TermsText>
      </TermsContainer>
    </>
  );

  return (
    <Container>
      {documentsAlreadySubmitted ? (
        checkbox ? (
          pixContent
        ) : (
          pixContentWarning
        )
      ) : (
        <WarningContainer>
          <TextHeader>{lang.UPDATE_ACCOUNT_PIX}</TextHeader>
          <NavigateButton onClick={() => navigate("/account/data")}>
            {lang.UPDATE_INFO}
          </NavigateButton>
        </WarningContainer>
      )}
    </Container>
  );
};
const DepositPix: React.FC = () => {
  const { lang } = useContext(LangContext);

  return (
    <Main headerBody={{ title: lang.PIX, backPath: "/" }}>
      <DepositContent />
    </Main>
  );
};
export default DepositPix;