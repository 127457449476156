import styled, { css } from "styled-components";
interface IDisabled {
  disabled?: boolean;
}
export const Container = styled.div`
  margin-top: -2px;
  width: 100%;
`;
export const Title = styled.span(
  (props) => css`
    font-size: 14px;
    font-weight: 700;
    color: ${props.theme.colors.primaryBorderInput};
    margin-left: 4px;
    ${props.theme.fonts.poppins};
  `
);

export const SelectedOptionContainer = styled.div<IDisabled>(
  (props) => css`
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    margin-top: 3px;
    position: relative;
    max-width: 86%;
  `
);
export const SelectedOption = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    ${props.theme.aligns.vertical};
    font-size: 12px;
    font-weight: 500;
    padding: 0 0 0 5px;
    height: 15px;
    margin-right: 10px;
    color: ${props.theme.colors.primaryFont};
  `
);
export const IconOption = styled.img`
  width: 25px;
  margin: 0 0 0 10px;
`;
export const IconTriangle = styled.img<{ open?: boolean }>`
  ${(props) =>
    props.open &&
    css`
      transform: rotate(180deg);
    `};
  width: 10px;
  margin: 0 10px 0 0;
  transition: all 0.2s ease-in-out;
`;
export const OptionsContainer = styled.div`
  width: 100%;
  height: 200px;
  z-index: 1;
  top: 33px;
  border: 1px solid ${(props) => props.theme.colors.tertiary};
  left: -1px;
  width: 100%;
  position: absolute;
  background-color: ${(props) => props.theme.colors.tertiary};
  padding: 15px 0 0 0;
  box-shadow: 0px 4px 4px
    ${(props) => props.theme.colors.blackTransparentDarker};
  border-radius: 8px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow: auto;
`;
export const OptionContainer = styled.div(
  (props) => css`
    padding: 8px 0;
    ${props.theme.aligns.vertical};
    &:hover {
      background-color: ${props.theme.colors.secundarySupport}77;
    }
  `
);

export const SelectOptionTitle = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    ${props.theme.aligns.vertical};
    font-weight: 600;
    font-size: 14px;
    padding: 0 5px;
    width: 100%;
    color: ${props.theme.colors.primarySupport};
  `
);

export const IconOptions = styled(IconOption)``;
export const Input = styled.input(
  (props) => css`
    ${props.theme.fonts.poppins};
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    height: 100%;
    color: ${props.theme.colors.primaryFont};
    border-radius: 0 16px 16px 0;
    ${props.disabled &&
    css`
      color: ${props.theme.colors.primaryTextDisabled};
    `}
    &:focus {
      border-bottom: none;
      margin: 0;
      box-shadow: none;
      outline: none;
    }
    &::placeholder {
      color: ${props.theme.colors.neutralDarker};
      ${props.theme.fonts.poppins};
      font-weight: 700;
    }
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  `
);
export const SelectContainer = styled.div`
  ${(props) => props.theme.aligns.vertical}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #d6a84c;
  width: 100%;
`;
export const Header = styled.div(
  (props) => css`
    ${props.theme.aligns.vertical};
    justify-content: space-between;
    margin-bottom: 1em;
    height: 20px;
  `
);
export const Label = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    font-weight: 300;
    color: ${props.theme.colors.primaryFont};
    font-size: 12px;
    width: 100%;
  `
);

export const Value = styled.span(
  (props) => css`
    ${props.theme.fonts.poppins};
    font-weight: 300;
    color: ${props.theme.colors.primaryFont};
    font-size: 12px;
  `
);
