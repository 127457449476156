import { alert } from "lib/Alert";
import React, { createContext, useContext, useEffect, useState } from "react";
import authService from "services/authService";
import authStorage from "storage/auth";
import PricesContext from "./Prices";
import TaxesAndLimitsContext from "./TaxAndLimits";
import UserContext from "./User";
import moment from "moment";
import { auth } from "lib/Auth";

interface ILogin {
  email: string;
  password: string;
}
interface IAuthContext {
  isUpdatingData: boolean;
  setIsUpdatingData: (data: boolean) => void;
  token: string;
  setToken: (data: string) => void;
  login: (data: ILogin) => Promise<any>;
  logout: () => Promise<any>;
  isCheckingToken: boolean;
  updateUserData: () => Promise<any>;
}
const AuthContext = createContext<IAuthContext>({
  token: "",
  setToken: (data) => {},
  isUpdatingData: false,
  setIsUpdatingData: (data) => {},
  login: async (data) => {},
  logout: async () => {},
  isCheckingToken: true,
  updateUserData: async () => {},
});
export const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string>("");
  const [isUpdatingData, setIsUpdatingData] = useState<boolean>(false);
  const [isCheckingToken, setIsCheckingLogin] = useState<boolean>(true);
  const { reset: resetUser, setUser, fetchBalance } = useContext(UserContext);
  const { reset: resetTaxesAndLimits, fetchTaxesAndLimits } = useContext(
    TaxesAndLimitsContext
  );
  const { reset: restMainTokenPrice, fetchTokenPrice } =
    useContext(PricesContext);
  useEffect(() => {
    auth.init({ logout });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateUserData = async () => {
    try {
      setIsUpdatingData(true);
      const _token = authStorage.get();
      if (_token) {
        const response = await authService.checkLogin();

        if (response) {
          setToken(_token);
          await fetchBalance();
          await fetchTaxesAndLimits();
          await fetchTokenPrice();
          setUser(response.data);
          return;
        } else {
          throw Error("Sem resposta");
        }
      }
      throw Error("Sem Token");
    } catch (error) {
      authStorage.remove();
      // console.log(error);
    }
    setIsUpdatingData(false);
  };
  // useEffect(() => {
  //   let interval;

  //   if (token) {
  //     interval = setInterval(() => updateUserData(), 15000);
  //   }

  //   return () => clearInterval(interval);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);
  useEffect(() => {
    let interval: any;
    const updateWithTokenInStorage = async () => {
      try {
        setIsCheckingLogin(true);
        await updateUserData();
        const _token = authStorage.get();
        if (_token) {
          let prices = await fetchTokenPrice();
          let now = moment();
          let serverTime = moment(prices.nextUpdateTimestamp);
          let serverTimeDiff = serverTime.diff(now, "milliseconds");

          setTimeout(() => {
            updateUserData();
            interval = setInterval(() => {
              updateUserData();
            }, 15000);
          }, serverTimeDiff);
        }
      } catch (error) {
        // console.log(error);
      }
      setTimeout(() => {
        setIsCheckingLogin(false);
      }, 1000);
    };
    updateWithTokenInStorage();
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async ({ email, password }: ILogin) => {
    return new Promise(async (resolve, reject) => {
      try {
        logout();
        const response = await authService.login({ email, password });
        setUser(response.data);
        authStorage.set(response.auth.token);

        await fetchTaxesAndLimits();
        await fetchBalance();
        await fetchTokenPrice();
        setToken(response.auth.token);

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
  const logout = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        setToken("");
        resetUser();
        resetTaxesAndLimits();
        restMainTokenPrice();
        alert.closeAll();
        authStorage.remove();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        login,
        logout,
        isCheckingToken,
        isUpdatingData,
        setIsUpdatingData,
        updateUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
