import { handleApi } from "./server";

const adminService = {
  adminAnalysisSave: async (data: { documentNumber; analysis }) => {
    return await handleApi("/admin/analysis/save", "post", { data });
  },
  adminCheckLogin: async () => {
    return await handleApi("/admin/checkLogin", "get", {});
  },
  adminAnalysisMessage: async () => {
    return await handleApi("/admin/analysis/messages", "get", {});
  },
  adminShowUser: async (adminData: { email; documentNumber }) => {
    const params = {
      email: adminData.email,
      documentNumber: adminData.documentNumber,
    };
    return await handleApi("/admin/user", "get", {
      adminData,
      params,
    });
  },
  adminListUser: async (data: {
    orderBy: any;
    order: string;
    filter: string;
    page: number;
    limit: number;
  }) => {
    const params = {
      orderBy: data["orderBy"],
      order: data["order"],
      filter: data["filter"],
      page: data["page"],
      limit: data["limit"],
    };
    return await handleApi("/admin/users", "get", {
      data,
      params,
    });
  },
};
export default adminService;
