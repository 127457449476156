import logoIcon from "assets/logo_icon.svg";
import cogImg from "assets/navbar/cog.svg";
import LangContext from "contexts/Lang";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SettingsAccount from "../MainHeader/SettingsAccount";
import { SettingImg } from "../MainHeader/styles";
import {
  Container,
  LogoContainer,
  Nav,
  RightContent,
  SettingButtonContainer,
  SettingsContainer,
} from "./styles";

const GuestHeader: React.FC<{ settingsAccount?: boolean }> = ({
  settingsAccount,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { lang } = useContext(LangContext);
  const [showSettingsAccount, setShowSettingsAccount] = useState(false);

  useEffect(() => {
    const listener = function (e) {
      setShowSettingsAccount(false);
    };
    window.addEventListener("click", listener);
    return () => {
      window.removeEventListener("click", listener);
    };
  }, []);

  useEffect(() => {}, [lang]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  const navigate = useNavigate();
  const handleRedirect = (path: string) => {
    if (navigate) {
      navigate(path, { replace: false });
    }
  };
  return (
    <Container>
      {screenWidth > 992 ? (
        <Nav>
          <LogoContainer onClick={() => handleRedirect("/")}>
            <img src={logoIcon} alt="logo" />
          </LogoContainer>
          <RightContent>
            {settingsAccount && (
              <SettingsContainer id="settings-account-container">
                <SettingButtonContainer
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSettingsAccount(!showSettingsAccount);
                  }}
                >
                  <SettingImg src={cogImg} alt="config" />
                </SettingButtonContainer>
                <SettingsAccount
                  {...{
                    show: showSettingsAccount,
                    setShow: setShowSettingsAccount,
                    user: false,
                  }}
                />
              </SettingsContainer>
            )}
          </RightContent>
        </Nav>
      ) : (
        <>
          <Nav mobile>
            <LogoContainer mobile onClick={() => handleRedirect("/")}>
              <img src={logoIcon} alt="logo" />
            </LogoContainer>
          </Nav>
          <RightContent style={{ right: 20, position: "absolute", top: "20%" }}>
            {settingsAccount && (
              <SettingsContainer id="settings-account-container">
                <SettingButtonContainer
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSettingsAccount(!showSettingsAccount);
                  }}
                >
                  <SettingImg src={cogImg} alt="config" />
                </SettingButtonContainer>
                <SettingsAccount
                  {...{
                    show: showSettingsAccount,
                    setShow: setShowSettingsAccount,
                    user: false,
                  }}
                />
              </SettingsContainer>
            )}
          </RightContent>
        </>
      )}
    </Container>
  );
};

export default GuestHeader;
