import styled from "styled-components";

export const Container = styled.div``;
export const Form = styled.form`
  min-height: 380px;
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
`;
export const ButtonContainer = styled.div`
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
`;
export const Message = styled.span`
  text-align: left;
  width: 300px;
  ${(props) => props.theme.fonts.roboto};
  font-size: 14px;
  color: ${(props) => props.theme.colors.primaryFont};
`;
