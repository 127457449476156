export const makeid = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export type TypeNotification = "success" | "error" | "info" | "warning";
export interface INotification {
  title?: string;
  id?: string;
  subtitle?: string;
  scanLink?: string;
  duration?: number;
  type: TypeNotification;
}

export interface IInit {
  open: (data: INotification, closeNotifications?: boolean) => void;
  closeAll: () => void;
}
export interface IAlert extends IInit {
  init: (data: IInit) => void;
}
class Alert implements IAlert {
  public init = (data: IInit) => {
    Object.assign(this, data);
  };
  public open = (data: INotification, closeNotifications?: boolean) => {};
  public closeAll = () => {};
}
export const alert = new Alert();
