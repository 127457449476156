import ButtonDefault from "components/buttons/ButtonDefault";
import ButtonGradient from "components/buttons/ButtonGradient";
import ErrorMessage from "components/texts/ErrorMessage";
import { allCoins, coins, dictionaryCoins } from "constants/coins";
import AuthContext from "contexts/Auth";
import LangContext from "contexts/Lang";
import { alert } from "lib/Alert";
import formatText from "lib/formatText";
import validator from "lib/validator";
import LoadingPage from "pages/LoadingPage";
import Main from "pages/templates/Main";
import { default as React, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import transactionService from "services/transactions";
import {
  Body,
  ButtonsContainer,
  Container,
  DescriptionRowTransactionInfo,
  InfoContent,
  RowTransactionInfo,
  TitleHeader,
  TitleRowTransactionInfo,
  TitleTransactionInfo,
  TransactionInfo,
} from "../styles";
import {
  ConfirmApprovalText,
  Header,
  IconCoin,
  IconCoinContainer,
  TokenCoin,
  WalletContainer,
} from "./styles";

const ApprovalToken: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { lang } = useContext(LangContext);
  const { updateUserData } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [estimateLoading, setEstimateLoading] = useState(true);
  const [token, setToken] = useState("");

  const [estimate, setEstimate] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.hash.split("?")[1];
    const urlParams = new URLSearchParams(queryString);

    const coinUrl = urlParams.get("coin") as string;
    const coinFromUrl = urlParams.get("coinFrom") as string;

    if (!coinUrl && !coinFromUrl) {
      setErrorMessage(lang.CURRENCY_MUST_BE_INFORMED);
      return;
    }
    if (!dictionaryCoins[coinUrl]) {
      setErrorMessage(lang.INVALID_TOKEN);
      return;
    }
    setToken(coinUrl || coinFromUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const estimateApproval = async () => {
      try {
        setEstimateLoading(true);
        if (token) {
          const res = await transactionService.estimateGasApproval({
            coin: token as allCoins,
          });

          setEstimate(res.data);
        }
      } catch (error: any) {
        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          if (
            errorRes.data.errors.length > 0 &&
            errorRes.data.errors[0].id === "APPROVE_IS_ALREADY_EXECUTED"
          ) {
            navigate(-1);
          } else {
            validator.verifyErrors(errorRes.data);
          }
        }
      }
      setTimeout(() => {
        setEstimateLoading(false);
      }, 500);
    };

    estimateApproval();
  }, [token]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await transactionService.approvalTransaction({
        coin: token as allCoins,
      });

      await updateUserData();
      alert.open({ title: res.message, type: "success" }, true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error: any) {
      // console.log(error);
      setTimeout(() => {
        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          validator.verifyErrors(errorRes.data);
        }
        setLoading(false);
      }, 1000);
    }
  };
  return estimateLoading || !estimate ? (
    <LoadingPage loadingLabel={lang.CHECKING_DATA} />
  ) : (
    <Main
      headerBody={{
        title: lang.CONFIRM_TRANSACTION,
        backPath: "/",
        type: "secondary",
      }}
    >
      <Container>
        {errorMessage && <ErrorMessage message={errorMessage} />}

        <InfoContent>
          {token && (
            <>
              <Header>
                <TitleHeader>{lang.PERMISSION}</TitleHeader>
              </Header>
              <Body>
                <WalletContainer>
                  <TokenCoin src={coins[dictionaryCoins[token]].icon} alt="" />
                </WalletContainer>

                <TransactionInfo>
                  <TitleTransactionInfo>{lang.DETAILS}</TitleTransactionInfo>

                  <RowTransactionInfo>
                    <TitleRowTransactionInfo>
                      {lang.ACTION}
                    </TitleRowTransactionInfo>
                    <IconCoinContainer>
                      {lang.PERMISSION_TO_FUNDS}
                    </IconCoinContainer>
                  </RowTransactionInfo>
                  <RowTransactionInfo>
                    <TitleRowTransactionInfo>
                      {lang.PERMISSION_TO}
                    </TitleRowTransactionInfo>
                    <IconCoinContainer>
                      {token && coins[dictionaryCoins[token]].symbol}
                      <IconCoin
                        src={token && coins[dictionaryCoins[token]].icon}
                        alt="bnb"
                      />
                    </IconCoinContainer>
                  </RowTransactionInfo>

                  <RowTransactionInfo>
                    <TitleRowTransactionInfo>
                      {lang.FEE}
                    </TitleRowTransactionInfo>
                    <DescriptionRowTransactionInfo>
                      {`${formatText.formatNumber({
                        number: parseFloat(estimate.gasInCrypto),
                      })} 
                        BNB
                        `}
                    </DescriptionRowTransactionInfo>
                  </RowTransactionInfo>
                  <RowTransactionInfo bold>
                    <TitleRowTransactionInfo>
                      {lang.TOTAL_SPENT}
                    </TitleRowTransactionInfo>
                    <DescriptionRowTransactionInfo>
                      {`${formatText.formatNumber({
                        number: parseFloat(estimate.gasInCrypto),
                      })} 
                      BNB
                        `}
                    </DescriptionRowTransactionInfo>
                  </RowTransactionInfo>
                </TransactionInfo>
              </Body>
              <ConfirmApprovalText>{lang.CONFIRM_APPROVAL}</ConfirmApprovalText>
            </>
          )}
        </InfoContent>
        <ButtonsContainer>
          <ButtonDefault
            {...{
              width: errorMessage ? "320px" : "",
              title: lang.BACK,
              disabled: loading,
              action: () => navigate(-1),
            }}
          />

          {!errorMessage && (
            <ButtonGradient
              {...{
                title: lang.CONFIRM,
                disabled: loading,
                loading,
                onClick: handleSubmit,
              }}
            />
          )}
        </ButtonsContainer>
      </Container>
    </Main>
  );
};

export default ApprovalToken;
