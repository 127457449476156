// import shareIcon from "assets/share.svg";
import ButtonGradient from "components/buttons/ButtonGradient";
import InputText from "components/forms/InputText";
import AuthContext from "contexts/Auth";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import { alert } from "lib/Alert";
import masks from "lib/masks";
import validator from "lib/validator";
import { useMounted } from "modules/usemounted";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userService from "services/userService";
import { ButtonContainer, Container, Form } from "./styles";

const PersonalContent: React.FC = () => {
  const { user } = useContext(UserContext);
  const { updateUserData } = useContext(AuthContext);
  const { lang } = useContext(LangContext);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [firstLoad, setFirstLoad] = useState(0);
  const [name, setName] = useState("");
  const [fullName, setFullName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const isMounted = useMounted();
  const navigate = useNavigate();

  useEffect(() => {
    setName(user.name ? user.name : "");
    setFullName(user.fullname ? user.fullname : "");
    setBirthDate(user.birthdate ? user.birthdate : "");
    setCellPhone(user.cellphone ? user.cellphone : "");
  }, []);

  const handleSubmit = async () => {
    if (!name || !fullName || !birthDate || !cellPhone) {
      alert.open({ title: lang.FILL_THE_INPUTS, type: "warning" });
      return;
    }

    setLoading(true);
    try {
      const data = {
        name: name,
        fullname: fullName,
        birthdate: masks.dayMonthYearMask(birthDate).toString(),
        phone: cellPhone,
        cellphone: cellPhone,
      };
      if (!isMounted()) return;
      const response = await userService.updateAccount(data);
      if (!isMounted()) return;
      setLoading(false);
      alert.closeAll();
      alert.open({ title: response.message, type: "success" });
      // navigate("/account/data");
      await updateUserData();
      // dispatch({ type: "ADD_USER", user: responseData.data });
    } catch (error: any) {
      // console.log({ error });
      if (error.message === "EMPTY_INPUT")
        alert.open({ title: lang.FILL_THE_INPUTS, type: "warning" }, true);
      const errorRes = error.response;
      if (errorRes && errorRes.data) {
        validator.verifyErrors(errorRes.data);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (firstLoad === 2) {
      setDisabled(false);
    } else {
      setFirstLoad(firstLoad + 1);
    }
  }, [name, fullName, birthDate, cellPhone]);

  return (
    <Container>
      <Form>
        <InputText
          {...{
            title: lang.NICKNAME,
            value: name,
            setValue: setName,
            containerStyle: { margin: "10px 0" },
          }}
        />
        <InputText
          {...{
            title: lang.FULL_NAME,
            value: fullName,
            setValue: setFullName,
            containerStyle: { margin: "10px 0" },
          }}
        />
        <InputText
          {...{
            title: lang.BIRTH_DATE,
            value: masks.dayMonthYearMask(birthDate),
            setValue: setBirthDate,
            containerStyle: { margin: "10px 0" },
          }}
        />
        <InputText
          {...{
            title: lang.PHONE,
            value: masks.cellphoneBrMask(cellPhone),
            setValue: setCellPhone,
            containerStyle: { margin: "10px 0" },
          }}
        />
      </Form>
      <ButtonContainer>
        <ButtonGradient
          {...{
            loading: loading,
            disabled:
              (fullName === user.fullName &&
                birthDate === user.birthDate &&
                cellPhone === user.cellPhone) ||
              disabled ||
              loading,
            title: lang.UPDATE,
            onClick: handleSubmit,
            width: "95%",
          }}
        />
      </ButtonContainer>
    </Container>
  );
};
const PersonalData: React.FC = () => {
  return (
    <Container>
      <PersonalContent />
    </Container>
  );
};
export default PersonalData;
