import styled from "styled-components";

export const Container = styled.div``;
export const Form = styled.form`
  min-height: 380px;
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
`;
export const ButtonContainer = styled.div`
  ${(props) => props.theme.aligns.horizontal};
`;
export const TermsContainer = styled.div`
  margin: 10px 0;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;
export const TermsText = styled.span`
  ${(props) => props.theme.fonts.poppins};
  color: ${(props) => props.theme.colors.primaryLink};
  font-size: 12px;
`;
export const ExternalLink = styled.a`
  color: ${(props) => props.theme.colors.primaryLink};
  font-weight: 700;
  &:hover {
    color: ${(props) => props.theme.colors.primaryLink}dd;
  }
`;
