import styled, { css } from "styled-components";

export const Container = styled.div(
  (props) => css`
    margin: 10px 0 30px 0;
    box-sizing: border-box;
    padding: 25px 20px 20px 20px;
    border-radius: 16px;
    position: relative;
    /* box-shadow: 0px 10px 10px ${props.theme.colors.boxShadowWalletCard}; */
    background-image: linear-gradient(
      226.19deg,
      ${props.theme.colors.gradientWalletBalancePrimary} 10.66%,
      ${props.theme.colors.gradientWalletBalanceSecundary} 92.35%
    );

    height: 200px;
    width: 100%;
    ${props.theme.responsive.sm(css`
      width: 335px;
      height: 160px;
    `)}
  `
);
export const WalletContainer = styled.div(
  (props) => css`
    ${props.theme.aligns.vertical};
    justify-content: space-between;
  `
);
export const WalletContainerIcon = styled.div`
  ${(props) => props.theme.aligns.verticalAndHorizontal}
`;
export const WalletIconImg = styled.img`
  margin: 0 10px;
  height: 16px;
  width: 16px;
  cursor: pointer;
`;
export const WalletHash = styled.span`
  ${(props) => props.theme.fonts.inter};
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.white}bb;
`;
export const BalanceContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex-flow: column;
`;
export const BalanceMainText = styled.span`
  ${(props) => props.theme.fonts.barlow};
  font-weight: 700;
  color: ${(props) => props.theme.colors.white};
  font-size: 36px;
`;
export const BalanceSecondaryText = styled.span`
  ${(props) => props.theme.fonts.barlow};
  font-weight: 800;
  font-size: 18px;
  color: ${(props) => props.theme.colors.secundarySupport}cc;
  margin-top: 5px;
`;
