import checkIcon from "assets/chevron.svg";
import React from "react";
import { Checkbox, CheckIcon, Container, Title } from "./styles";

interface ICheckBox {
  value: any;
  setValue: any;
  column?: boolean;
  title?: string;
  setAll?: any;
}
const CheckBox: React.FC<ICheckBox> = ({
  value,
  column,
  setValue,
  title,
  setAll,
}) => {
  const handleSetValue = () => {
    setValue({ message: -1, approved: !value });
    if (setAll) setAll(!value);
  };

  return (
    <>
      <Container onClick={handleSetValue} column={!column || false}>
        {title ? (
          <Title>{title}</Title>
        ) : (
          <Title>{!value ? "Aprovar" : "Aprovado"}</Title>
        )}
        <Checkbox>{value && <CheckIcon src={checkIcon} />}</Checkbox>
      </Container>
    </>
  );
};
export default CheckBox;
