import checkIcon from "assets/check_green.svg";
import errorIcon from "assets/error.svg";
import warningIcon from "assets/warning.svg";
import ButtonDefault from "components/buttons/ButtonDefault";
import ButtonGradient from "components/buttons/ButtonGradient";
import InputFileWithButton from "components/forms/InputFileWithButton";
import InputText from "components/forms/InputText";
import themes from "config/themes";
import AuthContext from "contexts/Auth";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import { cpf as cpf_validator } from "cpf-cnpj-validator";
import { alert } from "lib/Alert";
import masks from "lib/masks";
import validator from "lib/validator";
import { useMounted } from "modules/usemounted";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userService from "services/userService";
import {
  ButtonContainer,
  Container,
  DocumentNotAccepted,
  Form,
  HeaderContainer,
  InfosContainer,
  MessageVerificationContainer,
  MessageVerificationSubtitle,
  MessageVerificationTitle,
  ProgressBar,
  ProgressContainer,
  ProgressDescription,
  ProgressDescriptionContainer,
  ProgressMark,
  ProgressStatus,
  StatusIcon,
  TextHeader,
  TitleVerification,
} from "./styles";

const DocumentContent: React.FC = () => {
  const { user } = useContext(UserContext);
  const { updateUserData } = useContext(AuthContext);

  const { lang } = useContext(LangContext);

  const [documentNumber, setDocumentNumber] = useState("");
  const [documentFront, setDocumentFront] = useState("");
  const [documentBack, setDocumentBack] = useState("");
  const [documentSelfie, setDocumentSelfie] = useState("");
  const [disableResendForm, setDisableResendForm] = useState(true);
  const allowedExtentions = ["png", "jpg", "jpeg", "tiff", "webp", "jfif"];
  const [disableUpdateDocuments, setDisableUpdateDocuments] = useState(true);

  const isMounted = useMounted();

  const [loading, setLoading] = useState(false);
  const [isRefused, setIsRefused] = useState(false);
  const [showInputFile, setShowInputFile] = useState(true);
  const [documentsAlreadySubmitted, setDocumentsAlreadySubmitted] =
    useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [progressLog, setProgressLog] = useState({
    document: {
      status: "",
    },
    verification: {
      status: "",
      tooltiptexts: user.analysisMessages || [],
    },
    aproved: {
      status: "",
    },
  });
  // console.log(user);

  const fileSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    if (bytes === 0) return "0 Byte";

    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
    let size = Math.round(bytes / Math.pow(1024, i));

    if (size >= 10 && sizes[i] === "MB") return false;
    else return true;
  };

  const filterFileType = (fileType) => {
    if (fileType !== "") {
      const docType = fileType.split("/");
      if (!(docType[0] === "image" && allowedExtentions.includes(docType[1]))) {
        return false;
      }
    } else {
      return false;
    }
    return true;
  };

  const userHasPersonalInfoFilled = () => {
    if (user.name && user.fullname && user.birthdate && user.cellphone) {
      return true;
    }
    return false;
  };

  const validateFile = (file) => {
    ("");
    if (!filterFileType(file.type)) {
      // console.log("File Type ERROR");
      return { status: false, type: "type" };
    } else if (!fileSize(file.size)) {
      // console.log("File Size ERROR");
      return { status: false, type: "size" };
    }
  };

  const validFields = () =>
    new Promise((resolve: any, reject) => {
      // console.log({ documentBack, documentFront, documentSelfie });
      if (!cpf_validator.isValid(cpf_validator.strip(documentNumber)))
        reject({
          name: "input",
          message: lang.INVALID_CPF,
        });
      if (!user.analysis?.documentFileSelf.approved)
        if (!documentSelfie)
          reject({
            name: "input",
            message: lang.DOCUMENT_IMAGE_REQUIRED,
          });
        else {
          var validatedFile = validateFile(documentSelfie);
          if (
            validatedFile &&
            !validatedFile.status &&
            validatedFile.type === "type"
          )
            reject({
              name: "input",
              message: lang.INVALID_FILE_TYPE,
            });

          if (
            validatedFile &&
            !validatedFile.status &&
            validatedFile.type === "size"
          )
            reject({
              name: "input",
              message: lang.INVALID_FILE_SIZE,
            });
        }

      if (!user.analysis?.documentFileFront.approved)
        if (!documentFront)
          reject({
            name: "input",
            message: lang.DOCUMENT_IMAGE_REQUIRED,
          });
        else {
          let validatedFile = validateFile(documentFront);
          if (
            validatedFile &&
            !validatedFile.status &&
            validatedFile.type === "type"
          )
            reject({
              name: "input",
              message: lang.INVALID_FILE_TYPE,
            });

          if (
            validatedFile &&
            !validatedFile.status &&
            validatedFile.type === "size"
          )
            reject({
              name: "input",
              message: lang.INVALID_FILE_SIZE,
            });
        }

      if (!user.analysis?.documentFileBack.approved)
        if (!documentBack)
          reject({
            name: "input",
            message: "lang.DOCUMENT_IMAGE_REQUIRED",
          });
        else {
          let validatedFile = validateFile(documentBack);

          if (
            validatedFile &&
            !validatedFile.status &&
            validatedFile.type === "type"
          )
            reject({
              name: "input",
              message: lang.INVALID_FILE_TYPE,
            });

          if (
            validatedFile &&
            !validatedFile.status &&
            validatedFile.type === "size"
          )
            reject({
              name: "input",
              message: lang.INVALID_FILE_SIZE,
            });
        }

      resolve();
    });

  useEffect(() => {
    // console.log({ user });

    setDocumentNumber(
      cpf_validator.format(user.documentNumber) || documentNumber
    );

    const refused = user.documentStatus === "refused";
    setIsRefused(refused);

    if (
      user.documentStatus === "processing" ||
      user.documentStatus === "confirmed"
    ) {
      setDocumentsAlreadySubmitted(true);
      setShowInputFile(false);
      setDisableSubmit(true);
      setDisableUpdateDocuments(true);
    } else if (!userHasPersonalInfoFilled()) {
      setDocumentsAlreadySubmitted(false);
      setShowInputFile(false);
      setDisableSubmit(true);
      setDisableUpdateDocuments(true);
    } else if (!user.documentNumber) {
      setDocumentsAlreadySubmitted(false);
      setShowInputFile(true);
      setDisableSubmit(false);
      setDisableUpdateDocuments(false);
    } else {
      setDocumentsAlreadySubmitted(true);
      setShowInputFile(false);
      setDisableUpdateDocuments(false);
      setDisableSubmit(false);
    }

    // console.log({ documentsAlreadySubmitted, disableSubmit, showInputFile });
    //
    const percentage = user.analysisPercentage;
    const dict = {
      email: {
        status: "check",
      },
      document: {
        status: "",
      },
      verification: {
        status: "",
        tooltiptexts: user.analysisMessages || [],
      },
      aproved: {
        status: "",
      },
    };

    if (percentage > 24) {
      dict.document.status = "check";
    }
    if (percentage > 74) {
      dict.verification.status = refused ? "error" : "warning";
    }
    if (percentage > 75) {
      dict.verification.status = "check";
      dict.aproved.status = "check";
    }

    setProgressLog(dict);
    /*eslint-disable */
  }, [user, lang]);

  const handleSubmit = async (e: any) => {
    setLoading(true);
    alert.closeAll();
    try {
      e.preventDefault();
      await validFields();
      if (!isMounted()) return;
      let responseData = await userService.updateUserDocument({
        documentNumber: documentNumber,
        documentFileFront: documentFront,
        documentFileBack: documentBack,
        documentFileSelf: documentSelfie,
      });
      if (!isMounted()) return;
      await updateUserData();

      // window.location.reload();
      // navigate("/account/data");
      setLoading(false);
      setShowForm(false);
      alert.open({ title: responseData.message, type: "success" });
    } catch (e: any) {
      const response = e.response;
      // console.log(response);

      if (response && response.data) {
        validator.verifyErrors(response.data);
      }
      if (e.name === "input") {
        alert.open({ title: e.message, type: "warning" });
      }
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   // console.log({ user });
  //   const percentage = user.analysisPercentage;
  //   const dict = {
  //     email: {
  //       status: "check",
  //     },
  //     document: {
  //       status: "",
  //     },
  //     verification: {
  //       status: "",
  //       tooltiptexts: user.analysisMessages || [],
  //     },
  //     aproved: {
  //       status: "",
  //     },
  //   };

  //   if (percentage > 24) {
  //     dict.document.status = "check";
  //   }
  //   if (percentage > 74) {
  //     dict.verification.status = isRefused ? "error" : "warning";
  //   }
  //   if (percentage > 75) {
  //     dict.verification.status = "check";
  //     dict.aproved.status = "check";
  //   }

  //   setProgressLog(dict);
  //   // console.log({ progressLog });
  // }, [user]);

  useEffect(() => {
    // if (loadCounter >= 2) {
    //   setDisableResendForm(false);
    // } else {
    //   setLoadCounter(loadCounter + 1);
    // }
    if (documentSelfie || documentBack || documentFront || documentNumber)
      setDisableResendForm(false);
    else setDisableResendForm(true);
  }, [documentSelfie, documentBack, documentFront, documentNumber]);

  const showAnalysisStatus = (analysis) => {
    // console.log(analysis);
    alert.closeAll();
    const key = "tooltiptexts";
    analysis[key].map((item, key) => {
      alert.open({
        title: item.title,
        subtitle: item.description,
        type: "error",
      });
    });
  };

  const getIcon = (type: string) => {
    if (type == "warning") return warningIcon;
    if (type == "error") return errorIcon;
    if (type == "check") return checkIcon;

    return "";
  };

  const getPercentage = (percentage: number) => {
    // console.log({ percentage });
    if (percentage <= 25) return "38%";
    if (percentage <= 75) return "67%";
    if (percentage > 75) return "100%";
  };

  const showMessage = () => {
    if (!showForm && progressLog.verification.status == "error") return true;
    else return false;
  };

  return (
    <Container>
      <HeaderContainer>
        <InfosContainer>
          <TextHeader>
            {showForm && progressLog.verification.tooltiptexts.length > 0 && (
              <TitleVerification>{lang.ERRORS_FOUND}</TitleVerification>
            )}
            {showForm && (
              <>
                {progressLog.verification.tooltiptexts.map((item, key) => (
                  <>
                    <MessageVerificationContainer>
                      <MessageVerificationTitle>
                        {item.title}:{" "}
                      </MessageVerificationTitle>
                      <MessageVerificationSubtitle>
                        {item.description}
                      </MessageVerificationSubtitle>
                    </MessageVerificationContainer>
                  </>
                ))}
                <br />
                <br />
              </>
            )}
            {!isRefused &&
            documentsAlreadySubmitted &&
            user.analysisPercentage <= 75
              ? lang.DOCUMENT_ANALYSIS
              : user.analysisPercentage > 75
              ? ""
              : lang.TEXT_PHOTO_DOCUMENT}
            {showMessage() && (
              <DocumentNotAccepted onClick={() => setShowForm(true)}>
                {lang.DOCUMENT_NOT_ACCEPTED}
              </DocumentNotAccepted>
            )}
          </TextHeader>
        </InfosContainer>
      </HeaderContainer>
      {!documentsAlreadySubmitted || showForm ? (
        <Form>
          {!user.analysis?.documentNumber.approved && (
            <InputText
              {...{
                title: lang.DOCUMENT_NUMBER,
                name: lang.DOCUMENT_NUMBER,
                value: masks.cpfMask(documentNumber),
                setValue: setDocumentNumber,
                containerStyle: { margin: "8px 0" },
                disabled:
                  !showInputFile && user.analysis?.documentNumber.approved,
              }}
            />
          )}

          {!user.analysis?.documentFileFront.approved && (
            <InputFileWithButton
              {...{
                title: lang.DOCUMENT_FRONT,
                name: lang.DOCUMENT_FRONT,
                value: documentFront,
                setValue: setDocumentFront,
                containerStyle: { margin: "8px 0" },
                disabled:
                  !showInputFile && user.analysis?.documentFileFront.approved,
              }}
            />
          )}
          {!user.analysis?.documentFileBack.approved && (
            <InputFileWithButton
              {...{
                title: lang.DOCUMENT_BACK,
                name: lang.DOCUMENT_BACK,
                value: documentBack,
                setValue: setDocumentBack,
                containerStyle: { margin: "8px 0" },
                disabled:
                  !showInputFile && user.analysis?.documentFileBack.approved,
              }}
            />
          )}
          {!user.analysis?.documentFileSelf.approved && (
            <InputFileWithButton
              {...{
                title: lang.DOCUMENT_SELF,
                name: lang.DOCUMENT_SELF,
                value: documentSelfie,
                setValue: setDocumentSelfie,
                containerStyle: { margin: "8px 0" },
                disabled:
                  !showInputFile && user.analysis?.documentFileSelf.approved,
              }}
            />
          )}
        </Form>
      ) : (
        <ProgressContainer>
          <ProgressBar percentage={getPercentage(user.analysisPercentage)} />
          <ProgressDescriptionContainer>
            <ProgressDescription first={true}>
              <ProgressMark />
              <p>{lang.EMAIL}</p>
              <ProgressStatus>
                {lang.EMAIL_OK}
                <StatusIcon src={checkIcon} />
              </ProgressStatus>
            </ProgressDescription>
            <ProgressDescription>
              <ProgressMark />
              <p>{lang.DOCUMENTS}</p>
              <ProgressStatus>
                {user.analysisPercentage > 24 ? lang.RECEIVED : ""}
                <StatusIcon src={getIcon(progressLog.document.status)} />
              </ProgressStatus>
            </ProgressDescription>
            <ProgressDescription
              filled={progressLog.verification.status == "error" ? true : false}
              style={
                progressLog.verification.status == "error"
                  ? { cursor: "pointer" }
                  : {}
              }
              onClick={
                user.documentStatus === "refused"
                  ? () => {
                      setShowForm(true);
                    }
                  : () => {}
              }
            >
              <ProgressMark />
              <p>{lang.WAITING_VERIFICATION}</p>
              <ProgressStatus>
                {isRefused
                  ? lang.NOT_APPROVED
                  : progressLog.verification.status == "check"
                  ? lang.APPROVED
                  : lang.UNDER_ANALYSIS}

                <StatusIcon src={getIcon(progressLog.verification.status)} />
              </ProgressStatus>

              {isRefused ? (
                <>
                  <br /> <p>{lang.SHOW_DETAILS}</p>{" "}
                </>
              ) : (
                <></>
              )}
            </ProgressDescription>
            <ProgressDescription
              last={true}
              status={progressLog.aproved.status}
            >
              <ProgressMark />
              <p>{lang.DOCUMENTS_VERIFIED}</p>
              <ProgressStatus>
                {progressLog.aproved.status == "check" ? lang.APPROVED : ""}
                <StatusIcon src={getIcon(progressLog.aproved.status)} />
              </ProgressStatus>
            </ProgressDescription>
          </ProgressDescriptionContainer>
        </ProgressContainer>
      )}
      <InfosContainer>
        {/* <TextFooter>
          {disableUpdateDocuments
            ? lang.DOCUMENT_SUBMITTED
            : lang.TEXT_ANALYSIS_DOCUMENT}
        </TextFooter> */}
      </InfosContainer>
      {(showForm || !user.documentStatus) && (
        <ButtonContainer>
          <ButtonDefault
            {...{
              title: lang.CANCEL,
              disabled: loading,
              action: () => setShowForm(false),
              width: "47%",
            }}
          />

          <ButtonDefault
            {...{
              title: lang.SAVE,
              color: themes.colors.white,
              action: handleSubmit,
              width: "48%",
              disabled: disableResendForm || loading,
              loading: loading,
              css: `
								background-image: linear-gradient(89.94deg,
								${themes.colors.primarySupport} 13.57%,
									${themes.colors.secundary} 50.45%,
								${themes.colors.primarySupport} 85.9%
								);
								&:hover {
									background-image: none;
									background-color: ${themes.colors.secundary};
								}
							`,
            }}
          />
        </ButtonContainer>
      )}
    </Container>
  );
};
const DocumentData: React.FC = () => {
  const { lang } = useContext(LangContext);

  return (
    <Container>
      <DocumentContent />
    </Container>
  );
};
export default DocumentData;
