import triangleDown from "assets/triangle_down.svg";
import { allCoins, coins, dictionaryCoins } from "constants/coins";

import React, { useEffect, useState } from "react";
import {
  Container,
  IconOption,
  IconOptions,
  IconTriangle,
  OptionContainer,
  OptionsContainer,
  SelecdOptionSubtitle,
  SelectedOption,
  SelectedOptionContainer,
  SelectOptionContainer,
  SelectOptionTitle,
  Title,
} from "./styles";

export interface ISelectWithImgOptions {
  icon: string;
  symbol: string;
  name: string;
}
interface ISelectWithImg {
  title: string;
  value: allCoins;
  setValue: (val: allCoins) => void;
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  options: string[];
}
const SelectWithImg: React.FC<ISelectWithImg> = ({
  title,
  value,
  setValue,
  disabled,
  containerStyle,
  options,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  useEffect(() => {
    document.body.addEventListener("click", () => {
      setShowOptions(false);
    });

    return function cleanup() {
      window.removeEventListener("click", () => {
        setShowOptions(false);
      });
    };
  }, []);

  return (
    <Container
      style={{ ...containerStyle }}
      onClick={(e) => {
        e.stopPropagation();
        setShowOptions(!showOptions);
      }}
    >
      <Title>{title}</Title>
      <SelectedOptionContainer disabled={disabled}>
        <IconOption
          src={coins[dictionaryCoins[value]].icon}
          alt={coins[dictionaryCoins[value]].symbol}
        />
        <SelectedOption>{coins[dictionaryCoins[value]].symbol}</SelectedOption>
        <IconTriangle src={triangleDown} alt="option" />
        {showOptions && options.length > 0 && (
          <OptionsContainer>
            {options.map((option, idx) => (
              <OptionContainer
                key={idx}
                onClick={() => setValue(option as allCoins)}
              >
                <IconOptions
                  src={coins[dictionaryCoins[option]].icon}
                  alt={coins[dictionaryCoins[option]].name}
                />
                <SelectOptionContainer>
                  <SelectOptionTitle>
                    {coins[dictionaryCoins[option]].symbol}
                  </SelectOptionTitle>
                  <SelecdOptionSubtitle>
                    {coins[dictionaryCoins[option]].name}
                  </SelecdOptionSubtitle>
                </SelectOptionContainer>
              </OptionContainer>
            ))}
          </OptionsContainer>
        )}
      </SelectedOptionContainer>
    </Container>
  );
};
export default SelectWithImg;
