import styled, { css } from "styled-components";

export const Container = styled.div`
  background-color: #333434;
  padding: 15px 15px;
  /* box-sizing: border-box; */
  border-radius: 15px;
  height: fit-content !important;
  min-height: fit-content !important;
  overflow: auto;
`;

export const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const SectionTitle = styled.h1(
  (props) => css`
    color: #979898;
    ${props.theme.fonts.montserrat};
    margin: 1em 0.5em;
    font-weight: bold;
    font-size: 1.2em;
  `
);

export const CpfContainer = styled.div(
  (props) => css`
    color: #979898;
    ${props.theme.fonts.montserrat};
    margin: 1em 0.5em;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 1em;
    padding: 1em;
    border: 1px solid #979898;
    width: 30%;
    border-radius: 15px;
    @media (max-width: 650px) {
      width: 90%;
    }
  `
);

export const EvaluateContainer = styled.div<{ file?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 35%;
  margin-left: 10px;
  ${(props) =>
    props.file &&
    css`
      width: 100%;
    `}
  @media(max-width: 650px) {
    width: 100%;
  }
`;

export const CpfIcon = styled.img`
  margin-right: 1em;
`;

export const DocumentContainer = styled.div`
  width: 27%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  max-height: 400px;
  padding: 1em;
  background-color: #333434;
  border-radius: 10px;

  @media (min-width: 1300px) {
    max-height: fit-content;
  }
  @media (max-width: 650px) {
    margin-top: 10px;
    width: 100%;
  }
`;

export const Documents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

export const DocumentImageContainer = styled.div`
  height: 80%;
  width: 95%;
  background-color: #2c2e2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 10px;
`;

export const DocumentTitle = styled.h1(
  (props) => css`
    color: #979898;
    ${props.theme.fonts.montserrat};
    margin: 1em 0.5em;
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
  `
);

export const EmptyIcon = styled.img`
  height: 100%;
  width: 74%;
  cursor: pointer;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5em;
`;
export const BigImgContainer = styled.div(
  (props) => css`
    width: 100%;
    height: 100vh;
    background-color: #2c2e2fdd;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    ${props.theme.aligns.verticalAndHorizontal}
    cursor: pointer;
  `
);
export const BigImg = styled.img`
  width: 50%;
`;