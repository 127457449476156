import ButtonGradient from "components/buttons/ButtonGradient";
import Checkbox from "components/forms/Checkbox";
import InputText from "components/forms/InputText";
import themes from "config/themes";
import LangContext from "contexts/Lang";
import { alert } from "lib/Alert";
import validator from "lib/validator";
import Guest from "pages/templates/Guest";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "services/authService";
import {
  ButtonContainer,
  Container,
  ExternalLink,
  Form,
  TermsContainer,
  TermsText,
} from "./styles";

const SignUpContent: React.FC = () => {
  const { lang } = useContext(LangContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [checkTerms, setCheckTerms] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const user = {
        name,
        email,
        password,
        passwordConfirm,
        checkTerms: `${checkTerms}` as "true" | "false",
      };
      if (!validator.validateInputs(user)) throw Error("EMPTY_INPUT");

      const res = await authService.signup(user);
      if (res) {
        alert.open({ title: res.message, type: "success" }, true);
        navigate("/", { replace: true });
      }
    } catch (error: any) {
      setTimeout(() => {
        if (error.message === "EMPTY_INPUT")
          alert.open({ title: lang.FILL_THE_INPUTS, type: "warning" }, true);
        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          validator.verifyErrors(errorRes.data);
        }
        setLoading(false);
      }, 500);
    }
  };
  return (
    <Container>
      <Form>
        <InputText
          {...{
            title: lang.NICKNAME,
            value: name,
            setValue: setName,
            containerStyle: { margin: "10px 0" },
          }}
        />
        <InputText
          {...{
            title: lang.EMAIL,
            value: email,
            setValue: setEmail,
            containerStyle: { margin: "10px 0" },
          }}
        />
        <InputText
          {...{
            type: "password",
            title: lang.PASSWORD,
            value: password,
            setValue: setPassword,
            containerStyle: { margin: "10px 0" },
          }}
        />
        <InputText
          {...{
            type: "password",
            title: lang.CONFIRM_PASSWORD_S,
            value: passwordConfirm,
            setValue: setPasswordConfirm,
            containerStyle: { margin: "10px 0" },
          }}
        />

        <TermsContainer>
          <Checkbox {...{ value: checkTerms, setValue: setCheckTerms }} />
          <TermsText>
            {lang.CHECKBOX}{" "}
            <ExternalLink href="#"> {lang.DISCLAIMER}</ExternalLink>.
          </TermsText>
        </TermsContainer>
      </Form>
      <ButtonContainer>
        <ButtonGradient
          {...{
            title: lang.CONFIRM,
            disabled: !checkTerms || loading,
            onClick: handleSubmit,
            color: themes.colors.white,
            backgroundColor: themes.colors.primaryBtn,
            loading,
            width: screenWidth > 350 ? "320px" : "260px",
          }}
        />
      </ButtonContainer>
    </Container>
  );
};
const SignUp: React.FC = () => {
  const { lang } = useContext(LangContext);
  return (
    <Guest
      headerBody={{ title: lang.SIGNUP, backPath: "/login" }}
      settingsAccount
    >
      <SignUpContent />
    </Guest>
  );
};
export default SignUp;
