import styled, { css } from "styled-components";
import {
  IAccountItemContainer,
  IAccountItemContent,
  IAccountItemText,
  IContainer,
} from "./interfaces";

export const Container: any = styled.div<IContainer>`
  position: absolute;
  top: 48px;
  right: 0px;
  z-index: 3;
  box-shadow: 0px 4px 4px
    ${(props) => props.theme.colors.blackTransparentDarker};
  background-color: ${(props) => props.theme.colors.primaryBgc};
  padding: 3px;
  height: auto;
  width: 190px;
  display: ${(props) => (props.$show ? "block" : "none")};
`;

export const AccountItemContent: any = styled.div<IAccountItemContent>`
  ${(props) => props.theme.aligns.vertical};
  height: 40px;
  padding: 0 15px;

  ${(props) =>
    props.$select &&
    css`
      background-color: ${(props) => props.theme.colors.primaryBgc};
      cursor: pointer;

      &:hover {
        background-color: ${(props) =>
          props.theme.colors.primaryTextDisabled}22;
      }
    `}
`;

export const ContainerBody = styled.div``;
export const AccountItemContainer: any = styled.div<IAccountItemContainer>`
  display: flex;

  cursor: pointer;

  ${(props) =>
    props.$select
      ? css`
          background-color: ${(props) => props.theme.colors.primaryBgc};
          cursor: auto;
          flex-flow: column;
        `
      : css`
          &:hover {
            background-color: ${(props) =>
              props.theme.colors.primaryTextDisabled}22;
          }
        `}
`;
export const AccountItemText = styled.div<IAccountItemText>`
  ${(props) => props.theme.fonts.poppins};
  color: ${(props) => props.theme.colors.secundarySupport};
  font-size: 12px;
`;
export const IconImg = styled.img`
  margin-right: 12px;
  width: 20px;
`;
