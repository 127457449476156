// import AuthContext from "contexts/Auth/Index";
import LangContext from "contexts/Lang";
import React, { useContext } from "react";
import {
  BalanceContainer,
  BalanceMainText,
  BalanceSecondaryText,
  BalanceTitleMainText,
  Container,
  ImgToken,
  ImgTokenContainer,
  MainContent,
  RewardContent,
  RewardMainText,
  RewarTitleMainText,
} from "./styles";
interface IEarnBalance {
  balance: string;
  reward: string;
  balanceSecondary: string;
  tokenName: string;
  tokenImg: string;
}
const EarnBalance: React.FC<IEarnBalance> = ({
  balance,
  balanceSecondary,
  reward,
  tokenName,
  tokenImg,
}) => {
  const { lang } = useContext(LangContext);

  return (
    <Container>
      <MainContent>
        <ImgTokenContainer>
          <ImgToken src={tokenImg} alt={tokenName} />
        </ImgTokenContainer>
        <BalanceContainer>
          <BalanceTitleMainText>{lang.REWARD}</BalanceTitleMainText>
          <BalanceMainText>{reward}</BalanceMainText>
        </BalanceContainer>
      </MainContent>
      <RewardContent>
        <RewarTitleMainText>{lang.BALANCE}</RewarTitleMainText>
        <RewardMainText>{balance}</RewardMainText>
        <BalanceSecondaryText>{balanceSecondary}</BalanceSecondaryText>
      </RewardContent>
    </Container>
  );
};

export default EarnBalance;
