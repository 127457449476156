import styled from "styled-components";

export const Container = styled.div`
  height: 12px;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;
export const Message = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.alertError};
  ${(props) => props.theme.fonts.poppins};
`;
