import styled from "styled-components";

export const Container = styled.div``;

export const UserInfoContainer = styled.div`
  ${(props) => props.theme.aligns.vertical};
  margin-bottom: 30px;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
`;

export const InfosContainer = styled.div`
  ${(props) => props.theme.aligns.horizontal};
  flex-flow: column;
`;
export const TextHeader = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 400;
  color: ${(props) => props.theme.colors.primaryFont};
  font-size: 12px;
  line-height: 30px;
  max-width: 350px;
`;

export const NavigateButton = styled.span`
  margin-top: 10px;
  ${(props) => props.theme.fonts.roboto};
  font-weight: 300;
  font-size: 12px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primarySupport};
`;
export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  height: 100%;
`;

export const TextPix = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 700;
  color: ${(props) => props.theme.colors.primaryFont};
  font-size: 14px;
  line-height: 20px;
`;

export const QrCodeContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 1em 0 1em 0;
`;

export const Form = styled.form`
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
`;

export const TermsContainer = styled.div`
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;
export const TermsText = styled.span`
  ${(props) => props.theme.fonts.poppins};
  color: ${(props) => props.theme.colors.primaryFont};
  font-size: 12px;
`;

// #D6A84C
export const ListContainer = styled.div`
  -webkit-box-shadow: -6px 5px 4px -3px #d6a84c50;
  box-shadow: -2px 5px 10px -3px #d6a84c50;
  border-radius: 5px;
  padding: 15px;
  margin: 1em 0;
`;

export const ListItem = styled.div`
  ${(props) => props.theme.fonts.poppins};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  /* font-weight: bold; */
  line-height: 2em;
  color: ${(props) => props.theme.colors.white};
  font-size: 11px;
`;
export const CheckIcon = styled.img`
  width: 20px;
`;
