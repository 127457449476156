import styled, { css } from "styled-components";
import { IMobile } from "./interfaces";

export const Container = styled.header(
  (props) => css`
    ${props.theme.aligns.horizontal};
    ${props.theme.fonts.poppins};
    background: transparent;
    position: absolute;
    width: 100%;
    border-bottom: 2px solid ${(props) => props.theme.colors.borderHeader};
    height: ${props.theme.sizes.navbarHeightMob};
    ${props.theme.responsive.lg(
      css`
        height: ${props.theme.sizes.navbarHeightDesk};
      `
    )}
  `
);
export const Nav = styled.nav<IMobile>(
  (props) => css`
    ${props.theme.paddingLayoutPage};
    ${props.mobile
      ? css`
          justify-content: center;
          flex-flow: column;
        `
      : css`
          justify-content: space-between;
        `}
    display: flex;
    align-items: center;
    width: 1280px;
    position: relative;
    height: ${props.theme.sizes.navbarHeightMob};
    ${props.theme.responsive.lg(
      css`
        height: ${props.theme.sizes.navbarHeightDesk};
      `
    )}
  `
);

export const LogoContainer = styled.div<IMobile>`
  display: flex;
  cursor: pointer;
  align-items: center;
  & img {
    width: 50px;
  }
  & span {
    margin-left: 10px;
    font-weight: 700;
  }
`;

export const RightContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SettingsContainer = styled.div`
  position: relative;
`;
export const SettingButtonContainer = styled.div`
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  background-color: ${(props) => props.theme.colors.headerItemBgc};
  height: 35px;
  width: 35px;
  border-radius: 12px;
  margin-left: 20px;
  cursor: pointer;
`;
