import ActionsButtons from "components/cards/ActionsButtons";
import WalletBalance from "components/cards/WalletBalance";
import SpreadLoading from "components/SpreadLoading";
import Tabs, { ITabInfo } from "components/Tabs";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import React, { useContext } from "react";
import Main from "../templates/Main";
import Activities from "./Activities";
import Assets from "./Assets";
import { Container, WelcomeText, Icon, WarningDocs } from "./styles";
import WarningDocsIcon from "assets/warning_documents.svg";
import { useNavigate } from "react-router-dom";

const DashboardContent: React.FC = () => {
  const { user } = useContext(UserContext);
  const { lang } = useContext(LangContext);
  const navigate = useNavigate();
  const tabInfo: ITabInfo = {
    assets: {
      title: lang.ASSETS,
      content: <Assets />,
    },
    activities: {
      title: lang.ACTIVITIES,
      content: <Activities />,
    },
  };
  return (
    <Container>
      <WelcomeText>
        <span>
          {lang.HELLO}, {(user && user.name) || <SpreadLoading />}
        </span>
        {user.documentStatus && user.documentStatus === "refused" && (
          <WarningDocs onClick={() => navigate("/account/data")}>
            <Icon src={WarningDocsIcon} alt="warning" />
            {lang.DOCUMENTATION_REFUSED}
          </WarningDocs>
        )}
      </WelcomeText>
      <WalletBalance />
      <ActionsButtons />
      <br />
      <br />
      <Tabs tabInfo={tabInfo} tabKeyDefault="assets" />
    </Container>
  );
};
const Dashboard: React.FC = () => {
  return (
    <Main>
      <DashboardContent />
    </Main>
  );
};

export default Dashboard;
