import ButtonGradient from "components/buttons/ButtonGradient";
import InputText from "components/forms/InputText";
import LangContext from "contexts/Lang";
import { alert } from "lib/Alert";
import validator from "lib/validator";
import LoadingPage from "pages/LoadingPage";
import Guest from "pages/templates/Guest";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "services/authService";
import { ButtonContainer, Container, Form, Message } from "./styles";

const ResetPassword: React.FC = () => {
  const { lang } = useContext(LangContext);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingValidateToken, setLoadingValidateToken] =
    useState<boolean>(true);
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const validateToken = async () => {
      const queryString = window.location.hash.split("?")[1];
      const urlParams = new URLSearchParams(queryString);
      const tokenURL = urlParams.get("token");
      try {
        setLoadingValidateToken(true);
        if (tokenURL) {
          await authService.validateTokenResetPassword({
            token: tokenURL,
          });
          setToken(tokenURL);
        }
      } catch (error: any) {
        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          validator.verifyErrors(errorRes.data);
          setErrorMessage(lang.INVALID_PASSWORD_TOKEN);
        }

        // console.log(error);
      }
      setLoadingValidateToken(false);
    };
    validateToken();
  }, [lang]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await authService.resetPassword({
        token,
        password,
        password_confirmation: passwordConfirm,
      });
      setTimeout(() => {
        alert.open({ title: res.message, type: "success" }, true);
        navigate("/", { replace: true });
      }, 500);
    } catch (error: any) {
      setTimeout(() => {
        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          validator.verifyErrors(errorRes.data);
        }
        setLoading(false);
      }, 500);
    }
  };
  return loadingValidateToken ? (
    <LoadingPage />
  ) : (
    <Guest
      headerBody={{ title: lang.RESET_PASSWORD, backPath: "/login" }}
      settingsAccount
    >
      <Container>
        <Form>
          {errorMessage ? (
            <>
              <Message>{errorMessage}</Message>
            </>
          ) : (
            <>
              <InputText
                {...{
                  title: lang.NEW_PASSWORD,
                  type: "password",
                  value: password,
                  setValue: setPassword,
                  containerStyle: { margin: "15px 0 10px 0" },
                }}
              />
              <InputText
                {...{
                  title: lang.CONFIRM_PASSWORD,
                  type: "password",
                  value: passwordConfirm,
                  setValue: setPasswordConfirm,
                  containerStyle: { margin: "10px 0" },
                }}
              />
            </>
          )}
        </Form>
        <ButtonContainer>
          <ButtonGradient
            {...{
              loading,
              disabled: loading,
              width: "320px",
              title: errorMessage ? lang.LOGIN : lang.RESET_PASSWORD,
              onClick: errorMessage
                ? () => {
                    alert.closeAll();
                    navigate("/", { replace: true });
                  }
                : handleSubmit,
            }}
          />
        </ButtonContainer>
      </Container>
    </Guest>
  );
};

export default ResetPassword;
