import styled, { css } from "styled-components";
import { IArrowDown, IContainer, ISideNavSubItemList } from "./interfaces";

export const SideNavContent = styled.div(
  (props) => css`
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: ${props.theme.colors.primaryLink};
    display: flex;
    flex-flow: column;
    position: relative;
    /* transition: visibility 1s; */
    ${props.theme.responsive.md(
      css`
        width: 250px;
      `
    )}
  `
);

export const Container: any = styled.div<IContainer>`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0;
  z-index: 10;
  ${(props) =>
    !props.$show &&
    css`
      ${SideNavContent} {
        transform: translateX(-250px);
      }

      visibility: hidden;
    `}
`;
export const SideNavItem = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SideNavTitle = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
`;
export const SideNavIconLeftContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;

export const SideNavSubItemList = styled.div<ISideNavSubItemList>`
  padding: 10px 15px;
  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.colors.primary}aa;
  position: relative;
  ${(props) =>
    props.$select &&
    css`
      background-color: ${(props) => props.theme.colors.primary}aa;
      cursor: pointer;

      &:hover {
        background-color: ${(props) => props.theme.colors.primary};
      }
    `}
`;
export const SideNavSubItemTitle = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
`;
export const ArrowDownLink: any = styled.img<IArrowDown>`
  margin-left: 10px;
  transition: transform 0.1s;
  ${(props) =>
    props.$active &&
    css`
      transform: rotateZ(180deg);
    `}
`;
export const ConfigImg = styled.img``;
export const CloseIcon = styled.img`
  width: 15px;
  padding: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: rotateZ(90deg);
  cursor: pointer;
`;
export const CloseContainer = styled.div`
  background-color: ${(props) => props.theme.colors.blackTransparentDarkest};
  width: 100%;
  height: 100vh;
  position: absolute;
`;
