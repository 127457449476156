import arrowRightIcon from "assets/arrow_right.svg";
import ButtonDefault from "components/buttons/ButtonDefault";
import ButtonGradient from "components/buttons/ButtonGradient";
import ErrorMessage from "components/texts/ErrorMessage";
import { coins, dictionaryCoins, swapCoinType } from "constants/coins";
import AuthContext from "contexts/Auth";
import LangContext from "contexts/Lang";
import PricesContext from "contexts/Prices";
import { alert } from "lib/Alert";
import formatText from "lib/formatText";
import validator from "lib/validator";
import LoadingPage from "pages/LoadingPage";
import Main from "pages/templates/Main";
import { default as React, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import transactionService from "services/transactions";
import {
  Arrow,
  Body,
  ButtonsContainer,
  Container,
  DescriptionRowTransactionInfo,
  InfoContent,
  RowTransactionInfo,
  TitleRowTransactionInfo,
  TitleTransactionInfo,
  TitleHeader,
  Divider,
  TransactionInfo,
} from "../styles";
import { Header, LeftCoin, RightCoin, WalletContainer } from "./styles";

const SwapToken: React.FC = () => {
  const { updateUserData } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const { lang } = useContext(LangContext);
  const { mainTokenPrice } = useContext(PricesContext);

  const [loadingEstimate, setLoadingEstimate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [coinFrom, setCoinFrom] = useState<swapCoinType>();
  const [coinTo, setCoinTo] = useState<any>();

  const [fee, setFee] = useState<any>("");
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.hash.split("?")[1];
    const urlParams = new URLSearchParams(queryString);

    const amountUrl = urlParams.get("amount");
    const coinFromUrl = urlParams.get("coinFrom") as swapCoinType;
    const coinToUrl = urlParams.get("coinTo");

    if (!amountUrl) {
      setErrorMessage(lang.SHIPPING_VALUE_MUST_BE_INFORMED);
      return;
    }
    if (!coinFromUrl) {
      setErrorMessage(lang.CONVERSION_CURRENCY_MUST_BE_INFORMED);
      return;
    }
    if (!coinToUrl) {
      setErrorMessage(lang.CURRENCY_TO_BE_CONVERTED_MUST_BE_INFORMED);
      return;
    }

    if (
      dictionaryCoins[coinFromUrl] === undefined ||
      dictionaryCoins[coinToUrl] === undefined
    ) {
      setErrorMessage(lang.INVALID_TOKEN);
      return;
    }

    setAmount(amountUrl);
    setCoinFrom(coinFromUrl);
    setCoinTo(coinToUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Estimalor do gas
  useEffect(() => {
    const ac = new AbortController();
    let isMounted = true;
    const estimateGas = async () => {
      try {
        if (coinFrom) {
          const res = await transactionService.estimateGasSwap(
            {
              coinAmount: amount,
              coin: coinFrom,
              pairCoin: coinTo,
            },
            ac.signal
          );
          if (!isMounted) return null;
          setFee(res.data);
          setTimeout(() => {
            if (!isMounted) return null;
            setLoadingEstimate(false);
          }, 500);
        } else {
          setLoadingEstimate(false);
        }
      } catch (error: any) {
        const errorRes = error.response;
        if (!isMounted) return null;
        setTimeout(() => {
          if (!isMounted) return null;

          if (errorRes && errorRes.data) {
            if (
              errorRes.data.errors.length > 0 &&
              errorRes.data.errors[0].id === "MUST_EXECUTE_APPROVE_FIRST"
            ) {
              let navigateTo = formatText.changeUrlParams({
                url: window.location.hash,
                hashed: true,
                oldParameter: "type",
                newParameters: "type=approval",
              });

              navigate(navigateTo, { replace: false });
              return;
            } else if (
              errorRes.data.errors.length > 0 &&
              errorRes.data.errors[0].id ===
                "NO_LIQUIDITY_FOUND_FOR_THIS_AMOUNT"
            ) {
              validator.verifyErrors(errorRes.data);
              navigate(-1);
              return;
            } else {
              validator.verifyErrors(errorRes.data);
              navigate(-1);
              // setLoadingEstimate(false);
            }
          }
        }, 500);
      }
    };
    estimateGas();
    return () => {
      ac.abort();
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, coinFrom, coinTo, mainTokenPrice]);

  const handleSubmit = async () => {
    try {
      if (coinFrom) {
        setLoading(true);
        const res = await transactionService.swapTransaction({
          coinAmount: amount,
          coin: coinFrom,
          pairCoin: coinTo,
        });
        await updateUserData();
        setTimeout(() => {
          setLoading(false);
          alert.open({ title: res.message, type: "success" }, true);
          navigate("/", { replace: true });
        }, 1000);
      }
    } catch (error: any) {
      setTimeout(() => {
        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          validator.verifyErrors(errorRes.data);
        }
        setLoading(false);
      }, 1000);
    }
  };

  return loadingEstimate || !fee ? (
    <LoadingPage loadingLabel={lang.CHECKING_DATA} />
  ) : (
    <Main
      headerBody={{
        title: lang.CONFIRM_TRANSACTION,
        backPath: "/",
        type: "secondary",
      }}
    >
      <Container>
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <InfoContent>
          {amount && coinFrom && fee && (
            <>
              <Header>
                <TitleHeader>{lang.SWAP_TOKEN}</TitleHeader>
              </Header>
              <Body>
                <WalletContainer>
                  <LeftCoin
                    src={coins[dictionaryCoins[coinFrom]].icon}
                    alt=""
                  />
                  <Arrow src={arrowRightIcon} alt="" />
                  <RightCoin src={coins[dictionaryCoins[coinTo]].icon} alt="" />
                </WalletContainer>

                <TransactionInfo>
                  <TitleTransactionInfo>{lang.DETAILS}</TitleTransactionInfo>
                  <RowTransactionInfo>
                    <TitleRowTransactionInfo>
                      {lang.QUANTITY}
                    </TitleRowTransactionInfo>
                    <DescriptionRowTransactionInfo>
                      {`${fee.finalValueToSend}`}
                    </DescriptionRowTransactionInfo>
                  </RowTransactionInfo>
                  <RowTransactionInfo>
                    <TitleRowTransactionInfo>
                      {lang.FEE}
                    </TitleRowTransactionInfo>
                    <DescriptionRowTransactionInfo>
                      {` ${fee.totalFeeInCrypto}`}
                    </DescriptionRowTransactionInfo>
                  </RowTransactionInfo>
                  <RowTransactionInfo bold>
                    <TitleRowTransactionInfo>
                      {lang.TOTAL_SPENT}
                    </TitleRowTransactionInfo>
                    <DescriptionRowTransactionInfo>
                      {`${fee.finalValueToSend} + ${fee.totalFeeInCrypto}`}
                    </DescriptionRowTransactionInfo>
                  </RowTransactionInfo>
                  <Divider />
                  <RowTransactionInfo bold>
                    <TitleRowTransactionInfo>
                      {lang.TOTAL_RECEIVE}
                    </TitleRowTransactionInfo>
                    <DescriptionRowTransactionInfo>
                      {` ${fee.finalValueToReceive}`}
                    </DescriptionRowTransactionInfo>
                  </RowTransactionInfo>
                </TransactionInfo>
              </Body>
            </>
          )}
        </InfoContent>
        <ButtonsContainer>
          <ButtonDefault
            {...{
              width: errorMessage || !fee ? "100%" : "",
              title: lang.BACK,
              disabled: loading,
              action: () => navigate(-1),
            }}
          />

          {!errorMessage && fee && (
            <ButtonGradient
              {...{
                title: lang.CONFIRM,
                disabled: loading,
                loading,
                onClick: handleSubmit,
              }}
            />
          )}
        </ButtonsContainer>
      </Container>
    </Main>
  );
};

export default SwapToken;
