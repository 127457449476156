import currencyIcon from "assets/currency.svg";
import langIcon from "assets/lang.svg";
import powerIcon from "assets/power.svg";
import shieldIcon from "assets/shield.svg";
import userIcon from "assets/user.svg";
import userDataIcon from "assets/user_data.svg";

import languages from "constants/languages";
import AuthContext from "contexts/Auth";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import formatText from "lib/formatText";
import Main from "pages/templates/Main";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  ContainerOption,
  ContainerOptionDropdown,
  ContainerOptions,
  ContainerOptionsDropdown,
  IconOption,
  InfosContainer,
  LabelOption,
  TextEmail,
  TextHash,
  TitleOption,
  UserIcon,
  UserInfoContainer,
} from "./styles";

const AccountContent: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { changeLang, lang, currentLangKey } = useContext(LangContext);
  const [dropDownActive, setDropDownActive] = useState(false);
  const { logout } = useContext(AuthContext);

  return (
    <Container>
      <UserInfoContainer>
        <UserIcon src={userIcon} alt="user" />
        <InfosContainer>
          <TextEmail>{user.email}</TextEmail>
          <TextHash>
            {formatText.truncateHash({
              hash: user.walletAddress,
              startLength: 10,
              endLength: 10,
            })}
          </TextHash>
        </InfosContainer>
      </UserInfoContainer>
      <ContainerOptions>
        <ContainerOption>
          <IconOption src={currencyIcon} alt="currency" />
          <TitleOption>{lang.CURRENCY}</TitleOption>
          <LabelOption>USD</LabelOption>
        </ContainerOption>

        <ContainerOption onClick={() => setDropDownActive(!dropDownActive)}>
          <IconOption src={langIcon} alt="language" />
          <TitleOption>{lang.LANGUAGE}</TitleOption>
          <LabelOption>{currentLangKey}</LabelOption>
        </ContainerOption>
        <ContainerOptionsDropdown active={dropDownActive}>
          {languages.map((item, key) => {
            return (
              <ContainerOptionDropdown
                key={item.key}
                onClick={() => {
                  changeLang(item.key);
                  setDropDownActive(!dropDownActive);
                }}
              >
                <IconOption src={item.icon} alt="language" />
                <TitleOption>{item.name}</TitleOption>
                <LabelOption>{item.key}</LabelOption>
              </ContainerOptionDropdown>
            );
          })}
        </ContainerOptionsDropdown>
        <ContainerOption
          onClick={() => navigate("/account/data", { replace: false })}
        >
          <IconOption src={userDataIcon} alt="userData" />
          <TitleOption>{lang.ACCOUNT_DATA}</TitleOption>
        </ContainerOption>
        <ContainerOption
          onClick={() =>
            navigate("/account/change-password", { replace: false })
          }
        >
          <IconOption src={shieldIcon} alt="shield" />
          <TitleOption>{lang.CHANGE_PASSWORD}</TitleOption>
        </ContainerOption>

        <ContainerOption onClick={logout}>
          <IconOption src={powerIcon} alt="power" />
          <TitleOption>{lang.EXIT}</TitleOption>
        </ContainerOption>
      </ContainerOptions>
    </Container>
  );
};
const Account: React.FC = () => {
  const { lang } = useContext(LangContext);

  return (
    <Main headerBody={{ title: lang.ACCOUNT, backPath: "/" }}>
      <AccountContent />
    </Main>
  );
};
export default Account;
