import ArrowIcon from "assets/navbar/arrow_pagination.svg";
import Pagination from "components/Pagination";
import Admin from "pages/templates/Admin";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import adminService from "services/adminService";
import {
  ArrowDown,
  Body,
  CenterHeader,
  Container,
  Header,
  LeftHeader,
  ListOptionsContainer,
  ListOptionsItem,
  OptionFilter,
  SelectNumRowsContainer,
  SelectNumRowsTitle,
  Table,
  TailSpinContainer,
  Td,
  Th,
  Tr,
} from "./styles";
const SelectNumRows: React.FC<{
  rowsPageSelected: number;
  setRowsPageSelected: any;
}> = ({ rowsPageSelected, setRowsPageSelected }) => {
  const [isOpened, setIsOpened] = useState(false);
  const rowsPage = [5, 10, 15, 20];
  return (
    <SelectNumRowsContainer onClick={() => setIsOpened(!isOpened)}>
      <SelectNumRowsTitle>{rowsPageSelected}</SelectNumRowsTitle>
      <ArrowDown src={ArrowIcon} alt="arrow" />
      {isOpened && (
        <ListOptionsContainer>
          {rowsPage.map((num) => (
            <ListOptionsItem key={num} onClick={() => setRowsPageSelected(num)}>
              {num}
            </ListOptionsItem>
          ))}
        </ListOptionsContainer>
      )}
    </SelectNumRowsContainer>
  );
};
const ListUsersContent: React.FC = () => {
  const [filterSelected, setFilterSelected] = useState(1);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [rowsPageSelected, setRowsPageSelected] = useState(5);
  const [pagination, setPagination] = useState<{
    lastPage: number;
    page: number;
    perPage: number;
    total: number;
  }>({ lastPage: 0, page: 1, perPage: 5, total: 0 });
  const navigate = useNavigate();
  const filters = [
    {
      key: "",
      label: "Todos",
    },
    {
      key: "processing",
      label: "Pendentes",
    },
    {
      key: "refused",
      label: "Reprovados",
    },
    {
      key: "confirmed",
      label: "Aprovados",
    },
  ];
  useEffect(() => {
    getUsers({
      filter: filters[filterSelected].key,
      limit: rowsPageSelected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPageSelected]);
  const getUsers = async ({
    orderBy = "analysisDisapprovedAmount",
    order = "desc",
    filter = "processing",
    page = 1,
    limit = 5,
  }) => {
    try {
      setLoading(true);
      const response = await adminService.adminListUser({
        orderBy,
        order,
        filter,
        page,
        limit,
      });

      setUsers(response.data.data);

      // console.log({ response });
      setPagination({
        lastPage: response.data.lastPage,
        page: response.data.page,
        perPage: response.data.perPage,
        total: response.data.total,
      });
      setLoading(false);
    } catch (error) {
      // setLoading(false);
      // console.log(error);
    }
  };
  useEffect(() => {
    getUsers({});
  }, []);
  return (
    <Container>
      <Header>
        <LeftHeader>
          <SelectNumRows {...{ rowsPageSelected, setRowsPageSelected }} />
        </LeftHeader>
        <CenterHeader>
          {filters.map((filter, idx) => (
            <OptionFilter
              key={idx}
              onClick={() => {
                setFilterSelected(idx);
                getUsers({ filter: filter.key });
              }}
              active={filterSelected === idx}
            >
              {filter.label}
            </OptionFilter>
          ))}
        </CenterHeader>
        {/* <RightHeader></RightHeader> */}
      </Header>
      <Body>
        {loading ? (
          <TailSpinContainer>
            <TailSpin height="100" width="100" color="white" ariaLabel="" />
          </TailSpinContainer>
        ) : (
          <Table>
            <thead>
              <Tr>
                <Th>Nome</Th>
                <Th>E-mail</Th>
                <Th>Telefone</Th>
                <Th>CPF</Th>
                <Th>Nº Reprovações</Th>
                <Th>Ações</Th>
              </Tr>
            </thead>

            <tbody>
              {users.map((user: any, idx) => (
                <Tr
                  key={idx}
                  onClick={() => {
                    if (user.documentNumber) {
                      navigate(
                        "/users/documentation?document-number=" +
                          user.documentNumber,
                        {
                          replace: false,
                        }
                      );
                    }
                  }}
                >
                  <Td active={user.documentNumber}>{user.name}</Td>
                  <Td active={user.documentNumber}>{user.email}</Td>
                  <Td active={user.documentNumber}>{user.cellphone}</Td>
                  <Td active={user.documentNumber}>{user.documentNumber}</Td>
                  <Td active={user.documentNumber}>
                    {user.analysisDisapprovedAmount}
                  </Td>
                  <Td active={user.documentNumber}>
                    {user.documentNumber && "Ver Mais"}
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        )}
      </Body>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Pagination
          {...{
            ...pagination,
            getUsers,
            filter: filters[filterSelected].key,
          }}
        />
      </div>
    </Container>
  );
};

const ListUsers = () => {
  return (
    <Admin>
      <ListUsersContent />
    </Admin>
  );
};
export default ListUsers;