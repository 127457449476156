import styled from "styled-components";

export const Container = styled.div``;

export const Form = styled.form`
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
`;

export const ButtonContainer = styled.div`
  ${(props) => props.theme.aligns.horizontal};
  margin-top: 60px;
  width: 100%;
`;
