import styled, { css } from "styled-components";

export const Container = styled.div<{
  width?: string;
  disabled?: boolean;
  $loading?: any;
}>(
  (props) => css`
    ${props.theme.aligns.verticalAndHorizontal};

    height: 40px;

    min-width: ${props.width || "120px"};
    border-radius: 8px;
    ${props.theme.responsive.sm(css`
      border-radius: 5px;
      min-width: ${props.width || "160px"};
    `)}
    cursor: pointer;
    padding: 0 5px;
    ${props.disabled
      ? css`
          color: ${props.theme.colors.primaryTextDisabled};
          background-color: ${props.theme.colors.neutralDarkest};
          cursor: auto;
        `
      : css`
          background-image: linear-gradient(
            89.94deg,
            ${props.theme.colors.primarySupport} 13.57%,
            ${props.theme.colors.secundary} 50.45%,
            ${props.theme.colors.primarySupport} 85.9%
          );
          &:hover {
            background-image: none;
            background-color: ${props.theme.colors.secundary};
          }
        `}
    ${props.$loading &&
    css`
      cursor: auto;
      /* &:hover {
        background-image: linear-gradient(
          89.94deg,
          ${props.theme.colors.primarySupport} 13.57%,
          ${props.theme.colors.secundary} 50.45%,
          ${props.theme.colors.primarySupport} 85.9%
        );
      } */
    `}
  `
);
export const Label = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => props.theme.colors.white};
`;
