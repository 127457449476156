import axios from "axios";
import jwt_decode from "jwt-decode";
import { auth } from "lib/Auth";
import authStorage from "storage/auth";
import langStorage from "storage/lang";

const { REACT_APP_SERVER_HOST: SERVER_HOST } = process.env;

const validToken = (token: string) => {
  let decodedToken: { exp: number; iat: number; uid: string } =
    jwt_decode(token);
  let currentDate = new Date();

  if (decodedToken) {
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      authStorage.remove();
      // userStorage.remove();
      window.location.reload();
      throw new axios.Cancel("Operation canceled! Invalid Token");
    }
  }
};
// Criando api para comunicação com servidor
const api = axios.create({
  baseURL: SERVER_HOST,
  withCredentials: true,
});

// Adicionando interceptor para inserir token Bearer de autenticação no header
api.interceptors.request.use(
  async (config) => {
    const token = await authStorage.get();
    const lang = await langStorage.get();
    if (token) {
      validToken(token);
    } else {
      auth.logout();
    }

    if (config.headers) {
      config.headers["Accept-Language"] = lang;

      config.headers.Authorization = `Bearer ${token || ""}`;
    }
    return config;
  },
  function (error) {
    auth.logout();
    return Promise.reject(error);
  }
);

type methods = "post" | "get" | "put" | "patch" | "delete";
export const handleApi = async (
  path: string,
  method: methods,
  data: {
    data?: any;
    params?: any;
    formData?: any;
    adminData?: any;
    signal?: AbortSignal;
  }
) => {
  if (data.adminData) {
    const response = await api[method](path, { params: data.params });
    return response.data;
  }
  const response = await api[method](
    path,
    data.formData
      ? data.formData
      : {
          ...data.data,
          params: data.params,
          signal: data.signal,
        }
  );
  return response.data;
};
export default api;
