import EarnBalance from "components/cards/EarnBalance";
import ErrorMessage from "components/texts/ErrorMessage";
import { coins, dictionaryCoins } from "constants/coins";
import LangContext from "contexts/Lang";
import UserContext from "contexts/User";
import formatText from "lib/formatText";
import math from "lib/math";
// import Tabs, { ITabInfo } from "components/Tabs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Main from "../templates/Main";
// import Historic from "./Historic";
import { ButtonRedeem, ButtonRedeemContainer, Container } from "./styles";

const EarnInfoContent: React.FC = () => {
  const { lang } = useContext(LangContext);
  const { balance } = useContext(UserContext);
  const [tokenKey, setTokenKey] = useState("");
  const [tokenData, setTokenData] = useState<any>();
  const [earnKey, setEarnKey] = useState("");
  const [earnData, setEarnData] = useState<any>();
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  // const tabInfo: ITabInfo = {
  //   historic: {
  //     title: "Historic",
  //     content: <Historic />,
  //   },
  // };
  useEffect(() => {
    if (id) {
      setTokenKey(id);
      setTokenData(coins[dictionaryCoins[id]]);
      if (coins[dictionaryCoins[id]] && coins[dictionaryCoins[id]].earnKey) {
        setEarnData(
          coins[dictionaryCoins[coins[dictionaryCoins[id]].earnKey || ""]]
        );
        setEarnKey(
          coins[dictionaryCoins[coins[dictionaryCoins[id]].earnKey || ""]].key
        );
      } else {
        setErrorMessage(lang.TOKEN_TO_EARN_NOT_FOUND);
      }
    }
  }, [id, balance, lang]);

  return (
    <Container>
      {errorMessage ? (
        <ErrorMessage message={errorMessage} />
      ) : (
        <>
          <EarnBalance
            {...{
              tokenName: tokenData ? tokenData.name : "",
              tokenImg: tokenData ? tokenData.icon : "",
              balance: tokenKey
                ? `${formatText.formatNumber({
                    number: balance.wallet[tokenKey].available,
                    fixed: tokenData.fixed,
                  })} ${tokenData.symbol}`
                : "",
              balanceSecondary: tokenKey
                ? `${formatText.formatNumber({
                    number: balance.wallet[tokenKey].usd,
                    fixed: 2,
                  })} ${"USD"}`
                : "",
              reward: tokenKey
                ? `+ ${formatText.formatNumber({
                    number: earnKey
                      ? balance.earn[tokenKey][earnKey].available
                      : "",
                    fixed: coins[dictionaryCoins[earnKey]].fixed,
                  })} ${earnKey && earnData.symbol}`
                : "",
            }}
          />
          <ButtonRedeemContainer>
            <ButtonRedeem
              onClick={() => {
                if (
                  !Boolean(
                    tokenKey &&
                      earnKey &&
                      !math.isGreaterThan(
                        balance.earn[tokenKey][earnKey].available,
                        0
                      )
                  )
                )
                  navigate(`/confirm-transaction?type=earn&token=${tokenKey}`, {
                    replace: false,
                  });
              }}
              disabled={Boolean(
                tokenKey &&
                  earnKey &&
                  !math.isGreaterThan(
                    balance.earn[tokenKey][earnKey].available,
                    0
                  )
              )}
            >
              {lang.REWARD_REDEMPTION}
            </ButtonRedeem>
          </ButtonRedeemContainer>
        </>
      )}
      {/* <br />
      <br />
      <br /> */}
      {/* <Tabs tabKeyDefault="historic" tabInfo={tabInfo} /> */}
    </Container>
  );
};
const EarnInfo: React.FC = () => {
  return (
    <Main headerBody={{ title: "Earn", backPath: "/" }}>
      <EarnInfoContent />
    </Main>
  );
};

export default EarnInfo;
