import React, { createContext, useMemo, useState } from "react";
import pricesService from "services/prices";

interface IPricesContext {
  mainTokenPrice: any;
  busdTokenPrice: any;
  bnbTokenPrice: any;
  reset: () => void;
  fetchTokenPrice: () => Promise<any>;
}
const PricesContext = createContext<IPricesContext>({
  mainTokenPrice: "",
  busdTokenPrice: "",
  bnbTokenPrice: "",
  reset: () => {},
  fetchTokenPrice: () => {
    return new Promise(() => {});
  },
});
export const PricesProvider: React.FC = ({ children }) => {
  const [mainTokenPrice, setMainTokenPrice] = useState<any>();
  const [busdTokenPrice, setBusdTokenPrice] = useState<any>();
  const [bnbTokenPrice, setBnbTokenPrice] = useState<any>();
  const mainTokenPriceMemo = useMemo(() => mainTokenPrice, [mainTokenPrice]);
  const busdTokenPriceMemo = useMemo(() => busdTokenPrice, [busdTokenPrice]);
  const bnbTokenPriceMemo = useMemo(() => bnbTokenPrice, [bnbTokenPrice]);
  const reset = () => {
    setMainTokenPrice(undefined);
  };
  const fetchTokenPrice = () =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await pricesService.getTokenPrices();
        const res2 = await pricesService.getBusdTokenPrices();
        const res3 = await pricesService.getBnbCryptoPrices();
        setMainTokenPrice(res.data.price);
        setBusdTokenPrice(res2.data);
        setBnbTokenPrice(res3.data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  return (
    <PricesContext.Provider
      value={{
        mainTokenPrice: mainTokenPriceMemo,
        busdTokenPrice: busdTokenPriceMemo,
        bnbTokenPrice: bnbTokenPriceMemo,
        reset,
        fetchTokenPrice,
      }}
    >
      {children}
    </PricesContext.Provider>
  );
};

export default PricesContext;
