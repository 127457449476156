import { tokens } from "constants/coins";
import transactionService from "services/transactions";

const handleAccountTokenIsApproved = async (data: {
  coin: tokens;
}): Promise<any> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await transactionService.accountTokenIsApproved(data);
      // * status "executed" | "pending" | "unexecuted"
      return resolve(res.data.status);
    } catch (error) {
      reject(error);
    }
  });
export default handleAccountTokenIsApproved;
