import math from "./math";

const formatText = {
  truncateHash: ({
    hash,
    startLength,
    endLength,
  }: {
    hash: string;
    startLength: number;
    endLength: number;
  }): string => {
    const hashSize = hash.length;
    const firstPart = hash.slice(0, startLength);
    const sencondyPart = hash.slice(hashSize - endLength - 1, hashSize);
    return firstPart + "..." + sencondyPart;
  },
  formatNumber: ({
    number,
    fixed = 8,
  }: {
    number: number | string;
    fixed?: number;
  }) => {
    const bnNumber = math.BN(number);

    return math.fixit(bnNumber.toString(), fixed, true);
  },
  changeUrlParams: (data: {
    url: string;
    hashed?: boolean;
    oldParameter: string;
    newParameters: string;
  }) => {
    let { url, hashed, newParameters, oldParameter } = data;
    let path: string | string[] = url;
    if (hashed) {
      path = url.split("#/")[1];
    }
    path = path.split("?");

    let parameters = path[1].split("&");
    path = path[0];
    if (path[0] !== "/") path = "/" + path;
    let searchIndex = parameters.findIndex((parameter) =>
      parameter.search(oldParameter) > -1 ? true : false
    );
    parameters[searchIndex] = newParameters;

    return `${path}?${parameters.join("&")}`;
  },
};

export default formatText;
