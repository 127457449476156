import React from "react";
import { TailSpin } from "react-loader-spinner";
import { Container, Content } from "./styles";

interface IButtonDefault {
  title: string | any;
  action: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  color?: string;
  backgroundColor?: string;
  width?: string;
  loading?: boolean;
  disabled?: boolean;
  css?: any;
}
const ButtonDefault: React.FC<IButtonDefault> = ({
  title,
  action,
  color,
  backgroundColor,
  width,
  loading,
  disabled,
  css,
}) => {
  return (
    <Container
      {...{
        disabled,
        $loading: loading,
        onClick: disabled || loading ? () => {} : action,
        color,
        backgroundColor,
        width,
        css,
      }}
    >
      {loading ? (
        <TailSpin height="20" width="20" color="white" ariaLabel="" />
      ) : (
        <Content>{title}</Content>
      )}
    </Container>
  );
};

export default ButtonDefault;
