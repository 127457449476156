import ButtonGradient from "components/buttons/ButtonGradient";
import LangContext from "contexts/Lang";
import validator from "lib/validator";
import LoadingPage from "pages/LoadingPage";
import Guest from "pages/templates/Guest";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import transactionService from "services/transactions";
import {
  ButtonsContainer,
  Container,
  Message,
  MessageContainer,
  TitleMessage,
} from "./styles";

const VerifyTransaction: React.FC = () => {
  const [message, setMessage] = useState("E-mail verificado com sucesso!");
  const [loading, setLoading] = useState(false);
  const [successTransaction, setSuccessTransaction] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const { lang } = useContext(LangContext);
  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth);
    };

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    const validateToken = async () => {
      const queryString = window.location.hash.split("?")[1];
      const tokenURL = queryString.split("token=")[1];
      try {
        setLoading(true);
        if (!tokenURL) throw Error(lang.INVALID_TOKEN);

        const response = await transactionService.executePendingTransaction({
          token: tokenURL,
        });
        setMessage(response.message);
        setSuccessTransaction(true);
      } catch (error: any) {
        setSuccessTransaction(false);

        const errorRes = error.response;
        if (errorRes && errorRes.data) {
          validator.verifyErrors(errorRes.data);
          if (errorRes.data.errors) {
            setMessage(errorRes.data.errors[0].message);
          } else {
            setMessage(lang.EXPIRED_TRANSACTION_TOKEN);
          }
        }
      }
      setLoading(false);
    };
    validateToken();
  }, [lang]);
  return loading ? (
    <LoadingPage />
  ) : (
    <Guest
      headerBody={{
        title: lang.CONFIRM_TRANSACTION,
        backPath: "/",
      }}
      settingsAccount
    >
      <Container>
        <MessageContainer>
          {successTransaction && (
            <TitleMessage>{lang.TRANSACTION_SUCCESS}</TitleMessage>
          )}

          <Message>{message}</Message>
        </MessageContainer>
        <ButtonsContainer>
          <ButtonGradient
            {...{
              width: screenWidth > 350 ? "320px" : "260px",
              title: lang.LOGIN,
              onClick: () => navigate("/", { replace: true }),
            }}
          />
        </ButtonsContainer>
      </Container>
    </Guest>
  );
};

export default VerifyTransaction;
