import styled, { css } from "styled-components";

interface IRadiusContainer {
  $active: boolean;
}

export const InternalRadius = styled.div(
  (props) => css`
    background-color: ${props.theme.colors.secundarySupport};
    width: 10px;
    height: 10px;
    border: 2px solid ${props.theme.colors.primary};
    border-radius: 18px;
    cursor: pointer;
  `
);
export const Container: any = styled.div<IRadiusContainer>(
  (props) => css`
    position: absolute;
    right: 20px;
    border: 2px solid ${props.theme.colors.secundarySupport};
    border-radius: 18px;
    height: 14px;
    width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${!props.$active &&
    css`
      & ${InternalRadius} {
        background-color: transparent;
      }
    `}
  `
);
