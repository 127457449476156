import styled, { css } from "styled-components";
interface IContainer {
  color?: string;
  backgroundColor?: string;
  width?: string;
  $loading?: boolean;
  disabled?: boolean;
  css?: any;
}
export const Container = styled.div<IContainer>(
  (props) => css`
    ${props.theme.aligns.verticalAndHorizontal};
    min-width: ${props.width || "120px"};
    border-radius: 8px;
    ${props.theme.responsive.sm(css`
      border-radius: 5px;
      min-width: ${props.width || "160px"};
    `)}
    box-sizing: border-box;
    height: 40px;
    padding: 0 5px;
    background-color: ${props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.tertiary};
    cursor: pointer;
    color: ${props.color ? props.color : props.theme.colors.secundary};
    ${props.disabled
      ? css`
          color: ${props.theme.colors.primaryTextDisabled};
          background-color: ${props.theme.colors.neutralDarkest};
          cursor: auto;
        `
      : css`
          &:hover {
            background-color: ${props.backgroundColor
              ? `${props.backgroundColor}bb`
              : `${props.theme.colors.tertiary}ee`};
          }
        `}
    ${props.$loading &&
    css`
      cursor: auto;
    `}
		${props.disabled
      ? css`
          background-color: ${props.theme.colors.neutralDarkest};
          color: ${props.theme.colors.primaryTextDisabled};
        `
      : props.css};
  `
);
export const Content = styled.div`
  height: 40px;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  ${(props) => props.theme.fonts.poppins};
  font-weight: 700;
  font-size: 13px;
`;
