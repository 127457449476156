import React, { useEffect, useState } from "react";
interface ISpreadLoading {
  reverse?: boolean;
  width?: string;
}
const SpreadLoading: React.FC<ISpreadLoading> = ({ reverse, width }) => {
  const [spread, setSpread] = useState<string>(".");

  useEffect(() => {
    const points: any = { ".": "..", "..": "...", "...": "." };
    const interval = setInterval(() => {
      setSpread((state) => points[state]);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <span
      style={{
        width: width || "10px",
        display: "inline-block",
        textAlign: reverse ? "right" : "left",
      }}
    >
      {spread}
    </span>
  );
};

export default SpreadLoading;
