import styled, { css } from "styled-components";

export const Container = styled.div`
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
  justify-content: space-between;
`;
export const Form = styled.form`
  width: 100%;
  min-height: 380px;
  ${(props) =>
    props.theme.responsive.sm(css`
      ${(props) => props.theme.aligns.vertical};
      flex-flow: column;
    `)}
`;
export const ButtonsContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  justify-content: space-between;
`;
export const SwapIconContainer = styled.div`
  width: 100%;

  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;
export const SwapIcon = styled.img``;
export const AllowPlatformTokenContainer = styled.div`
  display: flex;
`;
export const ImgBlock = styled.img`
  margin-right: 5px;
`;
export const AllowButtonsContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  ${(props) => props.theme.aligns.verticalAndHorizontal};
`;

export const InfoPricesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${(props) =>
    props.theme.responsive.sm(css`
      width: 320px;
    `)}
  ${(props) => props.theme.fonts.poppins};
  font-size: 14px;
  padding: 4px 0;
`;
export const InfoPricesLeftContent = styled.div``;
export const InfoPricesRightContent = styled.div``;
export const InfoPricesLeftText = styled.span`
  color: ${(props) => props.theme.colors.secundary};
`;
export const InfoPricesRightText = styled.span`
  color: ${(props) => props.theme.colors.tertiary};
`;
