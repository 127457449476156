import { handleApi } from "./server";

const userService = {
  balances: async () => {
    return await handleApi("/user/balances", "get", {});
  },
  transactionHistory: async (signal?: AbortSignal) => {
    return await handleApi("/transactions/histories", "get", { signal });
  },
  confirmRegistration: async (data: { token: string }) => {
    return await handleApi("/user/registry/confirm", "post", { data });
  },
  resendTokenEmail: async (data: { token: string }) => {
    return await handleApi("/user/registry/resendmail", "post", { data });
  },
  updatePasswod: async (data: {
    currentPassword: string;
    password: string;
    passwordConfirm: string;
  }) => {
    return await handleApi("/user/password/", "put", { data });
  },
  updateAccount: async (data: {
    name: string;
    fullname: string;
    birthdate: string;
    phone: string;
    cellphone: string;
  }) => {
    return await handleApi("/user", "put", { data });
  },
  getCountry: async () => {
    return await handleApi("/country", "get", {});
  },
  updateUserDocument: async (data: {
    documentFileFront: any;
    documentFileBack: any;
    documentFileSelf: any;
    documentNumber: string;
  }) => {
    let formData = new FormData();

    if (data.documentFileFront) {
      formData.append("upload", data.documentFileFront, "documentFileFront");
    }
    if (data.documentFileBack) {
      formData.append("upload", data.documentFileBack, "documentFileBack");
    }
    if (data.documentFileSelf) {
      formData.append("upload", data.documentFileSelf, "documentFileSelf");
    }

    formData.append("documentNumber", data.documentNumber);

    return await handleApi("/user/documents", "put", { formData: formData });
  },
};
export default userService;
