import Radius from "components/forms/Radius";
import navigation from "config/navigation";
import languages from "constants/languages";
import LangContext from "contexts/Lang";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ISidenav } from "./interfaces";
import AuthContext from "contexts/Auth";
import {
  ArrowDownLink,
  CloseContainer,
  CloseIcon,
  ConfigImg,
  Container,
  SideNavContent,
  SideNavIconLeftContainer,
  SideNavItem,
  SideNavSubItemList,
  SideNavSubItemTitle,
  SideNavTitle,
} from "./styles";

const Sidenav: React.FC<ISidenav> = ({ show, setShow }) => {
  const [showSetting, setShowSetting] = useState<boolean>(false);
  const { changeLang, lang, currentLangKey } = useContext(LangContext);
  const [selectedLang, setSelectedLang] = useState<number>(
    currentLangKey === "pt" ? 0 : 1
  );
  useEffect(() => {
    setShowSetting(false);
  }, [show]);
  const handleChangeLanguage = (key: string) => {
    changeLang(key);
  };
  const navigate = useNavigate();
  const handleRedirect = (path: string) => {
    if (navigate) {
      navigate(path, { replace: false });
    }
  };

  const { logout } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      const res = await logout();
      if (res) navigate("/login", { replace: true });
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <Container $show={show}>
      <CloseContainer onClick={() => setShow(false)} />
      <SideNavContent>
        <CloseIcon
          onClick={() => setShow(false)}
          src={require("../../../../assets/navbar/close.svg").default}
          alt="Arrow down"
        />
        <br />
        <br />
        <SideNavItem onClick={() => handleRedirect("/account")}>
          <SideNavIconLeftContainer></SideNavIconLeftContainer>
          <SideNavTitle>{lang.ACCOUNT}</SideNavTitle>
        </SideNavItem>
        <SideNavItem
          onClick={() => {
            setShowSetting(!showSetting);
          }}
        >
          <SideNavIconLeftContainer>
            <ConfigImg
              src={require("../../../../assets/navbar/cog.svg").default}
              alt="config"
            />
          </SideNavIconLeftContainer>
          <SideNavTitle>{lang.SETTINGS}</SideNavTitle>
          <ArrowDownLink
            src={require("../../../../assets/navbar/arrow_down.svg").default}
            alt="Arrow down"
            $active={showSetting}
          />
        </SideNavItem>
        {showSetting &&
          navigation.settingAccount().map((item, idx) => (
            <div key={idx}>
              <SideNavSubItemList
                key={idx}
                onClick={
                  item.logout
                    ? () => {
                        setShowSetting(false);
                        handleLogout();
                      }
                    : () => {}
                }
                $select={item.logout ? true : false}
              >
                <SideNavIconLeftContainer>
                  <ConfigImg
                    src={item.icon}
                    alt={lang[item.nameKey].toString()}
                  />
                </SideNavIconLeftContainer>

                <SideNavSubItemTitle>
                  <>{lang[item.nameKey]}</>
                </SideNavSubItemTitle>
              </SideNavSubItemList>
              {item.lang &&
                languages.map((lang, idx) => (
                  <SideNavSubItemList
                    key={idx}
                    $select
                    onClick={() => {
                      setShowSetting(false);
                      setSelectedLang(idx);
                      handleChangeLanguage(lang.key);
                    }}
                  >
                    <SideNavIconLeftContainer>
                      <ConfigImg src={lang.icon} alt={lang.name} />
                    </SideNavIconLeftContainer>

                    <SideNavSubItemTitle>{lang.name}</SideNavSubItemTitle>
                    <Radius
                      value={idx === selectedLang}
                      setValue={() => {
                        setShowSetting(false);
                        setSelectedLang(idx);
                        handleChangeLanguage(lang.key);
                      }}
                    />
                  </SideNavSubItemList>
                ))}
            </div>
          ))}
      </SideNavContent>
    </Container>
  );
};

export default Sidenav;
