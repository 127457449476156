import styled from "styled-components";

export const Container = styled.div`
  ${(props) => props.theme.aligns.vertical};
  position: relative;
  flex-flow: column;
  width: 100%;
  height: 545px;
`;
export const ImgLogo = styled.img`
  width: 275px;
  height: 233px;
  margin-top: 10px;
`;

export const Link = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primarySupport};
  &:hover {
    color: ${(props) => props.theme.colors.secundary};
  }
  width: 80%;
`;
export const ForgotPasswordText = styled.span`
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  float: right;
  padding: 10px 0;
`;
export const Divider = styled.div`
  width: 80%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.blackTransparentMind};
`;
export const SignUpText = styled.span`
  position: absolute;
  bottom: 15px;
  font-size: 14px;
  width: 80%;
  text-align: center;
  font-weight: 700;
  color: ${(props) => props.theme.colors.white};
`;
