import React, { createContext, useState } from "react";
import transactionService from "services/transactions";

interface ITaxesAndLimitsContext {
  taxesAndLimits: any;
  setTaxesAndLimits: (data: any) => void;
  reset: () => void;
  fetchTaxesAndLimits: () => Promise<any>;
}
const TaxesAndLimitsContext = createContext<ITaxesAndLimitsContext>({
  taxesAndLimits: "",
  setTaxesAndLimits: (data) => {},
  reset: () => {},
  fetchTaxesAndLimits: () => {
    return new Promise(() => {});
  },
});
export const TaxesAndLimitsProvider: React.FC = ({ children }) => {
  const [taxesAndLimits, setTaxesAndLimits] = useState<any>();
  // TAXES AND LIMITS
  const fetchTaxesAndLimits = async () =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await transactionService.taxesAndLimits();
        setTaxesAndLimits(res.data);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  const reset = () => {
    setTaxesAndLimits(undefined);
  };
  return (
    <TaxesAndLimitsContext.Provider
      value={{ taxesAndLimits, setTaxesAndLimits, reset, fetchTaxesAndLimits }}
    >
      {children}
    </TaxesAndLimitsContext.Provider>
  );
};

export default TaxesAndLimitsContext;
