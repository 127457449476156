import styled, { css } from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
  ${(props) => props.theme.aligns.vertical};
  margin: 20px 0;
  box-sizing: border-box;
  width: 100%;
`;

export const InfosContainer = styled.div`
  ${(props) => props.theme.aligns.horizontal};
  /* flex-flow: column; */
`;

export const TextHeader = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 400;
  color: ${(props) => props.theme.colors.primaryFont};
  font-size: 12px;
  line-height: 20px;
  max-width: 350px;
`;

export const Form = styled.form`
  ${(props) => props.theme.aligns.vertical};
  flex-flow: column;
  margin-bottom: 250px;
  max-height: 200px;
`;

export const TextFooter = styled.span`
  ${(props) => props.theme.fonts.poppins};
  font-weight: 400;
  color: ${(props) => props.theme.colors.primaryFont};
  font-size: 12px;
  line-height: 20px;
  max-width: 350px;
`;

export const ButtonContainer = styled.div`
  ${(props) => props.theme.aligns.horizontal};
  margin-top: 20px;
  justify-content: space-between;
`;

export const ProgressBar = styled.div<{ percentage?: string }>`
  /* padding: 1em; */
  width: 6px;
  height: 106%;
  border: 1px solid ${(props) => props.theme.colors.secundarySupport};
  position: absolute;
  left: 10px;
  border-radius: 20px;
  &::after {
    content: "";
    height: ${(props) => props.percentage};
    width: 6px;
    background-color: ${(props) => props.theme.colors.secundarySupport};
    position: absolute;
    left: 0;
    border-radius: 20px;
  }
`;

export const ProgressContainer = styled.div`
  padding-left: 15px;
  width: 100%;
  position: relative;
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5em;
  margin-top: 2em;
`;

export const ProgressMark = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.secundarySupport};
  position: absolute;
  left: 11px;
`;

export const ProgressDescription = styled.div<{
  first?: boolean;
  last?: boolean;
  status?: string;
  filled?: boolean;
}>(
  (props) => css`
    ${props.theme.fonts.poppins};
    color: ${props.theme.colors.primaryFont};
    font-weight: 400;
    font-size: 12px;
    border: 1px solid ${props.theme.colors.secundarySupport};
    border-radius: 10px;
    padding: 1em 0.5em;
    margin: 1.2em 0;
    margin-left: 1em;
    width: 75%;
    min-width: 120px;
    text-align: center;
    ${props.filled &&
    css`
      background-color: ${props.theme.colors.secundary};
      font-weight: bold;
      box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.2);
    `}
    ${props.first &&
    css`
      margin-top: -12px;
    `}
  ${props.last &&
    css`
      margin-bottom: -14%;
    `}
  ${props.last &&
    props.status === "check" &&
    css`
      margin-bottom: -22%;
    `}
  `
);

export const ProgressStatus = styled.span<{ status?: string }>(
  (props) => css`
    margin-top: 10px;
    ${props.theme.fonts.roboto};
    font-weight: 300;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${props.status === "analysis"
      ? css`
          color: ${props.theme.colors.alertWarning};
        `
      : css`
          color: ${props.theme.colors.primaryFont};
        `}
  `
);
export const StatusIcon = styled.img`
  width: 25px;
  margin-left: 0px;
`;

export const ProgressDescriptionContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
`;

export const DocumentNotAccepted = styled.span`
  margin-top: 10px;
  ${(props) => props.theme.fonts.roboto};
  font-weight: bolder;
  font-size: 12px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.colors.secundarySupport};
`;
export const MessageVerificationContainer = styled.div`
  margin: 10px 0;
`;
export const MessageVerificationTitle = styled.span`
  font-weight: 600;
`;
export const MessageVerificationSubtitle = styled.span``;
export const TitleVerification = styled.p`
  margin: 15px 0 10px 0;
  font-size: 14px;
  font-weight: 600;
`;
