import bnbIcon from "assets/coins/bnb.svg";
import busdIcon from "assets/coins/busd.svg";
import tokenIcon from "assets/coins/token.svg";
import brlIcon from "assets/coins/brl.svg";
export type cryptos = "bnb";
export type tokens = "crd" | "busd";
export type fiat = "brl";
export type allCoins = cryptos | tokens | fiat;
export const mainToken = "crd";
export type sendCoinType = "bnb" | "busd" | "crd";
export const sendCoinKeys: sendCoinType[] = ["bnb", "busd", "crd"];
export type swapCoinType = "bnb" | "crd" | "brl";
export const swapCoinKeys: swapCoinType[] = ["bnb", "crd", "brl"];
export type ISwapCoins = {
  [key in swapCoinType]: Array<allCoins>;
};
// Mostra os pares que uma moeda pode ser trocada
export const swapCoinPairKey: ISwapCoins = {
  bnb: ["crd"],
  crd: ["bnb"],
  brl: ["crd"],
};
export const dictionaryCoins = {
  bnb: 0,
  busd: 1,
  crd: 2,
  brl: 3,
};

export interface ICoinInfo {
  name: string;
  icon: string;
  shortname: string;
  key: allCoins;
  symbol: string;
  fixed: number;
}

export const coins = [
  {
    icon: bnbIcon,
    name: "Binance Coin",
    shortname: "bnb",
    key: "bnb",
    symbol: "BNB",
    fixed: 4,
  },
  {
    icon: busdIcon,
    name: "Binance USD",
    shortname: "binanceusd",
    key: "busd",
    symbol: "BUSD",
    fixed: 4,
  },
  {
    icon: tokenIcon,
    name: "CreditCoin",
    shortname: "creditcoin",
    key: "crd",
    earnKey: "busd",
    symbol: "CRD",
    fixed: 4,
  },
  {
    icon: brlIcon,
    name: "Real",
    shortname: "",
    key: "brl",
    symbol: "BRL",
    fixed: 2,
  },
];
