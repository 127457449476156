import styled, { css } from "styled-components";

export const Container = styled.div(
  (props) => css`
    margin: 10px 0 30px 0;
    padding: 20px 20px 20px 20px;
    border-radius: 16px;
    box-sizing: border-box;
    position: relative;

    background-image: linear-gradient(
      226.19deg,
      ${props.theme.colors.bgcEarnBalancePrimary} 5.66%,
      ${props.theme.colors.bgcEarnBalancePrimary}aa 94.35%
    );
    min-height: 200px;
    width: 100%;
    ${props.theme.responsive.sm(css`
      width: 335px;
      height: 160px;
    `)}
  `
);

export const BalanceContainer = styled.div`
  display: flex;
  flex-flow: column;
`;
export const BalanceMainText = styled.span`
  ${(props) => props.theme.fonts.barlow};
  font-weight: 700;
  word-break: break-all;
  color: ${(props) => props.theme.colors.white};
  font-size: 34px;
`;
export const BalanceTitleMainText = styled.span`
  ${(props) => props.theme.fonts.poppins};
  color: ${(props) => props.theme.colors.secundary};
  font-size: 12px;
`;
export const BalanceSecondaryText = styled.span`
  ${(props) => props.theme.fonts.barlow};
  font-weight: 800;
  font-size: 18px;
  color: ${(props) => props.theme.colors.white}bb;
  margin-top: 5px;
`;
export const ImgTokenContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  ${(props) => props.theme.aligns.verticalAndHorizontal};
  box-sizing: border-box;
  border-radius: 50px;
  margin-right: 10px;
  padding: 8px;
`;
export const ImgToken = styled.img`
  width: 30px;
`;
export const MainContent = styled.div`
  ${(props) => props.theme.aligns.vertical};
`;
export const RewardContent = styled.div`
  color: ${(props) => props.theme.colors.white};
  position: absolute;
  right: 20px;
  bottom: 20px;
  text-align: right;
`;
export const RewarTitleMainText = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.secundary};
  ${(props) => props.theme.fonts.poppins};
`;
export const RewardMainText = styled.div`
  ${(props) => props.theme.fonts.barlow};
  font-weight: 700;

  font-size: 24px;
`;
